import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectBills } from "../../store/billsSlice";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "../Procurement/GenericGrid";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox";
import { CiMoneyBill } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import ImageGallery from "../../commons/ImageGallery"; // Import the ImageGallery component
import ItemWiseList from "./ItemsSearch";

const BillsList = () => {
  const bills = useSelector(selectBills);
  const [rowData, setRowData] = useState(bills);
  const [originalData] = useState(bills);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(bills);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedInvoiceNo, setSelectedInvoiceNo] = useState("");
  const [selectedSupplierName, setSelectedSupplierName] = useState("");
  const [selectedProjectSite, setSelectedProjectSite] = useState("");

  const filters = useMemo(
    () => ({
      invoiceNo: selectedInvoiceNo,
      supplierName: selectedSupplierName,
      projectSite: selectedProjectSite,
    }),
    [selectedInvoiceNo, selectedSupplierName, selectedProjectSite]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedInvoiceNo = selectedInvoiceNo;
    let newSelectedSupplierName = selectedSupplierName;
    let newSelectedProjectSite = selectedProjectSite;

    switch (filterType) {
      case "invoiceNo":
        newSelectedInvoiceNo = "";
        break;
      case "supplierName":
        newSelectedSupplierName = "";
        break;
      case "projectSite":
        newSelectedProjectSite = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedInvoiceNo || row.invoiceNo === newSelectedInvoiceNo) &&
        (!newSelectedSupplierName ||
          row.supplierName === newSelectedSupplierName) &&
        (!newSelectedProjectSite || row.projectSite === newSelectedProjectSite)
      );
    });

    setSelectedInvoiceNo(newSelectedInvoiceNo);
    setSelectedSupplierName(newSelectedSupplierName);
    setSelectedProjectSite(newSelectedProjectSite);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedInvoiceNo || row.invoiceNo === selectedInvoiceNo) &&
        (!selectedSupplierName || row.supplierName === selectedSupplierName) &&
        (!selectedProjectSite || row.projectSite === selectedProjectSite)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedInvoiceNo("");
    setSelectedSupplierName("");
    setSelectedProjectSite("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const calculateTotalAmount = useMemo(() => {
    return filteredData.reduce((sum, bill) => sum + Number(bill.amount), 0);
  }, [filteredData]);

  const billColumns = [
    {
      id: "sno",
      header: "S.No",
      accessor: (item) => item.sno,
      show: true,
      minWidth: 50,
    },
    {
      id: "invoiceNo",
      header: "Invoice No",
      accessor: (item) => item.invoiceNo,
      show: true,
      minWidth: 150,
    },
    {
      id: "supplierName",
      header: "Supplier Name",
      accessor: (item) => item.supplierName,
      show: true,
      minWidth: 150,
    },
    {
      id: "date",
      header: "Date",
      accessor: (item) => item.date,
      show: true,
      minWidth: 150,
    },
    {
      id: "projectSite",
      header: "Project/Site",
      accessor: (item) => item.projectSite,
      show: true,
      minWidth: 150,
    },
    {
      id: "projectStage",
      header: "Stage",
      accessor: (item) => item.projectStage,
      show: true,
      minWidth: 150,
    },
    {
      id: "buyer",
      header: "Buyer",
      accessor: (item) => item.buyer,
      show: true,
      minWidth: 150,
    },
    {
      id: "status",
      header: "Status",
      accessor: (item) => item.status,
      show: true,
      minWidth: 150,
    },
    {
      id: "amount",
      header: "Amount",
      accessor: (item) => item.amount,
      show: true,
      minWidth: 150,
    },
    {
      id: "image",
      header: "Image",
      accessor: (item) => item.image,
      render: (item) => (
        <Box minWidth={150} onClick={(e) => e.stopPropagation()}>
          <ImageGallery images={item.images} width={150} />
        </Box>
      ),
      show: true,
      minWidth: 150,
    },
    {
      id: "stageName",
      header: "Stage Name",
      accessor: (item) => item.stageName,
      show: true,
      minWidth: 150,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.sno,
      render: (item) => (
        <IconButton
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            deleteRow(item.sno);
          }}
        />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    billColumns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const deleteRow = (sno) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (confirmed) {
      setRowData(rowData.filter((row) => row.sno !== sno));
      setFilteredData(filteredData.filter((row) => row.sno !== sno));
      toast({
        title: "Row deleted.",
        description: `Row with S.No ${sno} has been deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRowClick = ({ sno }) => {
    navigate("/bills/edit/" + sno);
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={CiMoneyBill}
            name="Total Amount"
            value={calculateTotalAmount}
            backgroundColor="blue.300"
            type="money"
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Bills"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.invoiceNo && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Invoice No: ${filters.invoiceNo}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("invoiceNo")} />
            </Tag>
          )}
          {filters.supplierName && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Supplier Name: ${filters.supplierName}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("supplierName")} />
            </Tag>
          )}
          {filters.projectSite && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Project/Site: ${filters.projectSite}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("projectSite")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Invoice No"
                onChange={(e) => setSelectedInvoiceNo(e.target.value)}
                mb={4}
                value={selectedInvoiceNo}
              >
                {bills.map((bill) => (
                  <option key={bill.invoiceNo} value={bill.invoiceNo}>
                    {bill.invoiceNo}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Supplier Name"
                onChange={(e) => setSelectedSupplierName(e.target.value)}
                mb={4}
                value={selectedSupplierName}
              >
                {bills.map((bill) => (
                  <option key={bill.supplierName} value={bill.supplierName}>
                    {bill.supplierName}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Project/Site"
                onChange={(e) => setSelectedProjectSite(e.target.value)}
                value={selectedProjectSite}
              >
                {bills.map((bill) => (
                  <option key={bill.projectSite} value={bill.projectSite}>
                    {bill.projectSite}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {billColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={billColumns}
          data={filteredData}
          title="Bills List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={setSortConfig}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default BillsList;
