import React from "react";
import { useSelector } from "react-redux";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { selectMaterialById } from "../../store/materialsSlice";
import GenericHeader from "../../commons/GenericHeader";
import { useParams } from "react-router-dom";

const MaterialDetails = () => {
  const { id } = useParams();
  const material = useSelector((state) =>
    selectMaterialById(state, parseInt(id))
  );

  return (
    <Box>
      <GenericHeader title={`${material.name} Details `} back={"/materials"} />
      <Box p={4} borderWidth="1px" borderRadius="md">
        <Box as="h2" mb={4}>
          Details
        </Box>
        <Table variant="simple" background={"white"} borderRadius="md">
          <Tbody>
            <Tr>
              <Td>ID</Td>
              <Td>{material.id}</Td>
            </Tr>
            <Tr>
              <Td>Name</Td>
              <Td>{material.name}</Td>
            </Tr>
            <Tr>
              <Td>Type</Td>
              <Td>{material.type}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box p={4} borderWidth="1px" borderRadius="md" mt={4}>
        <Box as="h2" mb={4}>
          Material Price Comparison
        </Box>
        <Table variant="simple" background={"white"} borderRadius="md">
          <Thead>
            <Tr>
              <Th>Shop Name</Th>
              <Th>Price</Th>
              <Th>Last Purchased Date</Th>
              <Th>Invoice #</Th>
            </Tr>
          </Thead>
          <Tbody>
            {material.priceComparison.map((comparison, index) => (
              <Tr key={index}>
                <Td>{comparison.shopName}</Td>
                <Td>{comparison.price}</Td>
                <Td>{comparison.lastPurchasedDate}</Td>
                <Td>{comparison.invoiceNo}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default MaterialDetails;
