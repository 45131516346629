// src/components/ProfessionsComponent.jsx

import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Heading,
  Flex,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProfession,
  updateProfession,
  deleteProfession,
  selectProfessions,
} from "../../store/professionsSlice";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ConfirmDialog from "../../commons/ConfirmDialog";

const ProfessionsList = () => {
  const dispatch = useDispatch();
  const professions = useSelector(selectProfessions);
  const [rowData, setRowData] = useState(professions);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmAction, setConfirmAction] = useState(null);

  useEffect(() => {
    setRowData(professions.map((prof) => ({ ...prof })));
  }, [professions]);

  const onCellValueChanged = useCallback(
    (event) => {
      const updatedData = { ...event.data };
      dispatch(updateProfession(updatedData));
      toast({
        title: "Profession Updated",
        description: `Profession ID ${updatedData.id} has been updated.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    [dispatch, toast]
  );

  const onRowDelete = (id) => {
    setConfirmAction(() => () => {
      dispatch(deleteProfession(id));
      toast({
        title: "Profession Deleted",
        description: `Profession ID ${id} has been deleted.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
    onOpen();
  };

  const addNewRow = () => {
    const newId = rowData.length
      ? Math.max(...rowData.map((r) => r.id)) + 1
      : 1;
    const newRow = { id: newId, name: "" };
    dispatch(addProfession(newRow));
    toast({
      title: "Profession Added",
      description: `Profession ID ${newId} has been added.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      pinned: "left",
    },
    { headerName: "S.No", field: "id", width: 100 },
    {
      headerName: "Name",
      field: "name",
      editable: true,
      width: 200,
      fullWidth: true,
      filter: "agTextColumnFilter",
      cellEditor: "agTextCellEditor",
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 150,
      pinned: "right",
      cellRenderer: (params) => (
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => onRowDelete(params.data.id)}
        >
          Delete
        </Button>
      ),
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: "agTextColumnFilter",
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
    };
  }, []);

  const ButtonRenderer = (params) => {
    return (
      <Button
        colorScheme="red"
        size="sm"
        onClick={() => onRowDelete(params.data.id)}
      >
        Delete
      </Button>
    );
  };
  return (
    <Box p={4}>
      <Heading as="h3" size="lg" mb={4}>
        Professions Management
      </Heading>
      <Flex mb={4} justifyContent="flex-end">
        <Button colorScheme="primary" onClick={addNewRow}>
          Add Profession
        </Button>
      </Flex>
      <Box className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
          animateRows={true}
          onRowClicked={(param) => {
            console.log("row clied", param);
          }}
          defaultColDef={defaultColDef}
          frameworkComponent={ButtonRenderer}
          sideBar={
            ("columns",
            {
              id: "filters",
              labelKey: "filters",
              labelDefault: "Filters",
              iconKey: "menu",
              toolPanel: "agFiltersToolPanel",
            })
          }
        />
      </Box>
      <ConfirmDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => {
          confirmAction();
          onClose();
        }}
        title="Confirm Delete"
        description="Are you sure you want to delete this profession?"
      />
    </Box>
  );
};

export default ProfessionsList;
