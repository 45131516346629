import React, { useState, useEffect } from "react";
import { Flex, Image, Box } from "@chakra-ui/react";
import backgroundImage1 from "../images/corousel1.jpg";
import backgroundImage2 from "../images/corousel2.jpg";
import backgroundImage3 from "../images/corousel3.jpg";
import backgroundImage4 from "../images/corousel4.jpg";

const Carousel = () => {
  const images = [
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
    backgroundImage4,
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Box position="fixed" inset="0" zIndex="-1">
      {images.map((imagePath, idx) => (
        <Image
          key={idx}
          src={imagePath}
          alt={`Background ${idx + 1}`}
          position="absolute"
          top="0"
          left="0"
          opacity={idx === index ? 1 : 0}
          transition="opacity 1s ease-in-out"
          width="100%"
          height="100%"
          objectFit="cover"
        />
      ))}
    </Box>
  );
};

export default Carousel;
