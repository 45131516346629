import React from "react";
import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import { getContrastYIQ } from "../utils";

const DashboardBox = ({
  icon,
  name,
  value,
  backgroundColor = "white",
  type,
}) => {
  return (
    <Box
      bg={backgroundColor}
      p={4}
      borderRadius="md"
      textAlign="center"
      width="100%"
    >
      <Flex direction="row" align="center" justify="center" width="100%">
        <Icon as={icon} boxSize={10} color="primary.500" mr={4} />
        <Flex
          direction="column"
          align="flex-start"
          justify="center"
          flex="1"
          color={getContrastYIQ(backgroundColor)}
        >
          <Text
            fontWeight="bold"
            fontSize="lg"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {name}
          </Text>
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {value} {type === "money" && "AED"}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default DashboardBox;
