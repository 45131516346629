import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  List,
  ListItem,
  Flex,
  Avatar,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const GenericSelectBox = ({
  options,
  renderOption,
  keyExtractor,
  labelExtractor,
  placeholder = "Search...",
}) => {
  const [query, setQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const filteredOptions = options.filter(
    (option) =>
      !selectedValues.includes(keyExtractor(option)) &&
      labelExtractor(option).toLowerCase().includes(query.toLowerCase())
  );

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setIsDropdownOpen(true);
    setSelectedIndex(0);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prevIndex) =>
        prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "Enter") {
      if (filteredOptions.length > 0) {
        handleOptionSelect(
          filteredOptions[selectedIndex],
          e.ctrlKey || e.metaKey
        );
      }
    } else if (e.key === "Backspace" && query === "") {
      handleRemoveSelection(selectedValues[selectedValues.length - 1]);
    }
  };

  const handleOptionSelect = (option, isMultiSelect) => {
    const value = keyExtractor(option);

    if (isMultiSelect) {
      setSelectedValues((prev) =>
        prev.includes(value)
          ? prev.filter((v) => v !== value)
          : [...prev, value]
      );
    } else {
      setSelectedValues((prev) => [...prev, value]);
    }

    setQuery("");
    setIsDropdownOpen(true);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".select-box-container")) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedBgColor = useColorModeValue("blue.100", "blue.600");
  const defaultBgColor = useColorModeValue("white", "gray.800");
  const hoverBgColor = useColorModeValue("gray.200", "gray.600");
  const selectedOptionColor = useColorModeValue("blue.500", "blue.300");

  const selectedOptions = selectedValues.map((val) =>
    options.find((option) => keyExtractor(option) === val)
  );

  const handleRemoveSelection = (value) => {
    setSelectedValues((prev) => prev.filter((v) => v !== value));
  };

  return (
    <Box width="300px" className="select-box-container">
      <Box
        p={2}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        onClick={() => setIsDropdownOpen(true)}
      >
        <Flex flexWrap="wrap" alignItems="center">
          {selectedOptions.map((option) => (
            <Box
              key={keyExtractor(option)}
              mb={2}
              mr={2}
              p={2}
              bg={selectedBgColor}
              borderRadius="md"
              color={selectedOptionColor}
              cursor="pointer"
              onClick={() => handleRemoveSelection(keyExtractor(option))}
            >
              {renderOption(option)}
            </Box>
          ))}
          <Input
            placeholder={selectedValues.length > 0 ? "" : placeholder}
            value={query}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsDropdownOpen(true)}
            border="none"
            _focus={{ boxShadow: "none" }}
            p={0}
            flex="1"
          />
        </Flex>
      </Box>

      {isDropdownOpen && filteredOptions.length > 0 && (
        <List
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          mt={2}
        >
          {filteredOptions.map((option, index) => (
            <ListItem
              key={keyExtractor(option)}
              p={2}
              cursor="pointer"
              bg={selectedIndex === index ? selectedBgColor : defaultBgColor}
              _hover={{ bg: hoverBgColor }}
              onClick={(e) =>
                handleOptionSelect(option, e.ctrlKey || e.metaKey)
              }
            >
              {renderOption(option)}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default GenericSelectBox;
