// src/store/professionsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  professions: [
    { id: 1, name: "MARBLE MASON" },
    { id: 2, name: "STONE MASON" },
    { id: 3, name: "TILE MASONS" },
    { id: 4, name: "BLOCK MASONS" },
    { id: 5, name: "PLASTER MASON" },
    { id: 6, name: "INTERLOCK MASON" },
    { id: 7, name: "WALL PAINTER SIMPLE" },
    { id: 8, name: "GRAPHIC PAINTER" },
    { id: 9, name: "SHUTTERING CARPENTER" },
    { id: 10, name: "STEEL FIXER" },
    { id: 11, name: "ELECTRICIAN" },
    { id: 12, name: "PLUMBER" },
    { id: 13, name: "HELPER" },
    { id: 14, name: "GYPSUM CARPENTER" },
    { id: 15, name: "DRIVER" },
  ],
};

const professionsSlice = createSlice({
  name: "professions",
  initialState,
  reducers: {
    addProfession: (state, action) => {
      state.professions.push(action.payload);
    },
    updateProfession: (state, action) => {
      const index = state.professions.findIndex(
        (profession) => profession.id === action.payload.id
      );
      if (index !== -1) {
        state.professions[index] = {
          ...state.professions[index],
          ...action.payload,
        };
      }
    },
    deleteProfession: (state, action) => {
      state.professions = state.professions.filter(
        (profession) => profession.id !== action.payload
      );
    },
  },
});

export const { addProfession, updateProfession, deleteProfession } =
  professionsSlice.actions;

export const selectProfessions = (state) => state.professions.professions;

export default professionsSlice.reducer;
