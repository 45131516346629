import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoAddCircleOutline, IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addEmployee } from "../../store/employeesSlice";
import PrimaryButton from "../../commons/PrimaryButton";

const AddEmployee = () => {
  const [empName, setEmpName] = useState("");
  const [designation, setDesignation] = useState("");
  const [visa, setVisa] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [nationality, setNationality] = useState("");
  const [passportExpiry, setPassportExpiry] = useState("");
  const [visaExpiry, setVisaExpiry] = useState("");
  const [selfContactNo, setSelfContactNo] = useState("");
  const [familyContactNo, setFamilyContactNo] = useState("");
  const [performance, setPerformance] = useState("");
  const [photo, setPhoto] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePhotoUpload = (e) => {
    setPhoto(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newEmployee = {
      id: Date.now(), // Or use any unique identifier
      empName,
      designation,
      visa,
      passportNo,
      nationality,
      passportExpiry,
      visaExpiry,
      selfContactNo,
      familyContactNo,
      performance,
      photo,
    };
    dispatch(addEmployee(newEmployee));
    navigate("/employees");
  };

  return (
    <Box>
      <Flex align="center" mb="4">
        <IoArrowBackCircleOutline
          onClick={() => navigate("/hr/employees")}
          color="#db8700"
          size={50}
          cursor="pointer"
        />
        <Heading as="h2" size="lg" ml="4">
          Add Employee
        </Heading>
      </Flex>

      <Box
        p="6"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
        bg={useColorModeValue("white", "gray.800")}
      >
        <form onSubmit={handleSubmit}>
          <Flex
            mb="4"
            flexWrap="wrap"
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box flex="1" mr="4">
              <FormControl mb="4">
                <FormLabel>Employee Name</FormLabel>
                <Input
                  type="text"
                  value={empName}
                  onChange={(e) => setEmpName(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Designation</FormLabel>
                <Input
                  type="text"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Visa</FormLabel>
                <Input
                  type="text"
                  value={visa}
                  onChange={(e) => setVisa(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Passport No</FormLabel>
                <Input
                  type="text"
                  value={passportNo}
                  onChange={(e) => setPassportNo(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Nationality</FormLabel>
                <Input
                  type="text"
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                  required
                />
              </FormControl>
            </Box>
            <Box flex="1">
              <FormControl mb="4">
                <FormLabel>Passport Expiry</FormLabel>
                <Input
                  type="date"
                  value={passportExpiry}
                  onChange={(e) => setPassportExpiry(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Visa Expiry</FormLabel>
                <Input
                  type="date"
                  value={visaExpiry}
                  onChange={(e) => setVisaExpiry(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Self Contact No</FormLabel>
                <Input
                  type="text"
                  value={selfContactNo}
                  onChange={(e) => setSelfContactNo(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Family Contact No</FormLabel>
                <Input
                  type="text"
                  value={familyContactNo}
                  onChange={(e) => setFamilyContactNo(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Performance</FormLabel>
                <Select
                  placeholder="Select Performance"
                  value={performance}
                  onChange={(e) => setPerformance(e.target.value)}
                  required
                >
                  <option value="EXCELLENT">EXCELLENT</option>
                  <option value="GOOD">GOOD</option>
                  <option value="FAIR">FAIR</option>
                  <option value="POOR">POOR</option>
                </Select>
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Photo</FormLabel>
                <Input type="file" onChange={handlePhotoUpload} />
                {photo && (
                  <Box
                    mt="4"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                  >
                    <img src={photo} alt="Employee Photo" />
                  </Box>
                )}
              </FormControl>
            </Box>
          </Flex>
          <Flex justifyContent={"center"}>
            <PrimaryButton type="submit" title={"Add Employee"}></PrimaryButton>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default AddEmployee;
