import React from "react";
import { Flex, Input, Text } from "@chakra-ui/react";

const InputField = ({
  icon,
  placeholder,
  value,
  onChange,
  error,
  type = "text",
}) => {
  return (
    <Flex width={"100%"} alignItems={"center"} gap={2}>
      {icon}
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        _focus={{ borderColor: "primary.500", boxShadow: "none" }}
      />
      {error && <Text color="red.500">{error}</Text>}
    </Flex>
  );
};

export default InputField;
