import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useDisclosure,
  useToast,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { LiaToolsSolid } from "react-icons/lia";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import { selectOwnTools } from "../../store/toolsSlice";
import GenericGrid from "../Procurement/GenericGrid";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox";

const OwnTools = () => {
  const ownTools = useSelector(selectOwnTools);
  const [rowData, setRowData] = useState(ownTools);
  const [originalData] = useState(ownTools);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(ownTools);

  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedToolType, setSelectedToolType] = useState("");
  const [selectedSiteUsing, setSelectedSiteUsing] = useState("");

  const filters = useMemo(
    () => ({
      toolType: selectedToolType,
      siteUsing: selectedSiteUsing,
    }),
    [selectedToolType, selectedSiteUsing]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedToolType = selectedToolType;
    let newSelectedSiteUsing = selectedSiteUsing;

    switch (filterType) {
      case "toolType":
        newSelectedToolType = "";
        break;
      case "siteUsing":
        newSelectedSiteUsing = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedToolType || row.toolType === newSelectedToolType) &&
        (!newSelectedSiteUsing || row.siteUsing === newSelectedSiteUsing)
      );
    });

    setSelectedToolType(newSelectedToolType);
    setSelectedSiteUsing(newSelectedSiteUsing);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedToolType || row.toolType === selectedToolType) &&
        (!selectedSiteUsing || row.siteUsing === selectedSiteUsing)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedToolType("");
    setSelectedSiteUsing("");
    setRowData(originalData); // Reset to original data
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const calculateTotalAmount = (history) => {
    return history.reduce((sum, record) => sum + record.amount, 0);
  };

  const calculateToolConditionCount = (condition) => {
    return filteredData.filter((tool) => tool.condition === condition).length;
  };

  const calculateIdleTools = () => {
    return filteredData.filter((tool) => !tool.siteUsing).length;
  };

  const calculateIssuedTools = () => {
    return filteredData.length - calculateIdleTools();
  };

  const calculateTotalPrice = useMemo(() => {
    return filteredData.reduce((sum, tool) => sum + tool.purchasedPrice, 0);
  }, [filteredData]);

  const ownToolColumns = [
    {
      id: "sno",
      header: "SNo",
      accessor: (item) => item.sno,
      show: true,
      minWidth: 50,
    },
    {
      id: "toolName",
      header: "Tool name",
      accessor: (item) => item.toolName,
      show: true,
      minWidth: 150,
    },
    {
      id: "toolID",
      header: "Tool ID",
      accessor: (item) => item.toolID,
      show: true,
      minWidth: 150,
    },
    {
      id: "toolType",
      header: "Tool Type",
      accessor: (item) => item.toolType,
      show: true,
      minWidth: 150,
    },
    {
      id: "quantityAvailable",
      header: "Quantity Available",
      accessor: (item) => item.quantityAvailable,
      show: true,
      minWidth: 150,
    },
    {
      id: "siteUsing",
      header: "Site Using",
      accessor: (item) => item.siteUsing,
      show: true,
      minWidth: 150,
    },
    {
      id: "condition",
      header: "Condition",
      accessor: (item) => item.condition,
      show: true,
      minWidth: 150,
    },
    {
      id: "issuedTo",
      header: "Issued To",
      accessor: (item) => item.issuedTo,
      show: true,
      minWidth: 150,
    },
    {
      id: "purchasedPrice",
      header: "Purchased Price",
      accessor: (item) => item.purchasedPrice,
      show: true,
      minWidth: 150,
    },
    {
      id: "remarks",
      header: "Remarks",
      accessor: (item) => item.remarks,
      show: true,
      minWidth: 150,
    },
    {
      id: "maintenanceHistory",
      header: "Maintenance History",
      accessor: (item) => item.maintenanceHistory,
      render: (item) => calculateTotalAmount(item.maintenanceHistory),
      show: true,
      minWidth: 150,
    },
    {
      id: "issuedHistory",
      header: "Issued History",
      accessor: (item) => item.issuedHistory,
      render: (item) => calculateTotalAmount(item.issuedHistory),
      show: true,
      minWidth: 150,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.sno,
      render: (item) => (
        <IconButton icon={<DeleteIcon />} onClick={() => deleteRow(item.sno)} />
      ),
      show: true,
      minWidth: 100,
    },
  ];
  const [visibleColumns, setVisibleColumns] = useState(
    ownToolColumns.filter((col) => col.show !== false).map((col) => col.id)
  );
  const deleteRow = (sno) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (confirmed) {
      setRowData(rowData.filter((row) => row.sno !== sno));
      setFilteredData(filteredData.filter((row) => row.sno !== sno));
      toast({
        title: "Row deleted.",
        description: `Row with SNo ${sno} has been deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader
          title="Own Tools"
          to="/add-owntool"
          addButtonName="Add Tool"
          icon={<LiaToolsSolid />}
        />
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={LiaToolsSolid}
            name="Total Price"
            value={calculateTotalPrice}
            backgroundColor="blue.300"
            type="money"
          />
          <DashboardBox
            icon={LiaToolsSolid}
            name="Working Tools"
            value={calculateToolConditionCount("WORKING")}
            backgroundColor="green.300"
            type="number"
          />
          <DashboardBox
            icon={LiaToolsSolid}
            name="Damaged Tools"
            value={calculateToolConditionCount("RETIRE DAMAGED")}
            backgroundColor="red.300"
            type="number"
          />
          <DashboardBox
            icon={LiaToolsSolid}
            name="In Repair Tools"
            value={calculateToolConditionCount("IN REPAIR")}
            backgroundColor="yellow.300"
            type="number"
          />
          <DashboardBox
            icon={LiaToolsSolid}
            name="Idle Tools"
            value={calculateIdleTools}
            backgroundColor="gray.300"
            type="number"
          />
          <DashboardBox
            icon={LiaToolsSolid}
            name="Issued Tools"
            value={calculateIssuedTools}
            backgroundColor="purple.300"
            type="number"
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Own Tools"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.toolType && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Tool Type: ${filters.toolType}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("toolType")} />
            </Tag>
          )}
          {filters.siteUsing && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Site Using: ${filters.siteUsing}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("siteUsing")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Tool Type"
                onChange={(e) => setSelectedToolType(e.target.value)}
                mb={4}
                value={selectedToolType}
              >
                <option value="Electrical">Electrical</option>
                <option value="Plumbing">Plumbing</option>
                <option value="Carpentry">Carpentry</option>
              </Select>
              <Select
                placeholder="Select Site"
                onChange={(e) => setSelectedSiteUsing(e.target.value)}
                value={selectedSiteUsing}
              >
                <option value="Site 1">Site 1</option>
                <option value="Site 2">Site 2</option>
                <option value="Site 3">Site 3</option>
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {ownToolColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={ownToolColumns}
          data={filteredData}
          title="Tools List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
        />
      </Box>
    </Box>
  );
};

export default OwnTools;
