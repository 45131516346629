import React, { useState } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Box,
  Flex,
  Heading,
  Select,
  Text,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import "chart.js/auto";
import GenericHeader from "../../commons/GenericHeader";

const CostManagement = () => {
  const [selectedProject, setSelectedProject] = useState("Project A");

  // Dummy data
  const projects = ["Project A", "Project B", "Project C"];
  const costData = {
    "Project A": {
      budget: 100000,
      actual: 90000,
      variance: -10000,
      categories: {
        Labor: 30000,
        Materials: 40000,
        Equipment: 20000,
      },
      revenue: 120000,
      costsOverTime: [10000, 20000, 40000, 70000, 90000],
      revenueOverTime: [15000, 35000, 60000, 90000, 120000],
      varianceOverTime: [-2000, -3000, -1000, 500, -10000],
    },
    "Project B": {
      budget: 150000,
      actual: 140000,
      variance: -10000,
      categories: {
        Labor: 50000,
        Materials: 60000,
        Equipment: 30000,
      },
      revenue: 180000,
      costsOverTime: [20000, 50000, 90000, 120000, 140000],
      revenueOverTime: [25000, 60000, 100000, 140000, 180000],
      varianceOverTime: [-5000, -2000, -4000, -1000, -10000],
    },
    "Project C": {
      budget: 200000,
      actual: 220000,
      variance: 20000,
      categories: {
        Labor: 80000,
        Materials: 90000,
        Equipment: 50000,
      },
      revenue: 250000,
      costsOverTime: [30000, 70000, 120000, 180000, 220000],
      revenueOverTime: [40000, 80000, 150000, 200000, 250000],
      varianceOverTime: [1000, 3000, 5000, 7000, 20000],
    },
  };

  const project = costData[selectedProject];

  return (
    <Box p={5}>
      <GenericHeader title="Cost Management" />

      <Flex gap={5} justifyContent={"space-between"} mb={10}>
        <Box mb={5}>
          <Select
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
            size="lg"
            maxWidth="300px"
          >
            {projects.map((project) => (
              <option key={project} value={project}>
                {project}
              </option>
            ))}
          </Select>
        </Box>
        <Box mt={5}>
          <Flex gap={3}>
            <Button
              colorScheme="teal"
              onClick={() => alert("Cost Report generated!")}
            >
              Generate Cost Report
            </Button>
            <Button
              colorScheme="teal"
              onClick={() => alert("Variance Report generated!")}
            >
              Generate Variance Report
            </Button>
            <Button
              colorScheme="teal"
              onClick={() => alert("Trend Report generated!")}
            >
              Generate Trend Report
            </Button>
          </Flex>
        </Box>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
        <Box p={5} boxShadow="md" borderRadius="md" bg="white" maxWidth="100%">
          <Heading as="h4" size="sm" mb={3}>
            Budget Summary
          </Heading>
          <Text>Total Budget: ${project.budget}</Text>
          <Text>Total Spent: ${project.actual}</Text>
          <Text>Remaining Budget: ${project.budget - project.actual}</Text>
        </Box>

        <Box p={5} boxShadow="md" borderRadius="md" bg="white" maxWidth="100%">
          <Heading as="h4" size="sm" mb={3}>
            Budget vs. Actual Costs
          </Heading>
          <Bar
            data={{
              labels: ["Budget", "Actual"],
              datasets: [
                {
                  label: "Costs",
                  data: [project.budget, project.actual],
                  backgroundColor: ["#36A2EB", "#FF6384"],
                },
              ],
            }}
            height={200}
          />
        </Box>

        <Box p={5} boxShadow="md" borderRadius="md" bg="white" maxWidth="100%">
          <Heading as="h4" size="sm" mb={3}>
            Variance Analysis Over Time
          </Heading>
          <Line
            data={{
              labels: ["Month 1", "Month 2", "Month 3", "Month 4", "Month 5"],
              datasets: [
                {
                  label: "Variance Over Time",
                  data: project.varianceOverTime,
                  borderColor: project.variance > 0 ? "#4BC0C0" : "#FF6384",
                  fill: false,
                },
              ],
            }}
            height={200}
          />
        </Box>

        <Box p={5} boxShadow="md" borderRadius="md" bg="white" maxWidth="100%">
          <Heading as="h4" size="sm" mb={3}>
            Cost Distribution
          </Heading>
          <Pie
            data={{
              labels: Object.keys(project.categories),
              datasets: [
                {
                  label: "Cost Distribution",
                  data: Object.values(project.categories),
                  backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                },
              ],
            }}
            height={200}
          />
        </Box>

        <Box p={5} boxShadow="md" borderRadius="md" bg="white" maxWidth="100%">
          <Heading as="h4" size="sm" mb={3}>
            Cost vs. Revenue Over Time
          </Heading>
          <Line
            data={{
              labels: ["Month 1", "Month 2", "Month 3", "Month 4", "Month 5"],
              datasets: [
                {
                  label: "Costs Over Time",
                  data: project.costsOverTime,
                  borderColor: "#FF6384",
                  fill: false,
                },
                {
                  label: "Revenue Over Time",
                  data: project.revenueOverTime,
                  borderColor: "#36A2EB",
                  fill: false,
                },
              ],
            }}
            height={200}
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default CostManagement;
