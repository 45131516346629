import React, { useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const generateYears = (startYear, endYear) => {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
};

const YearSelector = ({
  startYear = 2000,
  endYear = new Date().getFullYear(),
  onYearChange,
}) => {
  const [selectedYear, setSelectedYear] = useState(endYear);
  const [searchTerm, setSearchTerm] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const years = generateYears(startYear, endYear);

  const handleYearChange = (year) => {
    setSelectedYear(year);
    onYearChange && onYearChange(year);
    onClose();
  };

  const filteredYears = years.filter((year) =>
    year.toString().includes(searchTerm)
  );

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        onClick={onOpen}
        variant="outline"
        size="lg"
        colorScheme="teal"
        _focus={{ boxShadow: "outline" }}
        _hover={{ bg: "teal.100" }}
        bg="white"
        zIndex="9999"
      >
        {selectedYear}
      </MenuButton>
      <MenuList maxH="200px" overflowY="auto" zIndex="9999" boxShadow="lg">
        <Box px={3} pb={2}>
          <Input
            placeholder="Search year"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="sm"
            mb={2}
          />
        </Box>
        {filteredYears.map((year) => (
          <MenuItem
            key={year}
            onClick={() => handleYearChange(year)}
            _hover={{ bg: "teal.100" }}
            _focus={{ bg: "teal.200" }}
          >
            {year}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default YearSelector;
