import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  useToast,
  HStack,
} from "@chakra-ui/react";
import GenericList from "../../commons/GenericList";
import { useSelector } from "react-redux";
import { selectOwnTools, selectRentalTools } from "../../store/toolsSlice";
import { GoIssueTracks } from "react-icons/go";
import GenericHeader from "../../commons/GenericHeader";
import { LiaToolsSolid } from "react-icons/lia";
import OwnTools from "./OwnTools";
import RentalTools from "./RentalTools";

const ToolsList = () => {
  const [tabIndex, setTabIndex] = useState(1); // Default to Rental Tools
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleSave = () => {
    // Implement the save logic here
    toast({
      title: "Tool issued successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onClose();
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <GenericHeader title="Tools" icon={<LiaToolsSolid />} />
        <Button
          onClick={onOpen}
          colorScheme="whatsapp"
          display={"flex"}
          gap={5}
        >
          <GoIssueTracks /> Issue Tool
        </Button>
      </Box>
      <Tabs
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        variant="unstyled"
      >
        <TabList>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Own Tools
          </Tab>
          <Tab
            borderRadius={20}
            __css={{ borderRadius: "20" }}
            _selected={{ color: "white", bg: "primary.500" }}
          >
            Rental Tools
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <OwnTools />
          </TabPanel>
          <TabPanel>
            <RentalTools />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"lg"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Issue Tool</DrawerHeader>

          <DrawerBody>
            <FormControl as={HStack} mt={4} spacing={4}>
              <FormLabel minWidth="100px">Tool</FormLabel>
              <Select placeholder="Select tool">
                <option value="hammer">Hammer</option>
                <option value="screwdriver">Screwdriver</option>
                <option value="wrench">Wrench</option>
              </Select>
            </FormControl>

            <FormControl as={HStack} mt={4} spacing={4}>
              <FormLabel minWidth="100px">Rate</FormLabel>
              <Input type="number" />
            </FormControl>

            <FormControl as={HStack} mt={4} spacing={4}>
              <FormLabel minWidth="100px">Site</FormLabel>
              <Select placeholder="Select site">
                <option value="siteA">Site A</option>
                <option value="siteB">Site B</option>
                <option value="siteC">Site C</option>
              </Select>
            </FormControl>

            <FormControl as={HStack} mt={4} spacing={4}>
              <FormLabel minWidth="100px">Stage</FormLabel>
              <Select placeholder="Select stage">
                <option value="stage1">Stage 1</option>
                <option value="stage2">Stage 2</option>
                <option value="stage3">Stage 3</option>
              </Select>
            </FormControl>

            <FormControl as={HStack} mt={4} spacing={4}>
              <FormLabel minWidth="100px">Issued To</FormLabel>
              <Select placeholder="Select foreman">
                <option value="akram">Akram</option>
                <option value="aslam">Aslam</option>
                <option value="john">John</option>
              </Select>
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ToolsList;
