import React from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Divider,
  Heading,
  Image,
  Button,
  Flex,
} from "@chakra-ui/react";
import logo from "../../images/Logo-Header.png";
const ManpowerReceipt = ({ formData }) => {
  return (
    <Box
      p={8}
      bg="white"
      borderRadius="lg"
      boxShadow="lg"
      width="100%"
      maxW="600px"
      margin="0 auto"
    >
      {/* Header with Company Logo and Title */}
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Image
          src={logo}
          alt="Company Logo"
          width="120px"
          objectFit="contain"
        />
        <Box textAlign="right">
          <Heading size="lg" color="teal.600">
            Payment Receipt
          </Heading>
          <Text fontSize="sm" color="gray.500">
            Date: {new Date().toLocaleDateString()}
          </Text>
        </Box>
      </Flex>

      <Divider mb={6} />

      {/* Worker Details */}
      <VStack align="start" spacing={2} mb={6}>
        <Heading size="md" color="teal.600">
          Worker Information
        </Heading>
        <HStack>
          <Text fontWeight="bold">Name:</Text>
          <Text>{formData.name}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Designation:</Text>
          <Text>{formData.skill}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Site Name:</Text>
          <Text>{formData.siteName}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Stage Name:</Text>
          <Text>{formData.stageName}</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Foreman:</Text>
          <Text>{formData.foreman}</Text>
        </HStack>
      </VStack>

      <Divider mb={6} />

      {/* Work Description */}
      <VStack align="start" spacing={2} mb={6}>
        <Heading size="md" color="teal.600">
          Work Description
        </Heading>
        <Text>{formData.workDescription}</Text>
      </VStack>

      <Divider mb={6} />

      {/* Payment Details */}
      <VStack align="start" spacing={2} mb={6}>
        <Heading size="md" color="teal.600">
          Payment Details
        </Heading>
        <HStack>
          <Text fontWeight="bold">Rate:</Text>
          <Text>{formData.rate} AED</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Paid:</Text>
          <Text>{formData.paid} AED</Text>
        </HStack>
        <HStack>
          <Text fontWeight="bold">Balance:</Text>
          <Text>{formData.balance} AED</Text>
        </HStack>
      </VStack>

      <Divider mb={6} />

      {/* Footer */}
      <Text fontSize="sm" color="gray.500" mb={4}>
        This is a system-generated receipt.
      </Text>
      <Text fontSize="sm" color="gray.500">
        For any inquiries, please contact us at support@company.com
      </Text>

      <Button
        colorScheme="teal"
        mt={8}
        width="100%"
        onClick={() => window.print()}
      >
        Print Receipt
      </Button>
    </Box>
  );
};

export default ManpowerReceipt;
