import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ownTools: [
    {
      sno: 1,
      toolName: "Grinder",
      toolID: "T001",
      toolType: "small",
      quantityAvailable: 10,
      siteUsing: "Site A",
      condition: "WORKING",
      issuedTo: "Worker A",
      purchasedPrice: 1000,
      remarks: "New tool",
      maintenanceHistory: [
        { date: "2023-01-01", typeOfWork: "Repair", amount: 100 },
        { date: "2023-06-01", typeOfWork: "Maintenance", amount: 50 },
      ],
      issuedHistory: [
        {
          issueDate: "2023-01-10",
          returnDate: "2023-01-20",
          foremanName: "Foreman A",
          site: "Site A",
          stage: "Initial",
          amount: 500,
        },
      ],
    },
    {
      sno: 2,
      toolName: "HILTI",
      toolID: "T002",
      toolType: "medium",
      quantityAvailable: 5,
      siteUsing: "Site B",
      condition: "IN REPAIR",
      issuedTo: "Worker B",
      purchasedPrice: 2000,
      remarks: "Old tool",
      maintenanceHistory: [],
      issuedHistory: [],
    },
    // Additional tool objects...
  ],
  rentalTools: [
    {
      sno: 1,
      hireDate: "2023-06-01",
      returnDate: "2023-06-10",
      totalDaysUsed: 10,
      siteName: "Site B",
      rentalShopName: "Rental Shop 1",
      toolName: "SPRAY MACHINE",
      toolType: "small",
      rateAmountPerDay: 500,
      paid: 3000,
      balance: 2000,
    },
    // Additional rental tool objects...
  ],
};

const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    addOwnTool: (state, action) => {
      state.ownTools.push({
        sno: state.ownTools.length + 1,
        ...action.payload,
      });
    },
    addRentalTool: (state, action) => {
      state.rentalTools.push(action.payload);
    },
    updateRentalTool: (state, action) => {
      const index = state.rentalTools.findIndex(
        (tool) => tool.sno === action.payload.sno
      );
      if (index !== -1) {
        state.rentalTools[index] = action.payload;
      }
    },
  },
});

export const { addOwnTool, addRentalTool, updateRentalTool } =
  toolsSlice.actions;

export const selectOwnTools = (state) => state.tools.ownTools;
export const selectRentalTools = (state) => state.tools.rentalTools;
export const selectRentalToolById = (state, id) =>
  state.tools.rentalTools.find((tool) => tool.sno === parseInt(id));

export default toolsSlice.reducer;
