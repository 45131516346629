// TransitionWrapper.js
import React from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";
import "../styles/transitions.css";

const TransitionWrapper = ({ children }) => {
  const location = useLocation();
  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        classNames="slide"
        timeout={300}
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default TransitionWrapper;
