import React, { useState, useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import GenericHeader from "../../../../commons/GenericHeader";
import DashboardBox from "../../../../commons/DashboardBox";
import GenericGrid from "../../../Procurement/GenericGrid";

const EmployeePayrollDetails = () => {
  const { employeeName } = useParams();

  // Dummy data for payroll details
  const payrollDetailsData = [
    {
      id: 1,
      month: "Jan 2024",
      dateProcessed: "2024-01-25",
      amount: 3200,
      bank: "ABC Bank",
    },
    {
      id: 2,
      month: "Feb 2024",
      dateProcessed: "2024-02-25",
      amount: 3100,
      bank: "XYZ Bank",
    },
    {
      id: 3,
      month: "Mar 2024",
      dateProcessed: "2024-03-25",
      amount: 3300,
      bank: "DEF Bank",
    },
    // Add more data as needed...
  ];

  const [totalSalaryProcessed, setTotalSalaryProcessed] = useState(0);
  const [totalBonus, setTotalBonus] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);

  const payrollColumns = [
    {
      id: "month",
      header: "Month",
      accessor: "month", // Ensure this matches the key in your data
      show: true,
      width: 100,
    },
    {
      id: "dateProcessed",
      header: "Date Salary Processed",
      accessor: "dateProcessed", // Ensure this matches the key in your data
      show: true,
      width: 150,
    },
    {
      id: "amount",
      header: "Amount",
      accessor: "amount", // Ensure this matches the key in your data
      show: true,
      width: 100,
    },
    {
      id: "bank",
      header: "Bank",
      accessor: "bank", // Ensure this matches the key in your data
      show: true,
      width: 150,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    payrollColumns.filter((col) => col.show).map((col) => col.id)
  );

  useEffect(() => {
    // Calculate totals from the data (dummy calculations)
    const totalSalary = payrollDetailsData.reduce(
      (acc, row) => acc + row.amount,
      0
    );
    setTotalSalaryProcessed(totalSalary);
    setTotalBonus(500); // Dummy value for bonus
    setTotalDeduction(200); // Dummy value for deductions
  }, [payrollDetailsData]);

  return (
    <Box>
      <GenericHeader title={`Payroll Details - ${employeeName}`} />

      {/* Dashboard Boxes */}
      <Flex justifyContent="space-between" mb={4} mt={4} gap={2}>
        <DashboardBox
          name="Total Salary Processed"
          value={`$${totalSalaryProcessed}`}
          type="money"
          backgroundColor="teal.400"
        />
        <DashboardBox
          name="Total Bonus"
          value={`$${totalBonus}`}
          type="money"
          backgroundColor="blue.300"
        />
        <DashboardBox
          name="Total Deduction"
          value={`$${totalDeduction}`}
          type="money"
          backgroundColor="red.300"
        />
      </Flex>

      {/* Payroll Details Table */}
      <Box>
        <GenericGrid
          columns={payrollColumns}
          data={payrollDetailsData}
          title={`Salary Details for ${employeeName}`}
          visibleColumnsState={visibleColumns}
          onVisibleColumnsChange={setVisibleColumns}
        />
      </Box>
    </Box>
  );
};

export default EmployeePayrollDetails;
