import React, { useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useReactToPrint } from "react-to-print";
import Logo from "../../images/Logo-Header.png"; // Example logo import

const SubcontractorAgreement = ({ agreementData }) => {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Box>
      <Box ref={printRef} p={2} bg={useColorModeValue("white", "gray.800")}>
        <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb={8}>
          Subcontractor Agreement
        </Text>
        <Divider mb={6} />
        <Flex justifyContent={"space-between"}>
          <Box>
            <img src={Logo} alt="Company Logo" style={{ width: "100px" }} />
            <Text fontSize="lg" fontWeight="bold">
              Your Company Name
            </Text>
            <Text>123 Street Address, City, State, ZIP</Text>
            <Text>Phone: (123) 456-7890</Text>
          </Box>
          <Box mb={6}>
            <Text fontSize="lg" fontWeight="bold">
              Agreement Date: {agreementData.date}
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              Subcontractor: {agreementData.subcontractorName}
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              Site Name: {agreementData.siteName}
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              Type of Work: {agreementData.typeOfWork}
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              Agreement Amount: AED {agreementData.agreementAmount.toFixed(2)}
            </Text>
          </Box>
        </Flex>
        <Divider mb={6} />
        {/* Agreement Details */}

        {/* Work Description */}
        <Box mb={6}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Work Description
          </Text>
          <Text>{agreementData.workRequired}</Text>
        </Box>
        {/* Payment Schedule */}
        <Box mb={6}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Payment Schedule
          </Text>
          <Table variant="simple">
            <Thead bg={useColorModeValue("gray.100", "gray.700")}>
              <Tr>
                <Th>Payment No</Th>
                <Th>Work Description</Th>
                <Th>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {agreementData.payments.map((payment, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{payment.work}</Td>
                  <Td>AED {payment.amount.toFixed(2)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        {/* Summary Section */}
        <Box mb={6}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Summary
          </Text>
          <Flex justifyContent="space-between">
            <Text>Total Amount:</Text>
            <Text>AED {agreementData.agreementAmount.toFixed(2)}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text>Total Paid:</Text>
            <Text>AED {agreementData.totalPaid.toFixed(2)}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text>Balance:</Text>
            <Text>AED {agreementData.totalBalance.toFixed(2)}</Text>
          </Flex>
        </Box>
        <Divider mb={6} />
        {/* Terms and Conditions */}
        <Box mb={6}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Terms and Conditions
          </Text>
          <Text>
            [Insert standard subcontractor agreement terms and conditions here,
            covering aspects like scope of work, payment terms, deadlines,
            quality of work, etc.]
          </Text>
        </Box>
        {/* Signatures */}
        <Box>
          <Flex justify="space-between" mt={12}>
            <Box textAlign="center">
              <Text>_________________________</Text>
              <Text>Authorized Company Signature</Text>
              <Text>Date</Text>
            </Box>
            <Box textAlign="center">
              <Text>_________________________</Text>
              <Text>Subcontractor Signature</Text>
              <Text>Date</Text>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Button colorScheme="primary" onClick={handlePrint}>
        Print Agreement
      </Button>
    </Box>
  );
};

export default SubcontractorAgreement;
