// src/store/weeklyMaterialRequirementSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requirements: [
    {
      id: 1,
      dateFrom: new Date("2024-06-01"),
      dateTo: new Date("2024-06-07"),
      project: "Project A",
      workRequired: "Concrete foundation work",
      materialRequired: [
        { name: "Cement", qty: 100 },
        { name: "Sand", qty: 200 },
        { name: "Steel", qty: 50 },
      ],
    },
    {
      id: 2,
      dateFrom: new Date("2024-06-08"),
      dateTo: new Date("2024-06-14"),
      project: "Project B",
      workRequired: "Bricklaying",
      materialRequired: [
        { name: "Cement", qty: 50 },
        { name: "Sand", qty: 100 },
      ],
    },
    {
      id: 3,
      dateFrom: new Date("2024-06-15"),
      dateTo: new Date("2024-06-21"),
      project: "Project C",
      workRequired: "Plastering",
      materialRequired: [
        { name: "Cement", qty: 30 },
        { name: "Sand", qty: 70 },
      ],
    },
  ],
  materials: [
    { id: 1, name: "Cement", price: 20 },
    { id: 2, name: "Sand", price: 10 },
    { id: 3, name: "Steel", price: 50 },
  ],
};

const weeklyMaterialRequirementSlice = createSlice({
  name: "weeklyMaterialRequirement",
  initialState,
  reducers: {
    addRequirement: (state, action) => {
      state.requirements.push(action.payload);
    },
    updateRequirement: (state, action) => {
      const index = state.requirements.findIndex(
        (req) => req.id === action.payload.id
      );
      if (index !== -1) {
        state.requirements[index] = action.payload;
      }
    },
  },
});

export const { addRequirement, updateRequirement } =
  weeklyMaterialRequirementSlice.actions;

export const selectWeeklyRequirements = (state) =>
  state.weeklyMaterialRequirement.requirements;
export const selectMaterials = (state) =>
  state.weeklyMaterialRequirement.materials;

export default weeklyMaterialRequirementSlice.reducer;
