import React from "react";
import {
  Box,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

import { FaProjectDiagram, FaDollarSign, FaChartLine } from "react-icons/fa";
import { Line, Pie } from "react-chartjs-2";
import GenericHeader from "../../../commons/GenericHeader";
import DashboardBox from "../../../commons/DashboardBox";

const FinanceDashboard = () => {
  // Dummy data
  const revenueVsExpenses = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Revenue",
        data: [50000, 75000, 60000, 90000, 100000, 85000, 95000],
        borderColor: "#68D391",
        backgroundColor: "rgba(104, 211, 145, 0.2)",
      },
      {
        label: "Expenses",
        data: [40000, 45000, 30000, 60000, 65000, 70000, 60000],
        borderColor: "#FC8181",
        backgroundColor: "rgba(252, 129, 129, 0.2)",
      },
    ],
  };

  const expenseBreakdown = {
    labels: ["Labor", "Material", "Overhead"],
    datasets: [
      {
        data: [40000, 30000, 15000],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  return (
    <Box p={5}>
      <GenericHeader title="Finance Dashboard" />

      <Tabs>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Project Summary</Tab>
          <Tab>Key Metrics</Tab>
          <Tab>Accounts Overview</Tab>
        </TabList>

        <TabPanels>
          {/* Overview */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={8}>
              <DashboardBox
                icon={FaDollarSign}
                name="Total Revenue"
                value="600,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Total Expenses"
                value="400,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Net Profit"
                value="200,000"
                backgroundColor="#4299E1"
                type="money"
              />
            </SimpleGrid>
            <Box mt={10}>
              <Line data={revenueVsExpenses} />
            </Box>
          </TabPanel>

          {/* Project Summary */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={8}>
              <DashboardBox
                icon={FaProjectDiagram}
                name="Active Projects"
                value="5"
                backgroundColor="#4299E1"
                type="number"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Total Budget"
                value="1,200,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Total Spent"
                value="950,000"
                backgroundColor="#FC8181"
                type="money"
              />
            </SimpleGrid>
          </TabPanel>

          {/* Key Metrics */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={8}>
              <DashboardBox
                icon={FaChartLine}
                name="Revenue Growth"
                value="15%"
                backgroundColor="#68D391"
                type="text"
              />
              <DashboardBox
                icon={FaChartLine}
                name="Expense Growth"
                value="5%"
                backgroundColor="#FC8181"
                type="text"
              />
              <DashboardBox
                icon={FaChartLine}
                name="Profit Margin"
                value="25%"
                backgroundColor="#4299E1"
                type="text"
              />
            </SimpleGrid>
          </TabPanel>

          {/* Accounts Overview */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={8}>
              <DashboardBox
                icon={FaDollarSign}
                name="Accounts Payable"
                value="80,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Accounts Receivable"
                value="150,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Bank Balance"
                value="120,000"
                backgroundColor="#4299E1"
                type="money"
              />
            </SimpleGrid>
            <Box mt={10}>
              <Pie data={expenseBreakdown} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default FinanceDashboard;
