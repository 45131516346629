import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  Divider,
  VStack,
  HStack,
  Spacer,
  Link,
} from "@chakra-ui/react";
import logo from "../../images/Logo-Header.png";

const TransportReceiptPreview = ({ transportData }) => {
  return (
    <Box
      p={8}
      maxW="600px"
      borderRadius="lg"
      border="1px solid #E2E8F0"
      background="white"
      boxShadow="lg"
      margin="20px auto"
    >
      {/* Company Logo and Name */}
      <VStack spacing={4} align="center" mb={6}>
        <Image src={logo} alt="Company Logo" maxH="60px" />
        <Heading size="lg" textAlign="center" color="gray.700">
          Transport Invoice
        </Heading>
      </VStack>

      <Divider mb={6} />

      {/* Receipt Header */}
      <HStack justifyContent="space-between" mb={6}>
        <Box>
          <Text fontWeight="bold" color="gray.600">
            Date:
          </Text>
          <Text color="gray.800">{transportData.date}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold" color="gray.600">
            Receipt No:
          </Text>
          <Text color="gray.800">{Math.floor(Math.random() * 100000)}</Text>
        </Box>
      </HStack>

      <Divider mb={6} />

      {/* Site and Transporter Details */}
      <HStack justifyContent="space-between" alignItems="flex-start" mb={6}>
        <Box width="48%">
          <Text fontWeight="bold" color="gray.600">
            Site Name and Area:
          </Text>
          <Text color="gray.800">{transportData.siteName}</Text>
        </Box>
        <Box width="48%">
          <Text fontWeight="bold" color="gray.600">
            Transporter/Party Name:
          </Text>
          <Text color="gray.800">{transportData.transporter}</Text>
        </Box>
      </HStack>

      {/* Work Type */}
      <Box mb={6}>
        <Text fontWeight="bold" color="gray.600">
          Work Type:
        </Text>
        <Text color="gray.800">{transportData.workType}</Text>
      </Box>

      <Divider mb={6} />

      {/* Payment Details */}
      <HStack justifyContent="space-between" mb={6}>
        <Box width="48%">
          <Text fontWeight="bold" color="gray.600">
            Rate:
          </Text>
          <Text color="gray.800">{transportData.rate} AED</Text>
        </Box>
        <Box width="48%">
          <Text fontWeight="bold" color="gray.600">
            Paid:
          </Text>
          <Text color="gray.800">{transportData.paid} AED</Text>
        </Box>
      </HStack>
      <Box textAlign="center" mb={6}>
        <Text fontWeight="bold" fontSize="xl" color="red.600">
          Balance: {transportData.balance} AED
        </Text>
      </Box>

      <Divider mb={6} />

      {/* Remarks */}
      <Box mb={6}>
        <Text fontWeight="bold" color="gray.600">
          Remarks:
        </Text>
        <Text color="gray.800">{transportData.remarks}</Text>
      </Box>

      {/* Attached Photo */}
      {transportData.photo && (
        <Box mt={6} textAlign="center">
          <Text fontWeight="bold" mb={4} color="gray.600">
            Attached Photo:
          </Text>
          <Image
            src={transportData.photo}
            alt="Transport Photo"
            maxH="250px"
            borderRadius="md"
            border="1px solid #E2E8F0"
            boxShadow="md"
          />
        </Box>
      )}

      {/* Footer */}
      <Box textAlign="center" mt={8}>
        <Text fontSize="sm" color="gray.500">
          This is a system generated receipt.
        </Text>
        <Text fontSize="sm" color="gray.500">
          For any inquiries, please contact us at{" "}
          <Link href="mailto:support@example.com" color="blue.500">
            support@example.com
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default TransportReceiptPreview;
