import React from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
  Text,
  Image,
  Badge,
} from "@chakra-ui/react";

const ProjectDetailsModal = ({ isOpen, onClose, project }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Project Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Box>
              <Image src={project.image} alt="Project" />
            </Box>
            <Box>
              <Text color="gray.600" fontWeight="bold">
                Name:
              </Text>
              <Text color="black" fontWeight="bold">
                {project.name}
              </Text>
            </Box>
            <Box>
              <Text color="gray.600" fontWeight="bold">
                Agreement Amount:
              </Text>
              <Text color="black" fontWeight="bold">
                {project.agreementAmount}
              </Text>
            </Box>
            <Box>
              <Text color="gray.600" fontWeight="bold">
                Start Date:
              </Text>
              <Text color="black" fontWeight="bold">
                {project.startDate}
              </Text>
            </Box>
            <Box>
              <Text color="gray.600" fontWeight="bold">
                End Date:
              </Text>
              <Text color="black" fontWeight="bold">
                {project.endDate}
              </Text>
            </Box>
            <Box>
              <Text color="gray.600" fontWeight="bold">
                Status:
              </Text>
              <Text color="black" fontWeight="bold">
                {project.status}
              </Text>
            </Box>
            <Box>
              <Text color="gray.600" fontWeight="bold">
                Description:
              </Text>
              <Text color="black" fontWeight="bold">
                {project.description}
              </Text>
            </Box>
            <Box>
              <Text color="gray.600" fontWeight="bold">
                Stages:
              </Text>
              <Stack direction="row" spacing={2}>
                {project.stages.map((stage, index) => (
                  <Badge key={index} colorScheme="blue">
                    {stage.name}
                  </Badge>
                ))}
              </Stack>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          {/* Add additional buttons or actions as needed */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectDetailsModal;
