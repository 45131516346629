import React, { createContext, useContext, useState, useEffect } from "react";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";

const DynamicThemeContext = createContext();

export const useDynamicTheme = () => useContext(DynamicThemeContext);

export const DynamicThemeProvider = ({ children }) => {
  const [themeSettings, setThemeSettings] = useState({
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    primaryColor: "#db8700",
    secondaryColor: "#2B6CB0",
    backgroundColor: "#F0F2F5",
    listHoverColor: "#F6D9AA",
    textColor: "#2D3748",
    borderColor: "#CBD5E0",
    borderRadius: "4px",
    boxShadow: "md",
    theme: "light",
  });

  const theme = extendTheme({
    fonts: {
      body: themeSettings.fontFamily,
      heading: themeSettings.fontFamily,
    },
    fontSizes: {
      base: themeSettings.fontSize,
    },
    colors: {
      primary: {
        100: "#fff3e8",
        200: "#FFCC99",
        300: "#FFB266",
        400: "#FF9933",
        500: themeSettings.primaryColor, // main primary color
        600: "#CC6E00",
        700: "#994F00",
        800: "#663300",
        900: "#331A00",
      },
      secondary: themeSettings.secondaryColor,
      background: themeSettings.backgroundColor,
      text: themeSettings.textColor,
      border: themeSettings.borderColor,
    },
    radii: {
      base: themeSettings.borderRadius,
    },
    shadows: {
      base: themeSettings.boxShadow,
    },
    styles: {
      global: {
        body: {
          bg: themeSettings.backgroundColor,
          color: themeSettings.textColor,
        },
      },
    },
  });

  const updateTheme = (key, value) => {
    setThemeSettings((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--font-family",
      themeSettings.fontFamily
    );
    document.documentElement.style.setProperty(
      "--font-size",
      themeSettings.fontSize
    );
    document.documentElement.style.setProperty(
      "--primary-color",
      themeSettings.primaryColor
    );
    document.documentElement.style.setProperty(
      "--color-theme",
      themeSettings.theme
    );
  }, [
    themeSettings.fontFamily,
    themeSettings.fontSize,
    themeSettings.primaryColor,
    themeSettings.theme,
  ]);

  return (
    <DynamicThemeContext.Provider value={{ themeSettings, updateTheme }}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </DynamicThemeContext.Provider>
  );
};
