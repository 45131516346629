import React, { useState } from "react";
import { FaHome, FaCog, FaFile } from "react-icons/fa";
import { FcDataConfiguration } from "react-icons/fc";
import { SlPeople } from "react-icons/sl";
// Import icons
import {
  IoIosArrowForward,
  IoIosArrowDown,
  IoIosArrowUp,
} from "react-icons/io"; // Import icons for submenus
import { CiCalculator1 } from "react-icons/ci";
import { Tooltip as ReactTooltip } from "react-tooltip"; // Import Tooltip from react-tooltip
import { Link, useLocation } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "../styles/Sidebar.css"; // Import sidebar styles
import { Icon, Tooltip } from "@chakra-ui/react";
import { GrProjects } from "react-icons/gr";
import { RiDashboard3Line } from "react-icons/ri";
import { MdSwitchAccessShortcutAdd } from "react-icons/md";
import { GoQuote } from "react-icons/go";
import { LuListTodo } from "react-icons/lu";
const Sidebar = ({ collapsed }) => {
  const [openMenus, setOpenMenus] = useState({});
  const location = useLocation();

  const menuItems = [
    {
      label: "Dashboards",
      icon: "RiDashboard3Line",
      to: "/",
    },
    {
      label: "Operations",
      icon: "FaCog",
      subMenu: [
        {
          label: "Manpower",
          icon: <IoIosArrowForward />,
          to: "/daily-manpower",
        },
        { label: "Tools", icon: <IoIosArrowForward />, to: "/tools" },
        {
          label: "Daily Employee Duties",
          icon: <IoIosArrowForward />,
          to: "/employee-duties",
        },
        {
          label: "Work Required",
          icon: <IoIosArrowForward />,
          to: "/work-required",
        },
        {
          label: "Forman Reportings",
          icon: <IoIosArrowForward />,
          to: "/foreman-reporting",
        },
        {
          label: "Subcontractor",
          icon: <IoIosArrowForward />,
          to: "/subcontractors",
        },
        {
          label: "Transport",
          icon: <IoIosArrowForward />,
          to: "/transport",
        },
      ],
    },
    {
      label: "Projects",
      icon: "GrProjects",
      subMenu: [
        {
          label: "Project List",
          icon: "IoIosArrowForward",
          to: "/projects",
        },
        {
          label: "Project Costs",
          icon: "IoIosArrowForward",
          to: "/costing",
        },
        {
          label: "Bills",
          icon: "IoIosArrowForward",
          to: "/bills",
        },
      ],
    },
    {
      label: "HR",
      icon: "SlPeople",
      subMenu: [
        {
          label: "HR Modules",
          icon: "IoIosArrowForward",
          to: "/hr/wheel",
        },
        {
          label: "Employees",
          icon: "IoIosArrowForward",
          to: "/hr/employees",
        },
        {
          label: "Attendence",
          icon: "IoIosArrowForward",
          to: "/hr/attendence",
        },
      ],
    },
    {
      label: "Finance",
      icon: "CiCalculator1",
      subMenu: [
        {
          label: "Accounts",
          icon: "IoIosArrowForward",
          to: "/accounts",
        },
        {
          label: "Projects Finances",
          icon: "IoIosArrowForward",
          to: "/project-financials",
        },
        {
          label: "Financial Statements",
          icon: "IoIosArrowForward",
          to: "/financial-statements",
        },
        {
          label: "Payroll",
          icon: "IoIosArrowForward",
          to: "/payroll",
        },
        // {
        //   label: "Testing UI",
        //   icon: "IoIosArrowForward",
        //   to: "/testing",
        // },
        {
          label: "Finance Dashboard",
          icon: "IoIosArrowForward",
          to: "/finance-dashboard",
        },

        // {
        //   label: "Analytics",
        //   icon: "IoIosArrowForward",
        //   to: "/analytics",
        // },
      ],
    },
    {
      label: "Configuration",
      icon: "FcDataConfiguration",
      subMenu: [
        {
          label: "Vehicles",
          icon: "IoIosArrowForward",
          to: "/vehicles",
        },
        {
          label: "Documents",
          icon: "IoIosArrowForward",
          to: "/documents",
        },
        {
          label: "Quotation",
          icon: "GoQuote",
          to: "/quotations",
        },
        {
          label: "Materials",
          icon: "IoIosArrowForward",
          to: "/materials",
        },
        {
          label: "Professions",
          icon: "IoIosArrowForward",
          to: "/professions",
        },
      ],
    },
    {
      label: "Todo",
      icon: "LuListTodo",
      to: "/todo",
    },
    {
      label: "User Roles Access",
      icon: "MdSwitchAccessShortcutAdd",
      to: "/user-access",
    },
  ];

  const isActive = (path) => location.pathname.startsWith(path);

  const isMenuItemActive = (index) => {
    if (menuItems[index].to) {
      // For the dashboard item, only match exactly "/"
      if (menuItems[index].to === "/") {
        return location.pathname === "/";
      }
      // For other items, use startsWith
      return isActive(menuItems[index].to);
    }
    if (menuItems[index].subMenu) {
      return menuItems[index].subMenu.some((subItem) => isActive(subItem.to));
    }
    return false;
  };

  const toggleMenu = (index) => {
    setOpenMenus((prev) => {
      const updatedMenus = { ...prev };

      // Close all other open menus
      Object.keys(updatedMenus).forEach((menuIndex) => {
        if (menuIndex !== index.toString()) {
          updatedMenus[menuIndex] = false;
        }
      });

      // Toggle the clicked menu
      updatedMenus[index] = !prev[index];
      return updatedMenus;
    });
  };

  const iconMapping = {
    RiDashboard3Line: RiDashboard3Line,
    FaCog: FaCog,
    GrProjects: GrProjects,
    IoIosArrowForward: IoIosArrowForward,
    IoIosArrowDown: IoIosArrowDown,
    IoIosArrowUp: IoIosArrowUp,
    FcDataConfiguration: FcDataConfiguration,
    CiCalculator1: CiCalculator1,
    SlPeople: SlPeople,
    MdSwitchAccessShortcutAdd: MdSwitchAccessShortcutAdd,
    GoQuote: GoQuote,
    LuListTodo: LuListTodo,
  };

  return (
    <aside className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      <ul className="menu-list">
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={`menu-item ${isMenuItemActive(index) ? "active" : ""}`}
          >
            <Tooltip
              label={item.label}
              aria-label="tooltip"
              placement="right"
              hasArrow={true}
              isDisabled={collapsed ? false : true}
              fontSize="md"
              bg="primary.500"
            >
              <div
                className="menu"
                onClick={() => (item.subMenu ? toggleMenu(index) : null)}
                data-tooltip-content={collapsed ? item.label : ""}
                data-tooltip-id={`tooltip-${index}`}
                data-delay-hide="2000"
              >
                <Link to={item.to} className="menu-link">
                  <Icon as={iconMapping[item.icon]} boxSize={6} />
                  {!collapsed && <span>{item.label}</span>}
                </Link>
                {!collapsed &&
                  item.subMenu &&
                  (openMenus[index] ? <IoIosArrowUp /> : <IoIosArrowDown />)}
              </div>
            </Tooltip>
            {item.subMenu && (
              <ul className={`submenu ${openMenus[index] ? "active" : ""}`}>
                {item.subMenu.map((subItem, subIndex) => (
                  <li
                    key={subIndex}
                    className={`submenu-item ${
                      isActive(subItem.to) ? "active" : ""
                    }`}
                  >
                    <Link to={subItem.to}>{subItem.label}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default Sidebar;
