import React, { useState } from "react";
import { Button, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/authSlice";
import { useNavigate } from "react-router-dom";
import { IoMdLogIn } from "react-icons/io";
import { CiLock, CiUser } from "react-icons/ci";
import AuthLayout from "./AuthLayout";
import InputField from "../../commons/InputField";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const lastVisitedPath = useSelector((state) => state.auth.lastVisitedPath);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const handleLogin = () => {
    setLoading(true);
    setTimeout(() => {
      if (username === "admin" && password === "admin") {
        dispatch(login());
        navigate(lastVisitedPath || "/");
      } else {
        setLoading(false);
        toast({
          title: "Invalid credentials",
          description: "Please check your username and password.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }, 2000);
  };

  return (
    <AuthLayout title="Sign In">
      <InputField
        icon={<CiUser size={25} />}
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <InputField
        icon={<CiLock size={25} />}
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        bg="primary.500"
        color={"white"}
        _hover={{ color: "white", bg: "primary.500" }}
        width="100%"
        onClick={handleLogin}
        isLoading={loading}
        loadingText="Signing In"
      >
        Sign In <IoMdLogIn size={18} />
      </Button>
      <Button onClick={() => navigate("/signup")} variant="link">
        Don't have an account? Sign Up
      </Button>
    </AuthLayout>
  );
};

export default LoginPage;
