import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectWeeklyRequirements,
  selectMaterials,
} from "../../store/weeklyMaterialRequirementSlice";
import GenericHeader from "../../commons/GenericHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GenericGrid from "../Procurement/GenericGrid";
import SearchInput from "../../commons/SearchInput";
import debounce from "lodash/debounce";
import { MdWorkOutline } from "react-icons/md";

const WorkRequiredList = () => {
  const navigate = useNavigate();
  const weeklyRequirements = useSelector(selectWeeklyRequirements);
  const materials = useSelector(selectMaterials);
  const toast = useToast();

  const [filterDateRange, setFilterDateRange] = useState([null, null]);
  const [startDate, endDate] = filterDateRange;

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [selectedProject, setSelectedProject] = useState("");

  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  useEffect(() => {
    const formattedData = weeklyRequirements.map((requirement) => ({
      ...requirement,
      days: `${
        new Date(requirement.dateTo).getDate() -
        new Date(requirement.dateFrom).getDate() +
        1
      }`,
      materialRequired: requirement.materialRequired
        .map((material) => `${material.name}: ${material.qty}`)
        .join(", "),
      totalCost: calculateTotalCost(requirement.materialRequired),
      dateFrom: new Date(requirement.dateFrom).toLocaleDateString(),
      dateTo: new Date(requirement.dateTo).toLocaleDateString(),
    }));
    setOriginalData(formattedData);
    setFilteredData(formattedData);
  }, [weeklyRequirements, materials]);

  const calculateTotalCost = (materialRequired) => {
    return materialRequired
      .reduce((total, material) => {
        const materialInfo = materials.find((m) => m.name === material.name);
        const price = materialInfo ? materialInfo.price : 0;
        const vat = price * 0.05;
        return total + material.qty * (price + vat);
      }, 0)
      .toFixed(2);
  };

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    [originalData]
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filtered = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = () => {
    setSelectedProject("");
    setFilteredData(originalData);
  };

  const applyFilter = () => {
    const filtered = originalData.filter((row) => {
      return (
        (!selectedProject || row.project === selectedProject) &&
        (!startDate ||
          !endDate ||
          (new Date(row.dateFrom) >= new Date(startDate) &&
            new Date(row.dateTo) <= new Date(endDate)))
      );
    });

    setFilteredData(filtered);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedProject("");
    setFilterDateRange([null, null]);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const columns = useMemo(
    () => [
      {
        id: "dateFrom",
        header: "Date From",
        minWidth: "150px",
        render: (row) => row.dateFrom, // Display the dateFrom value
      },
      {
        id: "dateTo",
        header: "Date To",
        minWidth: "150px",
        render: (row) => row.dateTo, // Display the dateTo value
      },
      {
        id: "days",
        header: "Days",
        minWidth: "100px",
        render: (row) => row.days, // Display the days value
      },
      {
        id: "project",
        header: "Project",
        minWidth: "200px",
        render: (row) => row.project, // Display the project value
      },
      {
        id: "workRequired",
        header: "Work Required",
        minWidth: "300px",
        render: (row) => row.workRequired, // Display the workRequired value
      },
      {
        id: "materialRequired",
        header: "Material Required",
        minWidth: "300px",
        render: (row) => row.materialRequired, // Display the materialRequired value
      },
      {
        id: "totalCost",
        header: "Total Cost",
        minWidth: "150px",
        render: (row) => `AED ${row.totalCost}`, // Display the totalCost value formatted with currency
      },
    ],
    []
  );

  const [visibleColumns, setVisibleColumns] = useState(
    columns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleSort = (column) => {
    const direction = column.isSortedDesc ? "desc" : "asc";
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredData(sortedData);
  };

  const handleRowClick = (row) => {
    navigate(`/work-required/${row.id}`);
  };

  return (
    <Box p={4}>
      <GenericHeader
        title="Work Required"
        to={"/work-required/add-new"}
        icon={<MdWorkOutline />}
      />
      <Flex mb={4} justifyContent="space-between" alignItems="center">
        <Button onClick={onOpenFilterModal}>Filter</Button>
        <SearchInput
          placeholder="Search Work Required"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      {selectedProject && (
        <Flex mb={4}>
          <Tag
            size="md"
            borderRadius="10px"
            variant="solid"
            colorScheme="gray"
            mr={2}
          >
            <TagLabel fontWeight="bold">{`Project: ${selectedProject}`}</TagLabel>
            <TagCloseButton onClick={handleTagClose} />
          </Tag>
        </Flex>
      )}
      <GenericGrid
        columns={columns}
        data={filteredData}
        title="Work Required List"
        visibleColumnsState={visibleColumns}
        filterState={{ project: selectedProject }}
        onVisibleColumnsChange={handleVisibleColumnsChange}
        onSort={handleSort}
        onRowClick={handleRowClick}
      />
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Project"
                onChange={(e) => setSelectedProject(e.target.value)}
                value={selectedProject}
                mb={4}
              >
                {Array.from(
                  new Set(weeklyRequirements.map((req) => req.project))
                ).map((project) => (
                  <option key={project} value={project}>
                    {project}
                  </option>
                ))}
              </Select>
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setFilterDateRange(update)}
                isClearable={true}
                className="react-datepicker__input-container"
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {columns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WorkRequiredList;
