import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: JSON.parse(localStorage.getItem("projects")) || [],
  activeProject: null,
};

const calculateStageDates = (project) => {
  let currentDate = new Date(project.startDate);
  project.stages.forEach((stage, index) => {
    if (index === 0) {
      stage.plannedStartDate = new Date(currentDate);
    } else {
      const previousStage = project.stages[index - 1];
      stage.plannedStartDate = new Date(previousStage.plannedEndDate);
    }
    currentDate.setDate(currentDate.getDate() + stage.days);
    stage.plannedEndDate = new Date(currentDate);

    if (stage.actualFinishDate) {
      const actualFinishDate = new Date(stage.actualFinishDate);
      const plannedEndDate = new Date(stage.plannedEndDate);
      stage.extraDays = Math.max(
        0,
        (actualFinishDate - plannedEndDate) / (1000 * 60 * 60 * 24)
      );
    } else {
      stage.extraDays = 0;
    }
  });

  // Update project finish dates
  const lastStage = project.stages[project.stages.length - 1];
  project.plannedFinishDate = lastStage.plannedEndDate;
  project.actualFinishDate = lastStage.actualFinishDate || null;
  project.totalExtraDays = project.stages.reduce(
    (total, stage) => total + stage.extraDays,
    0
  );
};

export const projectListSlice = createSlice({
  name: "projectList",
  initialState,
  reducers: {
    addProject: (state, action) => {
      state.projects.push(action.payload);
      state.activeProject = action.payload;
      calculateStageDates(state.projects[state.projects.length - 1]);
      state.projects[state.projects.length - 1].stages.forEach((stage) => {
        stage.activities = [];
      });
      localStorage.setItem("projects", JSON.stringify(state.projects));
    },
    removeProject: (state, action) => {
      state.projects = state.projects.filter(
        (project) => project.name !== action.payload
      );
      localStorage.setItem("projects", JSON.stringify(state.projects));
    },
    updateStageDetails: (state, action) => {
      const { projectName, stageIndex, field, value } = action.payload;
      const project = state.projects.find((p) => p.name === projectName);
      if (project) {
        project.stages[stageIndex][field] = value;
        calculateStageDates(project);
        localStorage.setItem("projects", JSON.stringify(state.projects));
      }
    },
    updateCustomField: (state, action) => {
      const { projectName, stageIndex, customField } = action.payload;
      const project = state.projects.find((p) => p.name === projectName);
      if (project) {
        project.stages[stageIndex].customFields.push(customField);
        localStorage.setItem("projects", JSON.stringify(state.projects));
      }
    },
    addActivity: (state, action) => {
      const { projectName, stageIndex, field, value } = action.payload;
      const projectIndex = state.projects.findIndex(
        (p) => p.name === projectName
      );
      const project = state.projects.find((p) => p.name === projectName);
      if (project) {
        project.stages[stageIndex][field] = value;
        localStorage.setItem("projects", JSON.stringify(state.projects));
      }
    },
  },
});

// Export the reducer functions
export const {
  addProject,
  removeProject,
  updateStageDetails,
  updateCustomField,
  addActivity,
} = projectListSlice.actions;

// Export the selector function to access the projects array
export const selectProjects = (state) => state.projectList.projects;

export default projectListSlice.reducer;
