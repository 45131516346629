import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "../Procurement/GenericGrid";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox";
import { CiDeliveryTruck } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectVehicles } from "../../store/vehiclesSlice";

const VehicleList = () => {
  const vehicles = useSelector(selectVehicles);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [rowData, setRowData] = useState(vehicles);
  const [originalData] = useState(vehicles);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(vehicles);
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedVehicleType, setSelectedVehicleType] = useState("");

  const filters = useMemo(
    () => ({
      vehicleType: selectedVehicleType,
    }),
    [selectedVehicleType]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedVehicleType = selectedVehicleType;

    if (filterType === "vehicleType") {
      newSelectedVehicleType = "";
    }

    const filteredData = originalData.filter((row) => {
      return !newSelectedVehicleType || row.type === newSelectedVehicleType;
    });

    setSelectedVehicleType(newSelectedVehicleType);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return !selectedVehicleType || row.type === selectedVehicleType;
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedVehicleType("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const columns = [
    {
      id: "sno",
      header: "SNo",
      accessor: (item) => item.sno,
      show: true,
      minWidth: 50,
    },
    {
      id: "type",
      header: "Type",
      accessor: (item) => item.type,
      show: true,
      minWidth: 150,
    },
    {
      id: "plateNo",
      header: "Plate No",
      accessor: (item) => item.plateNo,
      show: true,
      minWidth: 150,
    },
    {
      id: "mulkyaExpiry",
      header: "MULKYA EXPIRY",
      accessor: (item) => item.mulkyaExpiry,
      show: true,
      minWidth: 150,
    },
    {
      id: "insuranceExpiry",
      header: "INSURANCE EXPIRY",
      accessor: (item) => item.insuranceExpiry,
      show: true,
      minWidth: 150,
    },
    {
      id: "maintenanceCost",
      header: "MAINTENANCE COST",
      accessor: (item) => item.maintenanceCost,
      show: true,
      minWidth: 150,
    },
    {
      id: "purchasePrice",
      header: "PURCHASE PRICE",
      accessor: (item) => item.purchasePrice,
      show: true,
      minWidth: 150,
    },
    {
      id: "mulkyaRenewalCost",
      header: "MULKYA RENEWAL COST",
      accessor: (item) => item.mulkyaRenewalCost,
      show: true,
      minWidth: 200,
    },
    {
      id: "driverName",
      header: "Driver Name",
      accessor: (item) => item.driverName,
      show: true,
      minWidth: 150,
    },
    {
      id: "dateHandover",
      header: "Date Handover",
      accessor: (item) => item.dateHandover,
      show: true,
      minWidth: 150,
    },
    {
      id: "dateReturn",
      header: "Date Return",
      accessor: (item) => item.dateReturn,
      show: true,
      minWidth: 150,
    },
    {
      id: "finesCount",
      header: "No of Time Fines",
      accessor: (item) => item.finesCount,
      show: true,
      minWidth: 150,
    },
    {
      id: "todoMaintenance",
      header: "TODO Maintenance",
      accessor: (item) => item.todoMaintenance,
      show: true,
      minWidth: 150,
    },
    {
      id: "totalCost",
      header: "Total Cost",
      accessor: (item) => item.totalCost,
      show: true,
      minWidth: 150,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.id,
      render: (item) => (
        <IconButton
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            // Handle delete vehicle logic here
          }}
        />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleRowClick = ({ sno }) => {
    console.log("sno", sno);
    navigate(`/vehicle-details/${sno}`);
  };

  const totalVehicles = vehicles.length;

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader
          title="Vehicles"
          addButtonName="Vehicle"
          to="/add-vehicle"
          icon={<CiDeliveryTruck />}
        />
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={CiDeliveryTruck}
            name="Total Vehicles"
            value={totalVehicles}
            backgroundColor="blue.300"
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Vehicles"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.vehicleType && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Vehicle Type: ${filters.vehicleType}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("vehicleType")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Vehicle Type"
                onChange={(e) => setSelectedVehicleType(e.target.value)}
                mb={4}
                value={selectedVehicleType}
              >
                {vehicles.map((vehicle) => (
                  <option key={vehicle.type} value={vehicle.type}>
                    {vehicle.type}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {columns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={columns}
          data={filteredData}
          title="Vehicle List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={setSortConfig}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default VehicleList;
