import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProject } from "../../store/projectsSlice";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  NumberInput,
  NumberInputField,
  Select,
  Flex,
  Heading,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoAddCircleOutline, IoArrowBackCircleOutline } from "react-icons/io5";
import { calculateDaysBetweenDates } from "../../utils";
import { HiOutlinePlusCircle } from "react-icons/hi2";

const AddSiteForm = () => {
  const projects = useSelector((state) => state.projectList.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalDays, setTotalDays] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    agreementAmount: "",
    variationAmounts: [{ amount: "", days: "" }],
    projectType: "Complete Villa",
    startDate: "",
    endDate: "",
    location: "",
    image: "",
    documents: [],
    stages: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "startDate" || name === "endDate") {
      const startDate = name === "startDate" ? value : formData.startDate;
      const endDate = name === "endDate" ? value : formData.endDate;
      if (startDate && endDate) {
        const diffDays = calculateDaysBetweenDates(startDate, endDate);
        setTotalDays(diffDays);
      }
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, image: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    const projectNameExists = projects.some(
      (project) => project.name === formData.name
    );
    if (projectNameExists) {
      toast({
        title: "Error",
        description:
          "Project name already exists. Please enter a different name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const totalStageDays = formData.stages.reduce(
      (acc, stage) => acc + parseInt(stage.days || 0, 10),
      0
    );
    if (totalStageDays > totalDays) {
      toast({
        title: "Error",
        description: `Total days for all stages (${totalStageDays}) exceed the total project days (${totalDays}). Please adjust the days.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    dispatch(addProject(formData));
    toast({
      title: "Success",
      description: "Project Added.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/projects");
  };

  const calculateRemainingDays = () => {
    const totalStageDays = formData.stages.reduce(
      (acc, stage) => acc + parseInt(stage.days || 0, 10),
      0
    );
    return totalDays - totalStageDays;
  };

  return (
    <>
      <Flex gap={5} align="center" mb="4">
        <IoArrowBackCircleOutline
          onClick={() => navigate("/projects")}
          color="#db8700"
          size={50}
          cursor={"pointer"}
        />

        <Heading as="h2" size="lg">
          <Flex gap={5} align={"center"}>
            <Text>Add Project / Site </Text>
            <HiOutlinePlusCircle color="green.600" fontSize={40} />
          </Flex>
        </Heading>
        <Box width="40px" />
      </Flex>

      <Box
        as="form"
        onSubmit={handleSubmit}
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Agreement Amount</FormLabel>
            <NumberInput
              min={0}
              name="agreementAmount"
              value={formData.agreementAmount}
              onChange={(value) =>
                setFormData({ ...formData, agreementAmount: value })
              }
            >
              <NumberInputField />
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel>Variation Amounts and Days</FormLabel>
            {formData.variationAmounts.map((variation, index) => (
              <Box key={index} display="flex" gap={2}>
                <NumberInput
                  min={0}
                  name="amount"
                  value={variation.amount}
                  onChange={(value) => {
                    const updatedVariations = [...formData.variationAmounts];
                    updatedVariations[index].amount = value;
                    setFormData({
                      ...formData,
                      variationAmounts: updatedVariations,
                    });
                  }}
                >
                  <NumberInputField placeholder="Amount" />
                </NumberInput>
                <NumberInput
                  min={0}
                  name="days"
                  value={variation.days}
                  onChange={(value) => {
                    const updatedVariations = [...formData.variationAmounts];
                    updatedVariations[index].days = value;
                    setFormData({
                      ...formData,
                      variationAmounts: updatedVariations,
                    });
                  }}
                >
                  <NumberInputField placeholder="Days" />
                </NumberInput>
                <Button
                  onClick={() => {
                    const updatedVariations = formData.variationAmounts.filter(
                      (_, i) => i !== index
                    );
                    setFormData({
                      ...formData,
                      variationAmounts: updatedVariations,
                    });
                  }}
                >
                  Remove
                </Button>
              </Box>
            ))}
            <Button
              onClick={() =>
                setFormData({
                  ...formData,
                  variationAmounts: [
                    ...formData.variationAmounts,
                    { amount: "", days: "" },
                  ],
                })
              }
            >
              Add Variation
            </Button>
          </FormControl>
          <FormControl>
            <FormLabel>Type of Project</FormLabel>
            <Select
              name="projectType"
              value={formData.projectType}
              onChange={handleInputChange}
            >
              <option>Villa Construction</option>
              <option>Additional Block</option>
              <option>Maintenance Project</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Planned Start Date</FormLabel>
            <Input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Planned End Date</FormLabel>
            <Input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Total Days Allotted: {totalDays} Days</FormLabel>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Location/Area</FormLabel>
            <Input
              name="location"
              value={formData.location}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Upload Picture</FormLabel>
            <Input type="file" accept="image/*" onChange={handleImageUpload} />
          </FormControl>
          <FormControl>
            <FormLabel>Upload Project Documents</FormLabel>
            <Input
              type="file"
              accept=".pdf,.doc,.docx"
              multiple
              onChange={(e) =>
                setFormData({
                  ...formData,
                  documents: Array.from(e.target.files),
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Stages</FormLabel>
            {formData.stages.map((stage, index) => (
              <Box key={index} display="flex" gap={2}>
                <Input
                  name="name"
                  placeholder="Stage Name"
                  value={stage.name}
                  onChange={(e) => {
                    const updatedStages = [...formData.stages];
                    updatedStages[index].name = e.target.value;
                    setFormData({ ...formData, stages: updatedStages });
                  }}
                />
                <NumberInput
                  min={0}
                  name="days"
                  value={stage.days}
                  onChange={(value) => {
                    const updatedStages = [...formData.stages];
                    updatedStages[index].days = value;
                    setFormData({ ...formData, stages: updatedStages });
                  }}
                >
                  <NumberInputField placeholder="Days" />
                </NumberInput>
                <Button
                  onClick={() => {
                    const updatedStages = formData.stages.filter(
                      (_, i) => i !== index
                    );
                    setFormData({ ...formData, stages: updatedStages });
                  }}
                >
                  Remove
                </Button>
              </Box>
            ))}
            <Button
              onClick={() =>
                setFormData({
                  ...formData,
                  stages: [
                    ...formData.stages,
                    { name: "", days: calculateRemainingDays() },
                  ],
                })
              }
              isDisabled={calculateRemainingDays() <= 0} // Disable the button if no remaining days
            >
              Add Stage
            </Button>
          </FormControl>

          <button
            type="submit"
            className="btn-primary"
            isDisabled={
              !formData.name ||
              !formData.agreementAmount ||
              !formData.startDate ||
              !formData.endDate ||
              !formData.location ||
              !formData.image
            }
          >
            <IoAddCircleOutline /> Add Site
          </button>
        </VStack>
      </Box>
    </>
  );
};

export default AddSiteForm;
