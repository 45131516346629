import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectRentalTools } from "../../store/toolsSlice";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "../Procurement/GenericGrid";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox";
import { GrMoney, GrUserWorker } from "react-icons/gr";
import { AiOutlineDollar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const RentalTools = () => {
  const rentalTools = useSelector(selectRentalTools);
  const [rowData, setRowData] = useState(rentalTools);
  const [originalData] = useState(rentalTools);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(rentalTools);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();

  const [selectedToolName, setSelectedToolName] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedRentalShop, setSelectedRentalShop] = useState("");

  const filters = useMemo(
    () => ({
      toolName: selectedToolName,
      site: selectedSite,
      rentalShop: selectedRentalShop,
    }),
    [selectedToolName, selectedSite, selectedRentalShop]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedToolName = selectedToolName;
    let newSelectedSite = selectedSite;
    let newSelectedRentalShop = selectedRentalShop;

    switch (filterType) {
      case "toolName":
        newSelectedToolName = "";
        break;
      case "site":
        newSelectedSite = "";
        break;
      case "rentalShop":
        newSelectedRentalShop = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedToolName || row.toolName === newSelectedToolName) &&
        (!newSelectedSite || row.site === newSelectedSite) &&
        (!newSelectedRentalShop || row.rentalShop === newSelectedRentalShop)
      );
    });

    setSelectedToolName(newSelectedToolName);
    setSelectedSite(newSelectedSite);
    setSelectedRentalShop(newSelectedRentalShop);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedToolName || row.toolName === selectedToolName) &&
        (!selectedSite || row.site === selectedSite) &&
        (!selectedRentalShop || row.rentalShop === selectedRentalShop)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedToolName("");
    setSelectedSite("");
    setSelectedRentalShop("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const calculateTotalBalance = useMemo(() => {
    return filteredData.reduce((sum, tool) => sum + tool.balance, 0);
  }, [filteredData]);

  const calculateTotalPaid = useMemo(() => {
    return filteredData.reduce((sum, tool) => sum + tool.paid, 0);
  }, [filteredData]);

  const calculateAverageRate = useMemo(() => {
    return (
      filteredData.reduce((sum, tool) => sum + tool.rateAmountPerDay, 0) /
      filteredData.length
    ).toFixed(2);
  }, [filteredData]);

  const rentalToolColumns = [
    {
      id: "sno",
      header: "SNo",
      accessor: (item) => item.sno,
      show: true,
      minWidth: 50,
    },
    {
      id: "toolName",
      header: "Tool Name",
      accessor: (item) => item.toolName,
      show: true,
      minWidth: 150,
    },
    {
      id: "hireDate",
      header: "Hire Date",
      accessor: (item) => item.hireDate,
      show: true,
      minWidth: 150,
    },
    {
      id: "returnDate",
      header: "Return Date",
      accessor: (item) => item.returnDate,
      show: true,
      minWidth: 150,
    },
    {
      id: "totalDaysUsed",
      header: "Total Days Used",
      accessor: (item) => item.totalDaysUsed,
      show: true,
      minWidth: 150,
    },
    {
      id: "site",
      header: "Site Name",
      accessor: (item) => item.site,
      show: true,
      minWidth: 150,
    },
    {
      id: "rentalShop",
      header: "Rental Shop",
      accessor: (item) => item.rentalShop,
      show: true,
      minWidth: 150,
    },
    {
      id: "rateAmountPerDay",
      header: "Rate/Amount per day",
      accessor: (item) => item.rateAmountPerDay,
      show: true,
      minWidth: 150,
    },
    {
      id: "paid",
      header: "Paid",
      accessor: (item) => item.paid,
      show: true,
      minWidth: 150,
    },
    {
      id: "balance",
      header: "Balance",
      accessor: (item) => item.balance,
      show: true,
      minWidth: 150,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.sno,
      render: (item) => (
        <IconButton icon={<DeleteIcon />} onClick={() => deleteRow(item.sno)} />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    rentalToolColumns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const deleteRow = (sno) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (confirmed) {
      setRowData(rowData.filter((row) => row.sno !== sno));
      setFilteredData(filteredData.filter((row) => row.sno !== sno));
      toast({
        title: "Row deleted.",
        description: `Row with SNo ${sno} has been deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRowClick = (id) => {
    console.log(id);
    navigate("/tools/rental/edit/" + id);
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader
          title="Rental Tools"
          addButtonName="Add Rental Tool"
          to="/tools/rental/add"
          icon={<GrMoney />}
        />
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={GrMoney}
            name="Total Balance"
            value={calculateTotalBalance}
            backgroundColor="blue.300"
            type="money"
          />
          <DashboardBox
            icon={AiOutlineDollar}
            name="Total Paid"
            value={calculateTotalPaid}
            backgroundColor="yellow.300"
            type="money"
          />
          <DashboardBox
            icon={GrUserWorker}
            name="Average Rate"
            value={calculateAverageRate}
            backgroundColor="red.300"
            type="rate"
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Rental Tools"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenFilterModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.toolName && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Tool Name: ${filters.toolName}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("toolName")} />
            </Tag>
          )}
          {filters.site && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Site: ${filters.site}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("site")} />
            </Tag>
          )}
          {filters.rentalShop && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Rental Shop: ${filters.rentalShop}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("rentalShop")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Tool Name"
                onChange={(e) => setSelectedToolName(e.target.value)}
                mb={4}
                value={selectedToolName}
              >
                <option value="Tool 1">Tool 1</option>
                <option value="Tool 2">Tool 2</option>
                <option value="Tool 3">Tool 3</option>
              </Select>
              <Select
                placeholder="Select Site"
                onChange={(e) => setSelectedSite(e.target.value)}
                mb={4}
                value={selectedSite}
              >
                <option value="Site 1">Site 1</option>
                <option value="Site 2">Site 2</option>
                <option value="Site 3">Site 3</option>
              </Select>
              <Select
                placeholder="Select Rental Shop"
                onChange={(e) => setSelectedRentalShop(e.target.value)}
                value={selectedRentalShop}
              >
                <option value="Shop 1">Shop 1</option>
                <option value="Shop 2">Shop 2</option>
                <option value="Shop 3">Shop 3</option>
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={rentalToolColumns}
          data={filteredData}
          title="Rental Tools List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={setSortConfig}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default RentalTools;
