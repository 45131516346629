import React, { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
} from "@chakra-ui/react";

import {
  FaMoneyCheck,
  FaBook,
  FaMoneyBill,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import GenericHeader from "../../../commons/GenericHeader";
import DashboardBox from "../../../commons/DashboardBox";
import GenericGrid from "../../Procurement/GenericGrid";
import LedgerManagement from "./LedgerManagement";
import AddAccountDrawer from "./AddAccountDrawer";

const AccountsList = () => {
  // Dummy data
  const accountsData = [
    { id: 1, name: "Cash", type: "Asset", balance: 50000 },
    { id: 2, name: "Accounts Receivable", type: "Asset", balance: 150000 },
    { id: 3, name: "Accounts Payable", type: "Liability", balance: 80000 },
    { id: 4, name: "Revenue", type: "Income", balance: 200000 },
  ];

  const columns = [
    { id: "id", header: "ID", accessor: (row) => row.id },
    { id: "name", header: "Name", accessor: (row) => row.name },
    { id: "type", header: "Type", accessor: (row) => row.type },
    {
      id: "balance",
      header: "Balance",
      accessor: (row) => `AED ${row.balance.toLocaleString()}`,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => col.id)
  );

  return (
    <Box>
      <GenericHeader title="Accounts Management" />

      <Tabs>
        <TabList>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Chart of Accounts
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            General Ledger
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Accounts Payable
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Accounts Receivable
          </Tab>
        </TabList>

        <TabPanels>
          {/* Chart of Accounts */}
          <TabPanel>
            <AddAccountDrawer />
            <SimpleGrid columns={{ sm: 1, md: 4 }} spacing={5}>
              <DashboardBox
                icon={FaMoneyCheck}
                name="Total Assets"
                value="200,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaMoneyBill}
                name="Total Liabilities"
                value="80,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaMoneyCheckAlt}
                name="Total Revenue"
                value="300,000"
                backgroundColor="#4299E1"
                type="money"
              />
              <DashboardBox
                icon={FaBook}
                name="Total Equity"
                value="120,000"
                backgroundColor="#F6AD55"
                type="money"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={accountsData}
                title="Chart of Accounts"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>

          {/* General Ledger */}
          <TabPanel>
            <LedgerManagement />
          </TabPanel>

          {/* Accounts Payable */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaMoneyBill}
                name="Total Payables"
                value="80,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaBook}
                name="Pending Payments"
                value="20"
                backgroundColor="#4299E1"
                type="number"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={accountsData}
                title="Accounts Payable"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>

          {/* Accounts Receivable */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaMoneyCheckAlt}
                name="Total Receivables"
                value="150,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaBook}
                name="Pending Invoices"
                value="15"
                backgroundColor="#4299E1"
                type="number"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={accountsData}
                title="Accounts Receivable"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>

          {/* Bank Reconciliation */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaMoneyCheck}
                name="Bank Balance"
                value="120,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaBook}
                name="Unreconciled Transactions"
                value="5"
                backgroundColor="#FC8181"
                type="number"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={accountsData}
                title="Bank Reconciliation"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AccountsList;
