import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericHeader from "../../commons/GenericHeader";
import { FaTasks } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectDutiesPlanning } from "../../store/dutiesPlanningSlice";
import SearchInput from "../../commons/SearchInput";
import { useNavigate } from "react-router-dom";
import GenericGrid from "../Procurement/GenericGrid";
import ImageGallery from "../../commons/ImageGallery"; // Import the ImageGallery component

const ForemanReportingList = () => {
  const dutiesPlanning = useSelector(selectDutiesPlanning);
  const [rowData, setRowData] = useState(dutiesPlanning);
  const [originalData] = useState(dutiesPlanning);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dutiesPlanning);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedForeman, setSelectedForeman] = useState("");
  const [selectedSite, setSelectedSite] = useState("");

  const filters = useMemo(
    () => ({
      date: selectedDate,
      foreman: selectedForeman,
      site: selectedSite,
    }),
    [selectedDate, selectedForeman, selectedSite]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedDate = selectedDate;
    let newSelectedForeman = selectedForeman;
    let newSelectedSite = selectedSite;

    switch (filterType) {
      case "date":
        newSelectedDate = "";
        break;
      case "foreman":
        newSelectedForeman = "";
        break;
      case "site":
        newSelectedSite = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedDate || row.date === newSelectedDate) &&
        (!newSelectedForeman || row.foreman === newSelectedForeman) &&
        (!newSelectedSite || row.site === newSelectedSite)
      );
    });

    setSelectedDate(newSelectedDate);
    setSelectedForeman(newSelectedForeman);
    setSelectedSite(newSelectedSite);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedDate || row.date === selectedDate) &&
        (!selectedForeman || row.foreman === selectedForeman) &&
        (!selectedSite || row.site === selectedSite)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedDate("");
    setSelectedForeman("");
    setSelectedSite("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const foremanColumns = [
    {
      id: "sno",
      header: "S.No",
      accessor: (item) => item.sno,
      show: true,
      minWidth: 50,
    },
    {
      id: "date",
      header: "Date",
      accessor: (item) => item.date,
      show: true,
      minWidth: 150,
    },
    {
      id: "foreman",
      header: "Foreman",
      accessor: (item) => item.foreman,
      show: true,
      minWidth: 150,
    },
    {
      id: "workDescription",
      header: "Work Description",
      accessor: (item) => item.workDescription,
      show: true,
      minWidth: 200,
    },
    {
      id: "workImages",
      header: "Work Images",
      accessor: (item) => item.workImages,
      render: (item) => (
        <Box minWidth={150} onClick={(e) => e.stopPropagation()}>
          <ImageGallery images={item.workImages} width={150} />
        </Box>
      ),
      show: true,
      minWidth: 150,
    },
    {
      id: "site",
      header: "Site",
      accessor: (item) => item.site,
      show: true,
      minWidth: 150,
    },
    {
      id: "stage",
      header: "Stage",
      accessor: (item) => item.stage,
      show: true,
      minWidth: 150,
    },
    {
      id: "reportStatus",
      header: "Report Status",
      accessor: (item) => item.reportStatus,
      show: true,
      minWidth: 150,
    },
    {
      id: "labourHours",
      header: "Labor",
      accessor: (item) => item.labourHours,
      show: true,
      minWidth: 150,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.sno,
      render: (item) => (
        <IconButton
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            deleteRow(item.sno);
          }}
        />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    foremanColumns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const deleteRow = (sno) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (confirmed) {
      setRowData(rowData.filter((row) => row.sno !== sno));
      setFilteredData(filteredData.filter((row) => row.sno !== sno));
      toast({
        title: "Row deleted.",
        description: `Row with S.No ${sno} has been deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRowClick = ({ sno }) => {
    navigate("/foreman-reporting/edit/" + sno);
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader
          title="Foreman Reportings"
          addButtonName="Next Day Plan"
          to="/foreman-reporting/add"
          icon={<FaTasks />}
        />
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Duties"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.date && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Date: ${filters.date}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("date")} />
            </Tag>
          )}
          {filters.foreman && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Foreman: ${filters.foreman}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("foreman")} />
            </Tag>
          )}
          {filters.site && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Site: ${filters.site}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("site")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Date"
                onChange={(e) => setSelectedDate(e.target.value)}
                mb={4}
                value={selectedDate}
              >
                {dutiesPlanning.map((duty) => (
                  <option key={duty.date} value={duty.date}>
                    {duty.date}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Foreman"
                onChange={(e) => setSelectedForeman(e.target.value)}
                mb={4}
                value={selectedForeman}
              >
                {dutiesPlanning.map((duty) => (
                  <option key={duty.foreman} value={duty.foreman}>
                    {duty.foreman}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Site"
                onChange={(e) => setSelectedSite(e.target.value)}
                value={selectedSite}
              >
                {dutiesPlanning.map((duty) => (
                  <option key={duty.site} value={duty.site}>
                    {duty.site}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {foremanColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={foremanColumns}
          data={filteredData}
          title="Foreman Reportings"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={setSortConfig}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default ForemanReportingList;
