import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectEmployees } from "../../store/employeesSlice";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "../Procurement/GenericGrid";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox";
import { TbSubtask } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import ImageGallery from "../../commons/ImageGallery"; // Import the ImageGallery component
import EmployeeHierarchy from "./EmployeeHierarchy"; // Import the EmployeeHierarchy component

const EmployeeList = () => {
  const employees = useSelector(selectEmployees);
  const [rowData, setRowData] = useState(employees);
  const [originalData] = useState(employees);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(employees);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedNationality, setSelectedNationality] = useState("");

  const filters = useMemo(
    () => ({
      designation: selectedDesignation,
      nationality: selectedNationality,
    }),
    [selectedDesignation, selectedNationality]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedDesignation = selectedDesignation;
    let newSelectedNationality = selectedNationality;

    switch (filterType) {
      case "designation":
        newSelectedDesignation = "";
        break;
      case "nationality":
        newSelectedNationality = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedDesignation ||
          row.designation === newSelectedDesignation) &&
        (!newSelectedNationality || row.nationality === newSelectedNationality)
      );
    });

    setSelectedDesignation(newSelectedDesignation);
    setSelectedNationality(newSelectedNationality);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedDesignation || row.designation === selectedDesignation) &&
        (!selectedNationality || row.nationality === selectedNationality)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedDesignation("");
    setSelectedNationality("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const columns = [
    {
      id: "sno",
      header: "Sno",
      accessor: (item) => item.sno,
      show: true,
      minWidth: 50,
    },
    {
      id: "photo",
      header: "Photo",
      accessor: (item) => item.photo,
      render: (item) => (
        <Box minWidth={100} onClick={(e) => e.stopPropagation()}>
          <ImageGallery images={[item.photo]} width={100} />
        </Box>
      ),
      show: true,
      minWidth: 100,
    },
    {
      id: "empName",
      header: "Emp Name",
      accessor: (item) => item.empName,
      show: true,
      minWidth: 150,
    },
    {
      id: "designation",
      header: "Designation",
      accessor: (item) => item.designation,
      show: true,
      minWidth: 150,
    },
    {
      id: "visa",
      header: "Visa",
      accessor: (item) => item.visa,
      show: true,
      minWidth: 150,
    },
    {
      id: "passportNo",
      header: "Passport No",
      accessor: (item) => item.passportNo,
      show: true,
      minWidth: 150,
    },
    {
      id: "nationality",
      header: "Nationality",
      accessor: (item) => item.nationality,
      show: true,
      minWidth: 150,
    },
    {
      id: "passportExpiry",
      header: "Passport Expiry",
      accessor: (item) => item.passportExpiry,
      show: true,
      minWidth: 150,
    },
    {
      id: "visaExpiry",
      header: "Visa Expiry",
      accessor: (item) => item.visaExpiry,
      show: true,
      minWidth: 150,
    },
    {
      id: "selfContactNo",
      header: "Self Contact No",
      accessor: (item) => item.selfContactNo,
      show: true,
      minWidth: 150,
    },
    {
      id: "familyContactNo",
      header: "Family Contact No",
      accessor: (item) => item.familyContactNo,
      show: true,
      minWidth: 150,
    },
    {
      id: "performance",
      header: "Performance",
      accessor: (item) => item.performance,
      show: true,
      minWidth: 150,
    },

    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.id,
      render: (item) => (
        <IconButton
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleRowClick = (id) => {
    navigate(`/employees/details/${id}`);
  };

  const totalEmployees = employees.length;
  const engineers = employees.filter(
    (employee) => employee.designation === "Engineer"
  ).length;
  const nationalsUSA = employees.filter(
    (employee) => employee.nationality === "Pakistan"
  ).length;
  const goodPerformance = employees.filter(
    (employee) => employee.performance === "Good"
  ).length;

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader
          title="Employees"
          addButtonName="Employee"
          to="/hr/employees/add"
          icon={<TbSubtask />}
        />
      </Flex>

      <Tabs>
        <TabList mb="1em">
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Employee List
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Org Chart
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {/* Employee List Tab */}
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Flex
                alignItems="center"
                width="100%"
                justifyContent="space-around"
                gap={3}
              >
                <DashboardBox
                  icon={TbSubtask}
                  name="Total Employees"
                  value={totalEmployees}
                  backgroundColor="blue.300"
                />
                <DashboardBox
                  icon={TbSubtask}
                  name="Engineers"
                  value={engineers}
                  backgroundColor="green.300"
                />
                <DashboardBox
                  icon={TbSubtask}
                  name="Pakistan Nationals"
                  value={nationalsUSA}
                  backgroundColor="yellow.300"
                />
                <DashboardBox
                  icon={TbSubtask}
                  name="Good Performance"
                  value={goodPerformance}
                  backgroundColor="red.300"
                />
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" mb={4} alignItems="center">
              <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
                Filter
              </Button>
              <SearchInput
                placeholder="Search Employees"
                value={searchText}
                onChange={handleSearchInputChange}
                onClear={handleClearSearch}
              />
              <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
            </Flex>
            <Flex mb={4}>
              <Flex>
                {filters.designation && (
                  <Tag
                    size="md"
                    borderRadius="10px"
                    variant="solid"
                    colorScheme="gray"
                    mr={2}
                  >
                    <TagLabel fontWeight="bold">{`Designation: ${filters.designation}`}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleTagClose("designation")}
                    />
                  </Tag>
                )}
                {filters.nationality && (
                  <Tag
                    size="md"
                    borderRadius="10px"
                    variant="solid"
                    colorScheme="gray"
                    mr={2}
                  >
                    <TagLabel fontWeight="bold">{`Nationality: ${filters.nationality}`}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleTagClose("nationality")}
                    />
                  </Tag>
                )}
              </Flex>
            </Flex>
            <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Apply Filters</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box mb={4}>
                    <Select
                      placeholder="Select Designation"
                      onChange={(e) => setSelectedDesignation(e.target.value)}
                      mb={4}
                      value={selectedDesignation}
                    >
                      {employees.map((employee) => (
                        <option
                          key={employee.designation}
                          value={employee.designation}
                        >
                          {employee.designation}
                        </option>
                      ))}
                    </Select>
                    <Select
                      placeholder="Select Nationality"
                      onChange={(e) => setSelectedNationality(e.target.value)}
                      mb={4}
                      value={selectedNationality}
                    >
                      {employees.map((employee) => (
                        <option
                          key={employee.nationality}
                          value={employee.nationality}
                        >
                          {employee.nationality}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="teal" onClick={applyFilter} mr={3}>
                    Apply Filter
                  </Button>
                  <Button variant="ghost" onClick={clearFilter}>
                    Clear Filter
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Toggle Columns</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box>
                    {columns.map((col) => (
                      <FormControl
                        display="flex"
                        alignItems="center"
                        key={col.id}
                        mb={2}
                      >
                        <FormLabel htmlFor={col.id} mb="0" flex="1">
                          {col.header}
                        </FormLabel>
                        <Switch
                          id={col.id}
                          isChecked={visibleColumns.includes(col.id)}
                          onChange={() => handleVisibleColumnsChange(col.id)}
                        />
                      </FormControl>
                    ))}
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button variant="ghost" onClick={onCloseColumnModal}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Box>
              <GenericGrid
                columns={columns}
                data={filteredData}
                title="Employee List"
                visibleColumnsState={visibleColumns}
                filterState={filters}
                onVisibleColumnsChange={handleVisibleColumnsChange}
                onSort={setSortConfig}
                sortConfig={sortConfig}
                onRowClick={handleRowClick}
              />
            </Box>
          </TabPanel>

          <TabPanel>
            {/* Org Chart Tab */}
            <EmployeeHierarchy />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default EmployeeList;
