import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bills: [
    {
      sno: 1,
      invoiceNo: "INV001",
      supplierName: "Supplier A",
      date: "2023-06-25",
      projectSite: "Site A",
      projectStage: "Stage 1",
      buyer: "Buyer A",
      status: "Paid",
      amount: "1000",
      images: [
        "https://via.placeholder.com/150",
        "https://via.placeholder.com/250",
      ],
      stageName: "Stage 1",
      items: [
        { name: "Item A", unitRate: 50, quantity: 10 },
        { name: "Item B", unitRate: 100, quantity: 5 },
      ],
    },
    {
      sno: 2,
      invoiceNo: "INV002",
      supplierName: "Supplier B",
      date: "2023-06-26",
      projectSite: "Site B",
      projectStage: "Stage 3",
      buyer: "Buyer B",
      status: "Pending",
      amount: "2000",
      images: [
        "https://via.placeholder.com/150",
        "https://via.placeholder.com/250",
      ],
      stageName: "Stage 2",
      items: [
        { name: "Item A", unitRate: 45, quantity: 20 },
        { name: "Item C", unitRate: 200, quantity: 3 },
      ],
    },
    {
      sno: 3,
      invoiceNo: "INV003",
      supplierName: "Supplier C",
      date: "2023-06-27",
      projectSite: "Site C",
      projectStage: "Stage 2",
      buyer: "Buyer C",
      status: "Overdue",
      amount: "3000",
      images: [
        "https://via.placeholder.com/550",
        "https://via.placeholder.com/550",
        "https://via.placeholder.com/750",
        "https://via.placeholder.com/750",
        "https://via.placeholder.com/750",
        "https://via.placeholder.com/750",
        "https://fakeimg.pl/1920x1080",
      ],
      stageName: "Stage 3",
      items: [
        { name: "Item B", unitRate: 110, quantity: 7 },
        { name: "Item D", unitRate: 300, quantity: 2 },
      ],
    },
  ],
};

const billsSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {
    addBill(state, action) {
      state.bills.push(action.payload);
    },
  },
});

export const { addBill } = billsSlice.actions;

// Selector to get all bills with item details
export const selectBills = (state) => state.bills.bills;

// Selector to get all items with their associated invoices
export const selectItemsWithInvoices = (state) =>
  state.bills.bills.flatMap((bill) =>
    bill.items.map((item) => ({
      ...item,
      invoiceNo: bill.invoiceNo,
      images: bill.images,
    }))
  );

export default billsSlice.reducer;
