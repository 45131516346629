// src/components/DetailsDrawer.js
import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Image,
  Divider,
} from "@chakra-ui/react";
import { IoCloseOutline } from "react-icons/io5";

const DetailsDrawer = ({ isOpen, onClose, type }) => {
  const dummyData = {
    manhour: {
      costList: [
        { cost: "AED 100", date: "2024-08-15", images: ["dummy1.jpg"] },
        { cost: "AED 150", date: "2024-08-16", images: ["dummy2.jpg"] },
      ],
      totalCost: "AED 250",
    },
    bills: {
      invoiceList: [
        { invoice: "INV001", totalCost: "AED 300" },
        { invoice: "INV002", totalCost: "AED 200" },
      ],
      totalCost: "AED 500",
    },
  };

  const data = dummyData[type] || {
    costList: [],
    invoiceList: [],
    totalCost: "AED 0",
  };

  return (
    <Drawer isOpen={isOpen} placement="right" size={"xl"} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Text fontSize="lg" fontWeight="bold">
            {type === "manhour" ? "Manhour Costs" : "Stage Bills"}
          </Text>
          <Button
            position="absolute"
            right="0"
            top="0"
            variant="outline"
            onClick={onClose}
            aria-label="Close"
            icon={<IoCloseOutline />}
          />
        </DrawerHeader>
        <DrawerBody>
          <Box mb={4}>
            {type === "manhour" ? (
              <>
                <Text fontSize="md" fontWeight="bold">
                  Manhour Costs
                </Text>
                <Table variant="simple" mt={4}>
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Cost</Th>
                      <Th>Images</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.costList.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.date}</Td>
                        <Td>{item.cost}</Td>
                        <Td>
                          {item.images.map((img, imgIndex) => (
                            <Image
                              key={imgIndex}
                              src={img}
                              alt={`Manhour Image ${imgIndex + 1}`}
                              boxSize="50px"
                              objectFit="cover"
                              mr={2}
                            />
                          ))}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Divider my={4} />
                <Text fontSize="lg" fontWeight="bold">
                  Total Cost: {data.totalCost}
                </Text>
              </>
            ) : type === "bills" ? (
              <>
                <Text fontSize="md" fontWeight="bold">
                  Invoice List
                </Text>
                <Table variant="simple" mt={4}>
                  <Thead>
                    <Tr>
                      <Th>Invoice</Th>
                      <Th>Total Cost</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.invoiceList.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.invoice}</Td>
                        <Td>{item.totalCost}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Divider my={4} />
                <Text fontSize="lg" fontWeight="bold">
                  Total Cost: {data.totalCost}
                </Text>
              </>
            ) : (
              <Text>No data available for this type.</Text>
            )}
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DetailsDrawer;
