import React from "react";
import { Box, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import WheelShape from "../../commons/WheelShape";
import {
  FaTools,
  FaClipboardList,
  FaFileInvoice,
  FaChartLine,
  FaChartBar,
  FaBook,
  FaBriefcase,
  FaSearch,
  FaFileAlt,
  FaBullseye,
  FaLightbulb,
  FaCog,
} from "react-icons/fa";

const HRModules = () => {
  const icons1 = [
    <FaTools />,
    <FaClipboardList />,
    <FaFileInvoice />,
    <FaChartLine />,
    <FaChartBar />,
    <FaBook />,
    <FaBriefcase />,
    <FaSearch />,
    <FaFileAlt />,
    <FaBullseye />,
    <FaLightbulb />,
    <FaCog />,
  ];

  const items1 = [
    {
      label: "Recruitment and Staffing",
      icon: "🛠",
      route: "/recruitment",
      color: "#e57373",
    },
    {
      label: "Onboarding and Training",
      icon: "📋",
      route: "/onboarding",
      color: "#f06292",
    },
    {
      label: "Compliance and Legal Issues",
      icon: "🧾",
      route: "/compliance",
      color: "#ba68c8",
    },
    {
      label: "Payroll and Compensation",
      icon: "📈",
      route: "/payroll",
      color: "#9575cd",
    },
    {
      label: "Performance Management",
      icon: "📊",
      route: "/performance",
      color: "#7986cb",
    },
    {
      label: "Employees ",
      icon: "📚",
      route: "/employees",
      color: "#64b5f6",
    },
    {
      label: "Health Safety Welfare",
      icon: "💼",
      route: "/health-safety",
      color: "#4fc3f7",
    },
    {
      label: "Employee Relations and Engagement",
      icon: "🔍",
      route: "/relations",
      color: "#4db6ac",
    },
    {
      label: "HR Administration",
      icon: "📄",
      route: "/administration",
      color: "#81c784",
    },
    {
      label: "Strategic HR Planning",
      icon: "🎯",
      route: "/planning",
      color: "#aed581",
    },
    {
      label: "Administrative responsibilities",
      icon: "💡",
      route: "/responsibilities",
      color: "#dce775",
    },
    { label: "Well-being", icon: "⚙️", route: "/well-being", color: "#fff176" },
  ];

  const items = [
    {
      label: "Recruitment and Staffing",
      icon: <FaTools />,
      route: "/recruitment",
      color: "#e57373",
    },
    {
      label: "Onboarding and Training",
      icon: <FaClipboardList />,
      route: "/onboarding",
      color: "#f06292",
    },
    {
      label: "Compliance and Legal Issues",
      icon: <FaFileInvoice />,
      route: "/compliance",
      color: "#ba68c8",
    },
    {
      label: "Payroll and Compensation",
      icon: <FaChartLine />,
      route: "/payroll",
      color: "#9575cd",
    },
    {
      label: "Performance Management",
      icon: <FaChartBar />,
      route: "/performance",
      color: "#7986cb",
    },
    {
      label: "Employees Issues",
      icon: <FaBook />,
      route: "/employees",
      color: "#64b5f6",
    },
    {
      label: "Health Safety Welfare",
      icon: <FaBriefcase />,
      route: "/health-safety",
      color: "#4fc3f7",
    },
    {
      label: "Employee Relations and Engagement",
      icon: <FaSearch />,
      route: "/relations",
      color: "#4db6ac",
    },
    {
      label: "HR Administration",
      icon: <FaFileAlt />,
      route: "/administration",
      color: "#81c784",
    },
    {
      label: "Strategic HR Planning",
      icon: <FaBullseye />,
      route: "/planning",
      color: "#aed581",
    },
    {
      label: "Administrative responsibilities",
      icon: <FaLightbulb />,
      route: "/responsibilities",
      color: "#dce775",
    },
    {
      label: "Well-being",
      icon: <FaCog />,
      route: "/well-being",
      color: "#fff176",
    },
  ];

  return <WheelShape items={items} width={800} height={800} />;
};

export default HRModules;
