import React from "react";
import { Box, FormControl, FormLabel, useTheme } from "@chakra-ui/react";
import Select from "react-select";

// Custom styles for React Select to match Chakra UI theme
const customStyles = (theme) => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: theme.colors.white,
    borderColor: state.isFocused
      ? theme.colors.primary[500]
      : theme.colors.gray[300],
    boxShadow: state.isFocused
      ? `0 0 0 1px ${theme.colors.primary[500]}`
      : "none",
    "&:hover": {
      borderColor: theme.colors.primary[500],
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.gray[200],
    zIndex: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? theme.colors.primary[100]
      : theme.colors.white,
    color: state.isSelected ? theme.colors.primary[800] : theme.colors.black,
    "&:hover": {
      backgroundColor: theme.colors.primary[50],
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: theme.colors.primary[100],
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: theme.colors.primary[800],
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: theme.colors.primary[800],
    "&:hover": {
      backgroundColor: theme.colors.primary[200],
      color: theme.colors.primary[900],
    },
  }),
});

const SelectBox = ({
  label,
  options,
  isMulti = false,
  placeholder = "Select...",
  onChange,
  value,
  isSearchable = true,
  isClearable = true,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box>
      <FormControl>
        {label && <FormLabel>{label}</FormLabel>}
        <Select
          styles={customStyles(theme)}
          options={options}
          isMulti={isMulti}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          isSearchable={isSearchable}
          isClearable={isClearable}
          {...props}
        />
      </FormControl>
    </Box>
  );
};

export default SelectBox;
