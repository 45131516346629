import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaTools,
  FaClipboardList,
  FaFileInvoice,
  FaChartLine,
  FaChartBar,
  FaBook,
  FaBriefcase,
  FaSearch,
  FaFileAlt,
  FaBullseye,
  FaLightbulb,
  FaCog,
} from "react-icons/fa";
import ReactDOMServer from "react-dom/server";

const icons1 = [
  <FaTools />,
  <FaClipboardList />,
  <FaFileInvoice />,
  <FaChartLine />,
  <FaChartBar />,
  <FaBook />,
  <FaBriefcase />,
  <FaSearch />,
  <FaFileAlt />,
  <FaBullseye />,
  <FaLightbulb />,
  <FaCog />,
];

const items = [
  {
    label: "Recruitment and Staffing",
    icon: icons1[0],
    route: "/recruitment",
    color: "#e57373",
  },
  {
    label: "Onboarding and Training",
    icon: icons1[1],
    route: "/onboarding",
    color: "#f06292",
  },
  {
    label: "Compliance and Legal Issues",
    icon: icons1[2],
    route: "/compliance",
    color: "#ba68c8",
  },
  {
    label: "Payroll and Compensation",
    icon: icons1[3],
    route: "/payroll",
    color: "#9575cd",
  },
  {
    label: "Performance Management",
    icon: icons1[4],
    route: "/performance",
    color: "#7986cb",
  },
  {
    label: "Employees List",
    icon: icons1[5],
    route: "/employees",
    color: "#64b5f6",
  },
  {
    label: "Health Safety Welfare",
    icon: icons1[6],
    route: "/health-safety",
    color: "#4fc3f7",
  },
  {
    label: "Employee Relations and Engagement",
    icon: icons1[7],
    route: "/relations",
    color: "#4db6ac",
  },
  {
    label: "HR Administration",
    icon: icons1[8],
    route: "/administration",
    color: "#81c784",
  },
  {
    label: "Strategic HR Planning",
    icon: icons1[9],
    route: "/planning",
    color: "#aed581",
  },
  {
    label: "Administrative responsibilities",
    icon: icons1[10],
    route: "/responsibilities",
    color: "#dce775",
  },
  {
    label: "Well-being",
    icon: icons1[11],
    route: "/well-being",
    color: "#fff176",
  },
];

const WheelShape = ({ items, width, height }) => {
  const canvasRef = useRef(null);
  const [hoveredSlice, setHoveredSlice] = useState(-1);
  const totalSlices = items.length;
  const gap = 5;
  const sliceAngle = (2 * Math.PI) / totalSlices;
  const navigate = useNavigate();
  const [iconImages, setIconImages] = useState([]);

  useEffect(() => {
    preRenderIcons();
  }, []);

  useEffect(() => {
    drawPieChart();
  }, [hoveredSlice, iconImages]);

  const preRenderIcons = () => {
    const iconSize = 24;
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = iconSize;
    offscreenCanvas.height = iconSize;
    const offscreenCtx = offscreenCanvas.getContext("2d");
    offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
    const images = items.map((item) => {
      const svgString = ReactDOMServer.renderToString(
        React.cloneElement(item.icon, { color: item.color, size: iconSize })
      );
      const img = new Image();
      const svg = new Blob([svgString], {
        type: "image/svg+xml;charset=utf-8",
      });
      const url = URL.createObjectURL(svg);

      img.src = url;

      return new Promise((resolve) => {
        img.onload = () => {
          offscreenCtx.clearRect(
            0,
            0,
            offscreenCanvas.width,
            offscreenCanvas.height
          ); // Ensure the offscreen canvas is transparent
          offscreenCtx.drawImage(img, 0, 0, iconSize, iconSize);
          const imageData = offscreenCtx.getImageData(0, 0, iconSize, iconSize);
          resolve(imageData);
          URL.revokeObjectURL(url);
        };
      });
    });

    Promise.all(images).then((loadedImages) => {
      setIconImages(loadedImages);
    });
  };

  function drawUnderlinedText(ctx, text, x, y, color, underlineColor) {
    ctx.fillStyle = color;
    ctx.font = "bold 20px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.fillText(text, x, y);

    const textWidth = ctx.measureText(text).width;

    ctx.strokeStyle = underlineColor;
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(x - textWidth / 2, y + 10);
    ctx.lineTo(x + textWidth / 2, y + 10);
    ctx.stroke();
  }

  const drawPieChart = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const outerRadius = width * 0.4;
    const iconRadius = width * 0.2;
    const textRadius = width * 0.3;
    const innerRadius = width * 0.15;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.beginPath();
    ctx.arc(centerX, centerY, iconRadius + 15, 0, 2 * Math.PI);
    ctx.fillStyle = "#ddd";
    ctx.fill();

    for (let i = 0; i < totalSlices; i++) {
      const startAngle = i * sliceAngle;
      const endAngle = startAngle + sliceAngle - gap / outerRadius;
      const midAngle = (startAngle + endAngle) / 2;
      const isHovered = i === hoveredSlice;

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle);
      ctx.closePath();
      ctx.fillStyle = isHovered
        ? shadeColor(items[i].color, 20)
        : items[i].color;
      ctx.fill();

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(
        centerX,
        centerY,
        outerRadius,
        endAngle,
        endAngle + gap / outerRadius
      );
      ctx.lineTo(centerX, centerY);
      ctx.closePath();
      ctx.fillStyle = "#fff";
      ctx.fill();

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, iconRadius, startAngle, endAngle);
      ctx.closePath();
      ctx.fillStyle = shadeColor(items[i].color, -20); // Revert back to the original color
      ctx.fill();

      const iconX = centerX + Math.cos(midAngle) * (iconRadius - 20);
      const iconY = centerY + Math.sin(midAngle) * (iconRadius - 20);

      if (iconImages[i]) {
        ctx.putImageData(iconImages[i], iconX - 12, iconY - 12);
      }

      const textX = centerX + Math.cos(midAngle) * textRadius;
      const textY = centerY + Math.sin(midAngle) * textRadius;
      drawText(ctx, items[i].label, textX, textY, textRadius);
    }

    // Draw small circles at separators
    for (let i = 0; i < totalSlices; i++) {
      const startAngle = i * sliceAngle;
      const endAngle = startAngle + sliceAngle - gap / outerRadius;
      const separatorAngle = endAngle + gap / (2 * outerRadius);
      const smallCircleRadius = gap * 3.5;
      const circleX = centerX + Math.cos(separatorAngle) * outerRadius * 0.8;
      const circleY = centerY + Math.sin(separatorAngle) * outerRadius * 0.8;
      ctx.beginPath();
      ctx.arc(circleX, circleY, smallCircleRadius, 0, 2 * Math.PI);
      ctx.fillStyle = "#fff";
      ctx.fill();
    }

    ctx.beginPath();
    ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI);
    ctx.fillStyle = "#fff";
    ctx.fill();

    const gradient1 = ctx.createLinearGradient(
      centerX - 50,
      centerY - 10,
      centerX + 50,
      centerY - 10
    );
    gradient1.addColorStop(0, "#db8700");
    gradient1.addColorStop(1, "#FFC300");

    ctx.fillStyle = "#333";
    ctx.font = "bold 20px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = gradient1;
    ctx.fillText("GreenFort", centerX, centerY - 10);
    ctx.fillText("Constructions", centerX, centerY + 10);
    ctx.fillStyle = gradient1;
    drawUnderlinedText(ctx, "HR Modules", centerX, centerY + 40, gradient1);
  };

  const handleMouseMove = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const angle = Math.atan2(y - height / 2, x - width / 2);
    const normalizedAngle = (angle + 2 * Math.PI) % (2 * Math.PI);
    const distanceFromCenter = Math.sqrt(
      (x - width / 2) ** 2 + (y - height / 2) ** 2
    );
    const hovered =
      distanceFromCenter <= width * 0.4 && distanceFromCenter >= width * 0.15
        ? Math.floor(normalizedAngle / sliceAngle)
        : -1;
    setHoveredSlice(hovered);
  };

  const handleClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const angle = Math.atan2(y - height / 2, x - width / 2);
    const normalizedAngle = (angle + 2 * Math.PI) % (2 * Math.PI);
    const clickedSlice = Math.floor(normalizedAngle / sliceAngle);
    console.log(`Clicked on slice: ${clickedSlice + 1}`);
    if (items[clickedSlice] && items[clickedSlice].route) {
      navigate("/hr" + items[clickedSlice].route);
    }
  };

  return (
    <div className="container" style={{ position: "relative" }}>
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        style={{ display: "block", margin: "0 auto" }}
      />
    </div>
  );
};

function drawText(ctx, text, x, y, radius, angle) {
  ctx.save();
  ctx.translate(x, y);
  ctx.rotate(0);
  ctx.fillStyle = "#fff";
  ctx.font = "bold 14px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";

  const letters = text.split(" ");
  const lineHeight = 14;

  for (let i = 0; i < letters.length; i++) {
    ctx.fillText(
      letters[i],
      0,
      i * lineHeight - ((letters.length - 1) * lineHeight) / 2
    );
  }

  ctx.restore();
}

function shadeColor(color, percent) {
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}

export default WheelShape;
