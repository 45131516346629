import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
  NumberInput,
  NumberInputField,
  Textarea,
  Select,
  VStack,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Flex,
  Text,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import QuotationReceipt from "./QuotationReceipt";
import GenericHeader from "../commons/GenericHeader";
import PrimaryButton from "../commons/PrimaryButton";
import { GrPrint } from "react-icons/gr";

const AddQuotationForm = () => {
  const [quotationItems, setQuotationItems] = useState([]);
  const [formData, setFormData] = useState({
    clientName: "",
    projectName: "",
    date: "",
    status: "Draft",
  });

  const [itemData, setItemData] = useState({
    description: "",
    quantity: 0,
    unitPrice: 0,
    vat: 0,
    total: 0,
  });

  const [totalAmount, setTotalAmount] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    const total = quotationItems.reduce((sum, item) => sum + item.total, 0);
    setTotalAmount(total);
  }, [quotationItems]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleItemChange = (field, value) => {
    const updatedItem = { ...itemData, [field]: value };

    // Calculate total and VAT when quantity or unit price changes
    if (field === "quantity" || field === "unitPrice") {
      const total = updatedItem.quantity * updatedItem.unitPrice;
      const vat = total * 0.05;
      updatedItem.vat = vat;
      updatedItem.total = total + vat;
    }

    setItemData(updatedItem);
  };

  const addItem = () => {
    setQuotationItems([...quotationItems, itemData]);
    // Reset item fields after adding the item to the table
    setItemData({
      description: "",
      quantity: 0,
      unitPrice: 0,
      vat: 0,
      total: 0,
    });
  };

  const removeItem = (index) => {
    setQuotationItems(quotationItems.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Quotation Submitted:", {
      formData,
      quotationItems,
      totalAmount,
    });
    navigate("/quotation-management");
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader title="Add Quotation" />
        <Button
          onClick={onOpen}
          size="lg"
          leftIcon={<GrPrint />}
          colorScheme="teal"
        >
          Print Quotation
        </Button>
      </Flex>

      <Box
        as="form"
        onSubmit={handleSubmit}
        p={4}
        borderRadius="20"
        background="white"
        border="3px solid gray"
      >
        {/* Quotation Details */}
        <Text fontSize="xl" fontWeight="bold" color="gray.400" mb={2}>
          Quotation Details
        </Text>
        <Divider mb={4} />
        <VStack spacing={4}>
          <HStack spacing={4} width="100%">
            <FormControl isRequired>
              <FormLabel>Customer Name</FormLabel>
              <Input
                name="clientName"
                value={formData.clientName}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Project Name</FormLabel>
              <Input
                name="projectName"
                value={formData.projectName}
                onChange={handleInputChange}
              />
            </FormControl>
          </HStack>
          <HStack spacing={4} width="100%">
            <FormControl isRequired>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Status</FormLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="Draft">Draft</option>
                <option value="Sent">Sent</option>
                <option value="Approved">Approved</option>
                <option value="Rejected">Rejected</option>
              </Select>
            </FormControl>
          </HStack>
        </VStack>

        {/* Quoted Items Input */}
        <Text fontSize="xl" fontWeight="bold" color="gray.400" mt={8} mb={2}>
          Items
        </Text>
        <Divider mb={4} />
        <VStack spacing={4} mb={6}>
          <FormControl isRequired>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder="Enter item description"
              value={itemData.description}
              onChange={(e) => handleItemChange("description", e.target.value)}
            />
          </FormControl>
          <HStack spacing={4} width="100%">
            <FormControl isRequired>
              <FormLabel>Quantity</FormLabel>
              <NumberInput
                value={itemData.quantity}
                onChange={(value) =>
                  handleItemChange("quantity", parseFloat(value))
                }
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Unit Price</FormLabel>
              <NumberInput
                value={itemData.unitPrice}
                onChange={(value) =>
                  handleItemChange("unitPrice", parseFloat(value))
                }
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>VAT (5%)</FormLabel>
              <Input value={itemData.vat.toFixed(2)} readOnly />
            </FormControl>
            <FormControl>
              <FormLabel>Total</FormLabel>
              <Input value={itemData.total.toFixed(2)} readOnly />
            </FormControl>
          </HStack>
        </VStack>

        <Button
          onClick={addItem}
          colorScheme="gray"
          mt={4}
          leftIcon={<AddIcon />}
        >
          Add Item
        </Button>

        {/* Items Table */}
        <Table variant="simple" mt={8}>
          <Thead>
            <Tr>
              <Th>Description</Th>
              <Th>Quantity</Th>
              <Th>Unit Price</Th>
              <Th>VAT (5%)</Th>
              <Th>Total</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {quotationItems.map((item, index) => (
              <Tr key={index}>
                <Td>{item.description}</Td>
                <Td>{item.quantity}</Td>
                <Td>{item.unitPrice}</Td>
                <Td>{item.vat.toFixed(2)}</Td>
                <Td>{item.total.toFixed(2)}</Td>
                <Td>
                  <IconButton
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    onClick={() => removeItem(index)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {/* Total Amount */}
        <Text fontSize="2xl" fontWeight="bold" mt={8} textAlign="right">
          Total: {totalAmount.toFixed(2)} AED
        </Text>

        <PrimaryButton
          title={"Save Quotation"}
          type="submit"
          colorScheme="primary"
          size="lg"
          mt={4}
        >
          Submit Quotation
        </PrimaryButton>
      </Box>

      {/* Drawer for Preview and Print Quotation */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Preview and Print Quotation</DrawerHeader>
          <DrawerBody>
            <QuotationReceipt
              formData={formData}
              items={quotationItems}
              totalAmount={totalAmount}
            />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default AddQuotationForm;
