import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";

const UserRowBox = ({ photo, name, designation }) => {
  return (
    <Flex align="center">
      <Image src={photo} alt={name} boxSize="50px" borderRadius="full" mr={4} />
      <Box>
        <Text fontWeight="bold">{name}</Text>
        <Text fontSize="sm" color="gray.500">
          {designation}
        </Text>
      </Box>
    </Flex>
  );
};

export default UserRowBox;
