import React, { useState } from "react";
import { Button, useToast, Box, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CiUser, CiLock } from "react-icons/ci";
import InputField from "../../commons/InputField";
import AuthLayout from "./AuthLayout";

const SignupPage = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmit = (data) => {
    setLoading(true);
    // Simulate sending a verification email
    setTimeout(() => {
      console.log(data);
      toast({
        title: "Signup successful! Check your email for confirmation.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEmailSent(true);
      setLoading(false);
    }, 2000);
  };

  const verifyEmail = () => {
    // Simulate email verification process
    setLoading(true);
    setTimeout(() => {
      toast({
        title: "Email verified successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      navigate("/login");
    }, 2000);
  };

  return (
    <AuthLayout title="Sign Up">
      {!emailSent ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={4}>
            <InputField
              icon={<CiUser size={25} />}
              placeholder="First Name"
              {...register("firstName", { required: "First Name is required" })}
            />
            {errors.firstName && (
              <Text color="red.500">{errors.firstName.message}</Text>
            )}
          </Box>

          <Box mb={4}>
            <InputField
              icon={<CiUser size={25} />}
              placeholder="Last Name"
              {...register("lastName", { required: "Last Name is required" })}
            />
            {errors.lastName && (
              <Text color="red.500">{errors.lastName.message}</Text>
            )}
          </Box>

          <Box mb={4}>
            <InputField
              icon={<CiUser size={25} />}
              placeholder="Email"
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && (
              <Text color="red.500">{errors.email.message}</Text>
            )}
          </Box>

          <Box mb={4}>
            <InputField
              icon={<CiUser size={25} />}
              placeholder="Phone"
              type="tel"
              {...register("phone", { required: "Phone number is required" })}
            />
            {errors.phone && (
              <Text color="red.500">{errors.phone.message}</Text>
            )}
          </Box>

          <Box mb={4}>
            <InputField
              icon={<CiLock size={25} />}
              placeholder="Password"
              type="password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
            />
            {errors.password && (
              <Text color="red.500">{errors.password.message}</Text>
            )}
          </Box>

          <Box mb={4}>
            <InputField
              icon={<CiLock size={25} />}
              placeholder="Retype Password"
              type="password"
              {...register("confirmPassword", {
                validate: (value) =>
                  value === watch("password") || "Passwords do not match",
              })}
            />
            {errors.confirmPassword && (
              <Text color="red.500">{errors.confirmPassword.message}</Text>
            )}
          </Box>

          <Button
            type="submit"
            bg="primary.500"
            color="white"
            _hover={{ color: "white", bg: "primary.500" }}
            width="100%"
            isLoading={loading}
            loadingText="Signing Up"
            mt={4}
          >
            Sign Up
          </Button>

          <Button onClick={() => navigate("/login")} variant="link" mt={2}>
            Already have an account? Log In
          </Button>
        </form>
      ) : (
        <Box textAlign="center">
          <Text mb={4}>
            A verification email has been sent to your email. Please check your
            inbox to verify your account.
          </Text>
          <Button
            onClick={verifyEmail}
            bg="primary.500"
            color="white"
            _hover={{ color: "white", bg: "primary.500" }}
            isLoading={loading}
            loadingText="Verifying Email"
          >
            Verify Email
          </Button>
        </Box>
      )}
    </AuthLayout>
  );
};

export default SignupPage;
