import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  FormLabel,
  Grid,
  GridItem,
  Flex,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useDisclosure,
  IconButton,
  Collapse,
  Text,
  Switch,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { IoOpenOutline } from "react-icons/io5";
import {
  updateStageDetails,
  updateCustomField,
  addActivity,
} from "../../store/projectsSlice";
import { startLoading, stopLoading } from "../../store/loadingSlice";
import { TiDeleteOutline } from "react-icons/ti";
import { ReactComponent as ActivityIcon } from "../../images/activity-icon.svg";
import { FaFolder } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa6";
import "../../styles/StageDetails.css";
import DetailsDrawer from "../../commons/DetailsDrawer";

const StageDetails = ({ stage, projectStartDate, projectName, stageIndex }) => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projectList.projects);
  const project = projects.find((p) => p.name === projectName);
  // Drawer control states
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const [formData, setFormData] = useState({
    budget: stage.budget || 0,
    startDate: "",
    endDate: "",
    days: stage.days || 0,
    stageBills: stage.stageBills || 0,
    manhour: stage.manhour || 0,
    grossCost: 0,
    hypotheticalCost: stage.hypotheticalCost || 0,
    hypotheticalProfit: 0,
    costControl: 0,
    grossRevenue: stage.grossRevenue || 0,
    grossProfit: 0,
    hypotheticalProfitInput: stage.hypotheticalProfitInput || 0,
    activities: [],
  });
  const [isCollapsed, setIsCollapsed] = useState(false);
  useEffect(() => {
    const calculateDates = () => {
      if (!projectStartDate || isNaN(new Date(projectStartDate).getTime())) {
        console.error("Invalid project start date");
        return;
      }

      const start = new Date(projectStartDate);
      if (isNaN(start.getTime())) {
        console.error("Invalid calculated start date");
        return;
      }

      start.setDate(start.getDate() + (stage.startOffset || 0));
      const end = new Date(start);
      if (isNaN(end.getTime())) {
        console.error("Invalid calculated end date");
        return;
      }

      end.setDate(end.getDate() + stage.days);

      setFormData((prev) => ({
        ...prev,
        startDate: start.toISOString().split("T")[0],
        endDate: end.toISOString().split("T")[0],
      }));
    };

    calculateDates();
  }, [projectStartDate, stage.startOffset, stage.days]);

  const handleInputChange = (e) => {
    dispatch(startLoading());
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    dispatch(
      updateStageDetails({ projectName, stageIndex, field: name, value })
    );
    dispatch(stopLoading());
  };

  const handleCalculate = () => {
    dispatch(startLoading());
    const grossCost = Number(formData.stageBills) + Number(formData.manhour);
    const grossProfit = Number(formData.grossRevenue) - grossCost;
    const hypotheticalProfit = grossProfit - Number(formData.hypotheticalCost);
    const costControl = Number(formData.budget) - grossCost;

    setFormData((prev) => ({
      ...prev,
      grossCost,
      grossProfit,
      hypotheticalProfit,
      costControl,
    }));

    dispatch(
      updateStageDetails({
        projectName,
        stageIndex,
        field: "grossCost",
        value: grossCost,
      })
    );
    dispatch(
      updateStageDetails({
        projectName,
        stageIndex,
        field: "grossProfit",
        value: grossProfit,
      })
    );
    dispatch(
      updateStageDetails({
        projectName,
        stageIndex,
        field: "hypotheticalProfit",
        value: hypotheticalProfit,
      })
    );
    dispatch(
      updateStageDetails({
        projectName,
        stageIndex,
        field: "costControl",
        value: costControl,
      })
    );
    dispatch(stopLoading());
  };

  const removeActivityHandler = (index) => {
    const updatedActivities = [...activities];
    updatedActivities.splice(index, 1);
    setActivities(updatedActivities);
    dispatch(
      updateStageDetails({
        projectName,
        stageIndex,
        field: "activities",
        value: updatedActivities,
      })
    );
  };

  useEffect(() => {
    handleCalculate();
  }, [
    formData.stageBills,
    formData.manhour,
    formData.grossRevenue,
    formData.hypotheticalCost,
    formData.budget,
  ]);

  const [additionalFields, setAdditionalFields] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newField, setNewField] = useState({ label: "", type: "text" });

  const addField = () => {
    onOpen();
  };

  const saveNewField = () => {
    setAdditionalFields([...additionalFields, newField]);
    setNewField({ label: "", type: "text" });
    onClose();
  };

  const handleAdditionalFieldChange = (index, key, value) => {
    const updatedFields = [...additionalFields];
    updatedFields[index][key] = value;
    setAdditionalFields(updatedFields);
  };

  const [activities, setActivities] = useState(stage.activities || []);
  const [newActivity, setNewActivity] = useState("");

  const addActivityHandler = () => {
    dispatch(startLoading());
    const updatedActivities = [...activities, newActivity];
    setActivities(updatedActivities);
    console.log(updatedActivities);
    setNewActivity("");
    dispatch(
      addActivity({
        projectName,
        stageIndex,
        field: "activities",
        value: updatedActivities,
      })
    );
    dispatch(stopLoading());
  };

  return (
    <Box>
      <Flex justifyContent={"space-between"} paddingLeft={2} paddingRight={2}>
        <Text fontSize="20" fontWeight={"bold"} color={"gray.600"}>
          {stage.name} Details
        </Text>
        <Flex
          align={"center"}
          gap={5}
          border={"1px solid gray"}
          p={1}
          borderRadius={10}
        >
          <Text fontWeight={"bold"}>Mark {stage.name} as Completed</Text>
          <Switch id="isChecked" size={"lg"} />
        </Flex>
      </Flex>

      <Box mt={2} p={4} borderRadius="20" border={"2px solid gray"}>
        <Box overflowX="auto">
          <Grid
            templateColumns="repeat(12, minmax(100px, 1fr))"
            className="details-grid"
            gap={6}
            p={5}
          >
            <GridItem>
              <FormLabel>Budget</FormLabel>
              <Input
                type="number"
                name="budget"
                value={formData.budget}
                onChange={handleInputChange}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Start Date</FormLabel>
              <FormLabel>{formData.startDate}</FormLabel>
            </GridItem>
            <GridItem>
              <FormLabel>End Date</FormLabel>
              <FormLabel>{formData.endDate}</FormLabel>
            </GridItem>
            <GridItem>
              <FormLabel>Days</FormLabel>
              <FormLabel>{formData.days}</FormLabel>
            </GridItem>
            <GridItem style={{ background: "#87241d70", color: "white" }}>
              <Flex align="center" direction={"column"}>
                <FormLabel>Stage Bills</FormLabel>

                <IconButton
                  variant="outline"
                  fontSize="20px"
                  height={"25px"}
                  border={0}
                  color={"white"}
                  _hover={{ background: "black", color: "white" }}
                  aria-label="See Details"
                  icon={<IoOpenOutline />}
                  onClick={() => {
                    setDrawerType("bills");
                    setIsDrawerOpen(true);
                  }}
                />
              </Flex>
              <FormLabel>{formData.stageBills}</FormLabel>
            </GridItem>
            <GridItem style={{ background: "#87241d70", color: "white" }}>
              <Flex align="center" direction={"column"}>
                <FormLabel>Manhour</FormLabel>
                <IconButton
                  variant="outline"
                  fontSize="20px"
                  height={"25px"}
                  border={0}
                  color={"white"}
                  _hover={{ background: "black", color: "white" }}
                  aria-label="See Details"
                  icon={<IoOpenOutline />}
                  onClick={() => {
                    setDrawerType("manhour");
                    setIsDrawerOpen(true);
                  }}
                />
              </Flex>
              <FormLabel>{formData.manhour}</FormLabel>
            </GridItem>
            <GridItem style={{ background: "#0089c0", color: "white" }}>
              <FormLabel>Hypothetical Cost</FormLabel>
              <Input
                type="number"
                name="hypotheticalCost"
                value={formData.hypotheticalCost}
                onChange={handleInputChange}
              />
            </GridItem>
            <GridItem style={{ background: "#87241d70", color: "white" }}>
              <FormLabel>Gross Cost (Labor + Bills)</FormLabel>
              <FormLabel>{formData.grossCost}</FormLabel>
            </GridItem>

            <GridItem style={{ background: "#ffea0d7a", color: "black" }}>
              <FormLabel>Cost Control</FormLabel>
              <FormLabel>{formData.costControl}</FormLabel>
            </GridItem>
            <GridItem style={{ background: "#0F3C5C", color: "white" }}>
              <FormLabel>Gross Revenue (BOQ)</FormLabel>
              <Input
                type="number"
                name="grossRevenue"
                value={formData.grossRevenue}
                onChange={handleInputChange}
              />
            </GridItem>
            <GridItem style={{ background: "#178a3ea1", color: "white" }}>
              <FormLabel>Gross Profit</FormLabel>
              <FormLabel>{formData.grossProfit}</FormLabel>
            </GridItem>
            <GridItem style={{ background: "#0089c0", color: "white" }}>
              <FormLabel>Hypothetical Profit</FormLabel>
              <Input
                type="number"
                name="hypotheticalProfitInput"
                value={formData.hypotheticalProfitInput}
                onChange={handleInputChange}
              />
            </GridItem>
            {additionalFields.map((field, index) => (
              <GridItem key={index}>
                <FormLabel>
                  {field.label} <span>x</span>
                </FormLabel>
                <Input
                  type={field.type}
                  value={field.value}
                  onChange={(e) =>
                    handleAdditionalFieldChange(index, "value", e.target.value)
                  }
                />
              </GridItem>
            ))}
          </Grid>
        </Box>
        <Button mt={4} onClick={addField}>
          Add Field
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Custom Field</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel>Field Label</FormLabel>
            <Input
              type="text"
              value={newField.label}
              onChange={(e) =>
                setNewField({ ...newField, label: e.target.value })
              }
            />
            <FormLabel>Field Type</FormLabel>
            <Select
              value={newField.type}
              onChange={(e) =>
                setNewField({ ...newField, type: e.target.value })
              }
            >
              <option value="text">Text</option>
              <option value="number">Number</option>
              <option value="date">Date</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={saveNewField}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box mt={8} p={4} borderRadius={20} backgroundColor={"#eeeeee5c"}>
        <FormLabel display="flex" alignItems="center" gap={5} mb={5}>
          <ActivityIcon
            style={{ marginRight: 5 }}
            fill="primary.500"
            color="primary.500"
          />
          <Text fontSize="30" fontWeight={"bold"} color={"gray.600"}>
            Activities
          </Text>
          {isCollapsed && (
            <FaFolder
              fontSize={20}
              color="primary.500"
              onClick={() => {
                setIsCollapsed(false);
              }}
            />
          )}
          {!isCollapsed && (
            <FaFolderOpen
              fontSize={20}
              color="primary.500"
              onClick={() => {
                setIsCollapsed(true);
              }}
            />
          )}
        </FormLabel>

        <Collapse in={isCollapsed} animateOpacity>
          <Box>
            {activities.map((activity, index) => (
              <Flex
                key={index}
                align="center"
                justify="space-between"
                p={2}
                bg="#d1d1d1"
                borderRadius={5}
                mb={2}
              >
                <Box>{index + 1}</Box>
                <Box flex={1} mx={3}>
                  {activity}
                </Box>
                <IconButton
                  aria-label="Remove Activity"
                  ml={2}
                  icon={<TiDeleteOutline />}
                  colorScheme="red"
                  onClick={() => removeActivityHandler(index)}
                />
              </Flex>
            ))}
            <Flex mt={4} direction="column" spacing={5} gap={5}>
              <Textarea
                placeholder="Add new activity"
                value={newActivity}
                onChange={(e) => setNewActivity(e.target.value)}
              />
              <Button onClick={addActivityHandler}>Add Activity</Button>
            </Flex>
          </Box>
        </Collapse>
      </Box>
      <DetailsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        type={drawerType}
      />
    </Box>
  );
};

export default StageDetails;
