import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: !!localStorage.getItem("token"), // Check localStorage for token
  lastVisitedPath: "/",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
      localStorage.setItem("token", "authenticated");
    },
    logout: (state, action) => {
      state.isAuthenticated = false;
      localStorage.removeItem("token");
      state.lastVisitedPath = action.payload || "/";
    },
  },
});

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectLastVisitedPath = (state) => state.auth.lastVisitedPath;

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
