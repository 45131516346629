import React, { useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MonthSelector = ({ onMonthChange }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [searchTerm, setSearchTerm] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex);
    onMonthChange && onMonthChange(monthIndex);
    onClose();
  };

  const filteredMonths = months.filter((month) =>
    month.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        onClick={onOpen}
        variant="outline"
        size="lg"
        colorScheme="teal"
        _focus={{ boxShadow: "outline" }}
        _hover={{ bg: "teal.100" }}
        bg="white"
        zIndex="9999"
      >
        {months[selectedMonth]}
      </MenuButton>
      <MenuList maxH="200px" overflowY="auto" zIndex="9999" boxShadow="lg">
        <Box px={3} pb={2}>
          <Input
            placeholder="Search month"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="sm"
            mb={2}
          />
        </Box>
        {filteredMonths.map((month, index) => (
          <MenuItem
            key={month}
            onClick={() => handleMonthChange(index)}
            _hover={{ bg: "teal.100" }}
            _focus={{ bg: "teal.200" }}
          >
            {month}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default MonthSelector;
