import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Textarea,
  Select,
  Input,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addRequirement,
  selectWeeklyRequirements,
  selectMaterials,
} from "../../store/weeklyMaterialRequirementSlice";
import { selectProjects } from "../../store/projectsSlice";
import GenericHeader from "../../commons/GenericHeader";
import SelectBox from "../../commons/SelectBox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PrimaryButton from "../../commons/PrimaryButton";

const AddWorkRequired = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const materials = useSelector(selectMaterials);
  const weeklyRequirements = useSelector(selectWeeklyRequirements);

  const [selectedProject, setSelectedProject] = useState("");
  const [workRequired, setWorkRequired] = useState("");
  const [remarks, setRemarks] = useState("");
  const [materialRequired, setMaterialRequired] = useState([]);
  const [manpowerRequired, setManpowerRequired] = useState([]);
  const [toolsRequired, setToolsRequired] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const handleAddMaterial = () => {
    setMaterialRequired([...materialRequired, { name: "", qty: 0 }]);
  };

  const handleAddManpower = () => {
    setManpowerRequired([...manpowerRequired, { skill: "", qty: 0, rate: 0 }]);
  };

  const handleAddTool = () => {
    setToolsRequired([...toolsRequired, { name: "", qty: 0, rate: 0 }]);
  };

  const handleMaterialChange = (index, field, value) => {
    const updatedMaterials = materialRequired.map((material, i) =>
      i === index ? { ...material, [field]: value } : material
    );
    setMaterialRequired(updatedMaterials);
  };

  const handleManpowerChange = (index, field, value) => {
    const updatedManpower = manpowerRequired.map((manpower, i) =>
      i === index ? { ...manpower, [field]: value } : manpower
    );
    setManpowerRequired(updatedManpower);
  };

  const handleToolChange = (index, field, value) => {
    const updatedTools = toolsRequired.map((tool, i) =>
      i === index ? { ...tool, [field]: value } : tool
    );
    setToolsRequired(updatedTools);
  };

  const calculateTotalCost = () => {
    const materialCost = materialRequired.reduce((total, material) => {
      const materialInfo = materials.find((m) => m.name === material.name);
      const price = materialInfo ? materialInfo.price : 0;
      const vat = price * 0.05;
      return total + material.qty * (price + vat);
    }, 0);

    const manpowerCost = manpowerRequired.reduce(
      (total, manpower) => total + manpower.qty * manpower.rate,
      0
    );

    const toolsCost = toolsRequired.reduce(
      (total, tool) => total + tool.qty * tool.rate,
      0
    );

    return materialCost + manpowerCost + toolsCost;
  };

  const handleSubmit = () => {
    const newRequirement = {
      id: weeklyRequirements.length + 1,
      project: selectedProject,
      workRequired,
      materialRequired,
      manpowerRequired,
      toolsRequired,
      dateFrom: startDate,
      dateTo: endDate,
    };
    dispatch(addRequirement(newRequirement));
  };

  return (
    <Box>
      <GenericHeader
        title={"Work Required and Resource Planning and Management"}
        back={"/work-required"}
      />
      <Box
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mb={5}>
          <FormControl>
            <FormLabel>Select Site</FormLabel>
            <SelectBox
              placeholder="Select Project/Site"
              value={selectedProject}
              onChange={(value) => setSelectedProject(value)}
              options={projects.map((project) => {
                return { label: project.name, value: project.id };
              })}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Select Date</FormLabel>
            <DatePicker
              selectsRange
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => setDateRange(update)}
              isClearable={true}
              todayButton="Today"
              className="react-datepicker__input-container"
            />
          </FormControl>
        </SimpleGrid>
        <FormControl marginBottom={20}>
          <FormLabel>Work Required Description</FormLabel>
          <ReactQuill
            theme="snow"
            value={workRequired}
            onChange={setWorkRequired}
            placeholder="Describe the work required..."
            style={{ height: "200px" }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Remarks</FormLabel>
          <Textarea
            placeholder="Remarks"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            mb={4}
          />
        </FormControl>

        <Tabs isFitted variant="enclosed" mb={4}>
          <TabList>
            <Tab
              _selected={{ bg: "blue.500", color: "white" }}
              _hover={{ bg: "blue.200" }}
              _focus={{ boxShadow: "outline" }}
            >
              Materials
            </Tab>
            <Tab
              _selected={{ bg: "green.500", color: "white" }}
              _hover={{ bg: "green.200" }}
              _focus={{ boxShadow: "outline" }}
            >
              Manpower
            </Tab>
            <Tab
              _selected={{ bg: "purple.500", color: "white" }}
              _hover={{ bg: "purple.200" }}
              _focus={{ boxShadow: "outline" }}
            >
              Tools
            </Tab>
          </TabList>

          <TabPanels>
            {/* Materials Tab */}
            <TabPanel>
              <Button onClick={handleAddMaterial} mb={4}>
                Add Material
              </Button>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Material</Th>
                    <Th>Quantity</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {materialRequired.map((material, index) => (
                    <Tr key={index}>
                      <Td>
                        <Select
                          placeholder="Select Material"
                          value={material.name}
                          onChange={(e) =>
                            handleMaterialChange(index, "name", e.target.value)
                          }
                        >
                          {materials.map((material) => (
                            <option key={material.id} value={material.name}>
                              {material.name}
                            </option>
                          ))}
                        </Select>
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          placeholder="Quantity"
                          value={material.qty}
                          onChange={(e) =>
                            handleMaterialChange(index, "qty", e.target.value)
                          }
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TabPanel>

            {/* Manpower Tab */}
            <TabPanel>
              <Button onClick={handleAddManpower} mb={4}>
                Add Manpower
              </Button>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Skill</Th>
                    <Th>No. of Employees</Th>
                    <Th>Rate per Employee</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {manpowerRequired.map((manpower, index) => (
                    <Tr key={index}>
                      <Td>
                        <Input
                          placeholder="Skill"
                          value={manpower.skill}
                          onChange={(e) =>
                            handleManpowerChange(index, "skill", e.target.value)
                          }
                        />
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          placeholder="No of Employees"
                          value={manpower.qty}
                          onChange={(e) =>
                            handleManpowerChange(index, "qty", e.target.value)
                          }
                        />
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          placeholder="Rate per Employee"
                          value={manpower.rate}
                          onChange={(e) =>
                            handleManpowerChange(index, "rate", e.target.value)
                          }
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TabPanel>

            {/* Tools Tab */}
            <TabPanel>
              <Button onClick={handleAddTool} mb={4}>
                Add Tool
              </Button>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Tool Name</Th>
                    <Th>Quantity</Th>
                    <Th>Rate per Tool</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {toolsRequired.map((tool, index) => (
                    <Tr key={index}>
                      <Td>
                        <Input
                          placeholder="Tool Name"
                          value={tool.name}
                          onChange={(e) =>
                            handleToolChange(index, "name", e.target.value)
                          }
                        />
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          placeholder="Quantity"
                          value={tool.qty}
                          onChange={(e) =>
                            handleToolChange(index, "qty", e.target.value)
                          }
                        />
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          placeholder="Rate per Tool"
                          value={tool.rate}
                          onChange={(e) =>
                            handleToolChange(index, "rate", e.target.value)
                          }
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Text fontWeight="bold" textAlign="right" mt={4}>
          Total Cost: {calculateTotalCost().toFixed(2)}
        </Text>

        <PrimaryButton
          title={"Save Weekly Target"}
          onClick={handleSubmit}
          colorScheme="primary"
          mt={4}
        ></PrimaryButton>
      </Box>
    </Box>
  );
};

export default AddWorkRequired;
