export const isMobile = () => {
  return window.innerWidth <= 768;
};
export const calculateDaysBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDifference = end - start;
  const dayDifference = timeDifference / (1000 * 3600 * 24);
  return dayDifference + 1; // Adding 1 to include both start and end date
};

export function getContrastYIQ(hexColor) {
  // Remove the hash at the start if it's there
  hexColor = hexColor.replace(/^#/, "");

  // Parse the r, g, b values
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the YIQ (brightness)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black or white based on brightness
  return yiq >= 128 ? "black" : "white";
}

export const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) return "Good Morning";
  if (hour < 18) return "Good Afternoon";
  if (hour < 21) return "Good Evening";
  return "Good Night";
};
