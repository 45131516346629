import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../store/sidebarSlice";
import { logout } from "../store/authSlice"; // Ensure the path is correct
import ThemeToggle from "./ThemeToggle";
import "../styles/Header.css";
import { MdOutlineChatBubble, MdOutlineNotifications } from "react-icons/md";
import logoImg from "../images/Logo-Header.png";
import {
  Box,
  Image,
  useBreakpointValue,
  Avatar,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  Flex,
  Button,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { CiCircleChevDown } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Header = ({ toggleSidebar }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleSettingsClick = () => {
    setDrawerOpen(true);
    setDropdownOpen(false);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    setLoggingOut(true);
    setTimeout(() => {
      dispatch(logout(window.location.pathname));
      setLoggingOut(false);
      window.location.href = "/login";
    }, 2000); // Simulate an API call delay
  };

  return (
    <header className="header">
      <div className="left-section">
        <button className="toggle-button" onClick={handleToggleSidebar}>
          <FaBars />
        </button>
        <Image src={logoImg} alt="Logo" mr={4} height={isMobile ? 30 : 50} />
        <Box fontWeight="bold" fontSize={isMobile ? "lg" : "xl"}>
          {isMobile ? "ERP" : "Enterprise Resource Planning"}
        </Box>
      </div>
      <div className="user-menu">
        <Flex align="center" gap={10}>
          <MdOutlineNotifications className="user-icon" />
          {!isMobile && <MdOutlineChatBubble className="user-icon" />}
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<CiCircleChevDown />}
              variant="ghost"
              className="user-menu-button"
            >
              <Flex align={"center"}>
                <Avatar size="sm" name={"Abu Bakar"} mr={2} />
                <Text>Abu Bakar</Text>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
              {isMobile && (
                <MenuItem icon={<MdOutlineChatBubble />}>Messages</MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  navigate("/profile");
                }}
              >
                Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </div>
      {isDrawerOpen && (
        <div className="drawer">
          <div className="drawer-content">
            <div className="drawer-header">
              <button className="close-button" onClick={handleCloseDrawer}>
                &times;
              </button>
              <h2>Settings</h2>
            </div>
            <ThemeToggle />
          </div>
        </div>
      )}
      {loggingOut && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(255, 255, 255, 0.8)"
          align="center"
          justify="center"
          zIndex="1000"
        >
          <Spinner size="xl" />
        </Flex>
      )}
    </header>
  );
};

export default Header;
