import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import GenericHeader from "../../commons/GenericHeader";
import { useDispatch } from "react-redux";
import { addOwnTool } from "../../store/toolsSlice";
import { LiaToolsSolid } from "react-icons/lia";
import { IoAddCircleOutline } from "react-icons/io5";

const AddOwnTool = () => {
  const dispatch = useDispatch();
  const [toolData, setToolData] = useState({
    toolName: "",
    toolID: "",
    toolType: "",
    quantityAvailable: 0,
    siteUsing: "",
    condition: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setToolData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addOwnTool(toolData));
  };

  return (
    <Box>
      <GenericHeader
        title="Add Own Tool"
        icon={<LiaToolsSolid />}
        back={"/tools"}
      />
      <Box p={4} borderWidth="1px" borderRadius="md" backgroundColor="white">
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="flex-start">
            <FormControl isRequired>
              <FormLabel>Tool ID</FormLabel>
              <Input
                name="toolID"
                value={toolData.toolID}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Tool Type</FormLabel>
              <Select
                name="toolType"
                value={toolData.toolType}
                onChange={handleChange}
              >
                <option value="">Select Tool Type</option>
                <option value="Grinder">Grinder</option>
                <option value="HILTI">HILTI</option>
                <option value="DRILL">DRILL</option>
                <option value="COMPACTOR">COMPACTOR</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Quantity Available</FormLabel>
              <Input
                type="number"
                name="quantityAvailable"
                value={toolData.quantityAvailable}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Site Using</FormLabel>
              <Input
                name="siteUsing"
                value={toolData.siteUsing}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Condition</FormLabel>
              <Select
                name="condition"
                value={toolData.condition}
                onChange={handleChange}
              >
                <option value="">Select Condition</option>
                <option value="WORKING">WORKING</option>
                <option value="RETIRE DAMAGED">RETIRE DAMAGED</option>
                <option value="IN REPAIR">IN REPAIR</option>
              </Select>
            </FormControl>
            <Flex justifyContent={"center"}>
              <button className="btn-primary" type="submit">
                <IoAddCircleOutline /> Add Own Tool
              </button>
            </Flex>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default AddOwnTool;
