import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Switch,
  Image,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import GenericGrid from "../Procurement/GenericGrid";
import TruckSvg from "../../images/truck.png";
import { addTransport } from "../../store/transportSlice";
import { useNavigate } from "react-router-dom";
import DashboardBox from "../../commons/DashboardBox";

const TransportList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([
    {
      sno: 1,
      date: "2024-01-01",
      siteName: "Site 1",
      transporter: "Akram",
      workType: "Option 1",
      rate: 100,
      paid: 50,
      remarks: "Delivery completed on time.",
      photo: "", // Example with no photo
    },
    {
      sno: 2,
      date: "2024-01-02",
      siteName: "Site 2",
      transporter: "Aslam",
      workType: "Option 2",
      rate: 200,
      paid: 150,
      remarks: "Delayed due to traffic.",
      photo: "https://via.placeholder.com/150", // Example with a photo
    },
  ]);
  const [originalData] = useState(rowData);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(rowData);

  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedTransporter, setSelectedTransporter] = useState("");
  const [selectedSite, setSelectedSite] = useState("");

  const filters = useMemo(
    () => ({
      transporter: selectedTransporter,
      site: selectedSite,
    }),
    [selectedTransporter, selectedSite]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedTransporter = selectedTransporter;
    let newSelectedSite = selectedSite;

    switch (filterType) {
      case "transporter":
        newSelectedTransporter = "";
        break;
      case "site":
        newSelectedSite = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedTransporter ||
          row.transporter === newSelectedTransporter) &&
        (!newSelectedSite || row.siteName === newSelectedSite)
      );
    });

    setSelectedTransporter(newSelectedTransporter);
    setSelectedSite(newSelectedSite);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedTransporter || row.transporter === selectedTransporter) &&
        (!selectedSite || row.siteName === selectedSite)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedTransporter("");
    setSelectedSite("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleRowClick = (row) => {
    navigate(`/transport/edit/${row.id}`);
  };

  const transportColumns = [
    {
      id: "sno",
      header: "Sno",
      accessor: (item) => item.sno,
      show: true,
      width: 20,
    },
    {
      id: "date",
      header: "DATE",
      accessor: (item) => item.date,
      show: true,
      width: 150,
    },
    {
      id: "siteName",
      header: "SITE NAME AND AREA",
      accessor: (item) => item.siteName,
      show: true,
      width: 150,
    },
    {
      id: "transporter",
      header: "TRANSPORTER NAME",
      accessor: (item) => item.transporter,
      show: true,
      width: 150,
    },
    {
      id: "workType",
      header: "WORK TYPE",
      accessor: (item) => item.workType,
      show: true,
      width: 150,
    },
    {
      id: "rate",
      header: "RATE",
      accessor: (item) => item.rate,
      show: true,
      width: 150,
    },
    {
      id: "paid",
      header: "PAID",
      accessor: (item) => item.paid,
      show: true,
      width: 150,
    },
    {
      id: "balance",
      header: "BALANCE",
      accessor: (item) => item.rate - item.paid,
      show: true,
      width: 150,
    },
    {
      id: "remarks",
      header: "Remarks",
      accessor: (item) => item.remarks,
      show: true,
      width: 250,
    },
    {
      id: "photo",
      header: "Photo",
      render: (row) =>
        row.photo ? (
          <Image src={row.photo} alt="Transport Photo" boxSize="50px" />
        ) : (
          "No Photo"
        ),
      show: true,
      width: 150,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    transportColumns.filter((col) => col.show).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };
  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };
  const totalPaid = useMemo(() => {
    return rowData.reduce((total, row) => total + row.paid, 0);
  }, [rowData]);

  const totalBalance = useMemo(() => {
    return rowData.reduce((total, row) => total + (row.rate - row.paid), 0);
  }, [rowData]);

  const averageRate = useMemo(() => {
    return rowData.length
      ? (
          rowData.reduce((total, row) => total + row.rate, 0) / rowData.length
        ).toFixed(2)
      : 0;
  }, [rowData]);

  return (
    <Box>
      <GenericHeader
        title="Transport List"
        to={"/transport/add"}
        addButtonName={"Transport "}
        icon={<Image src={TruckSvg} height={"50px"} />}
      />
      <Flex justifyContent="space-between" mb={4} mt={4} gap={2}>
        <DashboardBox
          icon={AddIcon} // You can choose any relevant icon
          name="Total Balance"
          value={totalBalance}
          type="money"
          backgroundColor="teal.300"
        />
        <DashboardBox
          icon={AddIcon} // You can choose any relevant icon
          name="Total Paid"
          value={totalPaid}
          type="money"
          backgroundColor="blue.300"
        />
        <DashboardBox
          icon={AddIcon} // You can choose any relevant icon
          name="Average Rate"
          value={averageRate}
          backgroundColor="purple.300"
        />
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Transport"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.transporter && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Transporter: ${filters.transporter}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("transporter")} />
            </Tag>
          )}
          {filters.site && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Site: ${filters.site}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("site")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Transporter"
                onChange={(e) => setSelectedTransporter(e.target.value)}
                mb={4}
                value={selectedTransporter}
              >
                {originalData.map((row) => (
                  <option key={row.transporter} value={row.transporter}>
                    {row.transporter}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Site"
                onChange={(e) => setSelectedSite(e.target.value)}
                value={selectedSite}
              >
                {originalData.map((row) => (
                  <option key={row.siteName} value={row.siteName}>
                    {row.siteName}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {transportColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={transportColumns}
          data={filteredData}
          title="Transport List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default TransportList;
