import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subcontractors: [
    {
      sno: 1,
      subcontractorName: "Subcontractor A",
      siteName: "Site 1",
      typeOfWork: "Electrical",
      agreementAmount: 10000,
      paymentsPlan: [
        { work: "Initial work", amount: 3000 },
        { work: "Phase 2", amount: 2000 },
      ],
      totalPaid: 5000,
      balance: 5000,
    },
    {
      sno: 2,
      subcontractorName: "Subcontractor B",
      siteName: "Site 2",
      typeOfWork: "Plumbing",
      agreementAmount: 15000,
      paymentsPlan: [
        { work: "Initial work", amount: 5000 },
        { work: "Phase 2", amount: 5000 },
      ],
      totalPaid: 10000,
      balance: 5000,
    },
    // Add more rows as needed
  ],
};

const subcontractorsSlice = createSlice({
  name: "subcontractors",
  initialState,
  reducers: {
    addSubcontractor: (state, action) => {
      state.subcontractors.push(action.payload);
    },
    updateSubcontractor: (state, action) => {
      const { sno, updatedData } = action.payload;
      const index = state.subcontractors.findIndex((sc) => sc.sno === sno);
      if (index !== -1) {
        state.subcontractors[index] = {
          ...state.subcontractors[index],
          ...updatedData,
        };
      }
    },
    addPayment: (state, action) => {
      const { sno, payment } = action.payload;
      const index = state.subcontractors.findIndex((sc) => sc.sno === sno);
      if (index !== -1) {
        state.subcontractors[index].paymentsPlan.push(payment);
        state.subcontractors[index].totalPaid += payment.amount;
        state.subcontractors[index].balance =
          state.subcontractors[index].agreementAmount -
          state.subcontractors[index].totalPaid;
      }
    },
    deletePayment: (state, action) => {
      const { sno, paymentIndex } = action.payload;
      const index = state.subcontractors.findIndex((sc) => sc.sno === sno);
      if (index !== -1) {
        const [deletedPayment] = state.subcontractors[
          index
        ].paymentsPlan.splice(paymentIndex, 1);
        state.subcontractors[index].totalPaid -= deletedPayment.amount;
        state.subcontractors[index].balance =
          state.subcontractors[index].agreementAmount -
          state.subcontractors[index].totalPaid;
      }
    },
  },
});

export const {
  addSubcontractor,
  updateSubcontractor,
  addPayment,
  deletePayment,
} = subcontractorsSlice.actions;

export const selectSubcontractors = (state) =>
  state.subcontractors.subcontractors;

export default subcontractorsSlice.reducer;
