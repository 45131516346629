import React, { useState, useMemo, useCallback } from "react";
import {
  Button,
  Box,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Select,
  useToast,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { GrMoney, GrUserWorker, GrFilter } from "react-icons/gr";
import { AiOutlineDollar } from "react-icons/ai";
import GenericGrid from "../Procurement/GenericGrid";
import GenericHeader from "../../commons/GenericHeader";
import DashboardBox from "../../commons/DashboardBox";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import SearchInput from "../../commons/SearchInput";
import { IoAddCircleOutline } from "react-icons/io5";
import ImageGallery from "../../commons/ImageGallery";
import { GoColumns } from "react-icons/go";

const DailyManpowerList = () => {
  const [rowData, setRowData] = useState([
    {
      sno: 1,
      date: "2024-01-01",
      name: "Akram",
      skill: "Electrician",
      siteName: "Site 1",
      siteStage: "Till Building",
      rate: 100,
      workDoneEvaluation: "Good",
      paid: 50,
      foreman: "Foreman A",
      pictures: [
        "https://images.pexels.com/photos/27050027/pexels-photo-27050027/free-photo-of-frame-the-view-of-the-new-mosque-from-karakoy-pier.jpeg",
        "https://images.pexels.com/photos/27050027/pexels-photo-27050027/free-photo-of-frame-the-view-of-the-new-mosque-from-karakoy-pier.jpeg",
        "https://images.pexels.com/photos/27050027/pexels-photo-27050027/free-photo-of-frame-the-view-of-the-new-mosque-from-karakoy-pier.jpeg",
        "https://images.pexels.com/photos/27050027/pexels-photo-27050027/free-photo-of-frame-the-view-of-the-new-mosque-from-karakoy-pier.jpeg",
        "https://images.pexels.com/photos/19276913/pexels-photo-19276913/free-photo-of-young-brunette-on-balcony-against-blue-sky.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      ],
      workDescription: "Installed new wiring",
    },
    {
      sno: 2,
      date: "2024-01-02",
      name: "Aslan",
      skill: "Plumber",
      siteName: "Site 2",
      siteStage: "Finishing",
      rate: 200,
      workDoneEvaluation: "Excellent",
      paid: 150,
      foreman: "Foreman B",
      pictures: ["https://via.placeholder.com/150"],
      workDescription: "Fixed leaking pipes",
    },
    // Additional rows...
  ]);

  const [originalData] = useState(rowData); // Store original data
  const [nextSno, setNextSno] = useState(rowData.length + 1);
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();
  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedForeman, setSelectedForeman] = useState("");
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(rowData);

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const filters = useMemo(
    () => ({
      skill: selectedSkill,
      date: selectedDate,
      site: selectedSite,
      foreman: selectedForeman,
    }),
    [selectedSkill, selectedDate, selectedSite, selectedForeman]
  );

  const handleFilterChange = (newFilters) => {
    setSelectedSkill(newFilters.skill);
    setSelectedDate(newFilters.date);
    setSelectedSite(newFilters.site);
    setSelectedForeman(newFilters.foreman);
  };

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const calculateBalance = (row) => {
    return row.rate - row.paid;
  };

  const calculateTotalBalance = useMemo(() => {
    return filteredData.reduce((sum, row) => sum + (row.rate - row.paid), 0);
  }, [filteredData]);

  const calculateTotalPaid = useMemo(() => {
    return filteredData.reduce((sum, row) => sum + row.paid, 0);
  }, [filteredData]);

  const calculateAverageRate = useMemo(() => {
    return (
      filteredData.reduce((sum, row) => sum + row.rate, 0) / filteredData.length
    ).toFixed(2);
  }, [filteredData]);

  const addRow = () => {
    const newRow = {
      sno: nextSno,
      date: "",
      name: "",
      skill: "",
      siteName: "",
      siteStage: "",
      rate: 0,
      workDoneEvaluation: "",
      paid: 0,
      foreman: "",
      pictures: [],
      workDescription: "",
    };
    setRowData([...rowData, newRow]);
    setNextSno(nextSno + 1);
    toast({
      title: "New row added.",
      description: "You can now edit the new row.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const deleteRow = (sno) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (confirmed) {
      setRowData(rowData.filter((row) => row.sno !== sno));
      setFilteredData(filteredData.filter((row) => row.sno !== sno));
      toast({
        title: "Row deleted.",
        description: `Row with SNo ${sno} has been deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedSkill || row.skill === selectedSkill) &&
        (!selectedDate || row.date === selectedDate) &&
        (!selectedSite || row.siteName === selectedSite) &&
        (!selectedForeman || row.foreman === selectedForeman)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedSkill("");
    setSelectedDate("");
    setSelectedSite("");
    setSelectedForeman("");
    setRowData(originalData); // Reset to original data
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const handleTagClose = (filterType) => {
    let newSelectedSkill = selectedSkill;
    let newSelectedDate = selectedDate;
    let newSelectedSite = selectedSite;
    let newSelectedForeman = selectedForeman;

    switch (filterType) {
      case "skill":
        newSelectedSkill = "";
        break;
      case "date":
        newSelectedDate = "";
        break;
      case "site":
        newSelectedSite = "";
        break;
      case "foreman":
        newSelectedForeman = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedSkill || row.skill === newSelectedSkill) &&
        (!newSelectedDate || row.date === newSelectedDate) &&
        (!newSelectedSite || row.siteName === newSelectedSite) &&
        (!newSelectedForeman || row.foreman === newSelectedForeman)
      );
    });

    setSelectedSkill(newSelectedSkill);
    setSelectedDate(newSelectedDate);
    setSelectedSite(newSelectedSite);
    setSelectedForeman(newSelectedForeman);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const columns = [
    {
      id: "sno",
      header: "SNo",
      accessor: (item) => item.sno,
      show: true,
      minWidth: "150px",
    },
    {
      id: "date",
      header: "Date",
      accessor: (item) => item.date,
      show: true,
      minWidth: "150px",
    },
    {
      id: "name",
      header: "Name",
      accessor: (item) => item.name,
      show: true,
      minWidth: "150px",
    },
    {
      id: "skill",
      header: "Skill / Designation",
      accessor: (item) => item.skill,
      show: true,
      minWidth: "150px",
    },
    {
      id: "siteName",
      header: "Site Name",
      accessor: (item) => item.siteName,
      show: true,
      minWidth: "150px",
    },
    {
      id: "siteStage",
      header: "Site Stage",
      accessor: (item) => item.siteStage,
      show: true,
      minWidth: "150px",
    },
    {
      id: "rate",
      header: "Rate",
      accessor: (item) => item.rate,
      show: true,
      minWidth: "150px",
    },
    {
      id: "workDoneEvaluation",
      header: "Work Done Evaluation",
      accessor: (item) => item.workDoneEvaluation,
      show: true,
      minWidth: "150px",
    },
    {
      id: "paid",
      header: "Paid",
      accessor: (item) => item.paid,
      show: true,
      minWidth: "150px",
    },
    {
      id: "balance",
      header: "Balance",
      accessor: calculateBalance,
      show: true,
      minWidth: "150px",
    },
    {
      id: "foreman",
      header: "Foreman",
      accessor: (item) => item.foreman,
      show: true,
      minWidth: "150px",
    },
    {
      id: "pictures",
      header: "Pictures",
      accessor: (item) => item.pictures,
      render: (item) => (
        // <div style={{ display: "flex", flexWrap: "wrap" }}>
        //   {item.pictures.map((url, index) => (
        //     <Zoom>
        //       <div
        //         key={index}
        //         style={{
        //           position: "relative",
        //           marginRight: 5,
        //           marginBottom: 5,
        //         }}
        //       >
        //         <img
        //           src={url}
        //           alt={`work done ${index}`}
        //           style={{
        //             width: 50,
        //             height: 50,
        //             marginRight: 5,
        //             marginBottom: 5,
        //           }}
        //         />
        //       </div>
        //     </Zoom>
        //   ))}
        // </div>
        <Box minWidth={150} onClick={(e) => e.stopPropagation()}>
          <ImageGallery images={item.pictures} width={150} />
        </Box>
      ),
      show: true,
      minWidth: "150px",
    },
    {
      id: "workDescription",
      header: "Work Description",
      accessor: (item) => item.workDescription,
      show: true,
      minWidth: "150px",
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.sno,
      render: (item) => (
        <IconButton icon={<DeleteIcon />} onClick={() => deleteRow(item.sno)} />
      ),
      show: true,
      minWidth: "150px",
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.filter((col) => col.show).map((col) => col.id)
  );
  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };
  const handleRowClick = (row) => {
    navigate(`/daily-manpower/edit/${row.id}`);
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader title="Daily Manpower List" />
        <Button
          leftIcon={<IoAddCircleOutline />}
          borderRadius={"lg"}
          colorScheme="primary"
          onClick={() => navigate("/daily-manpower/add")}
        >
          Add Manpower
        </Button>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={GrMoney}
            name="Total Balance"
            value={calculateTotalBalance}
            backgroundColor="blue.300"
            type="money"
          />
          <DashboardBox
            icon={AiOutlineDollar}
            name="Total Paid"
            value={calculateTotalPaid}
            backgroundColor="yellow.300"
            type="money"
          />
          <DashboardBox
            icon={GrUserWorker}
            name="Average Rate"
            value={calculateAverageRate}
            backgroundColor="red.300"
            type="rate"
          />
          <DashboardBox
            icon={GrUserWorker}
            name="Most Hired Skill"
            value={"Plumber"}
            backgroundColor="gray.300"
          />
          <DashboardBox
            icon={GrUserWorker}
            name="Total Hours"
            value={"1000 "}
            backgroundColor="gray.300"
          />
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-between"
        mb={4}
        alignItems={"center"}
        gap={10}
      >
        <Button leftIcon={<GrFilter />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder={`Search Manpower List`}
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />

        <Button leftIcon={<GoColumns />} onClick={onOpenColumnModal}>
          Toggle Columns
        </Button>
      </Flex>
      <Flex mb={4} mt={4}>
        <Flex>
          {filters.skill && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Skill: ${filters.skill}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("skill")} />
            </Tag>
          )}
          {filters.date && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Date: ${filters.date}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("date")} />
            </Tag>
          )}
          {filters.site && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Site: ${filters.site}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("site")} />
            </Tag>
          )}
          {filters.foreman && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Foreman: ${filters.foreman}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("foreman")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Skill/Designation"
                onChange={(e) => setSelectedSkill(e.target.value)}
                mb={4}
                value={selectedSkill}
              >
                <option value="Electrician">Electrician</option>
                <option value="Plumber">Plumber</option>
                <option value="Carpenter">Carpenter</option>
              </Select>
              <Select
                placeholder="Select Date"
                onChange={(e) => setSelectedDate(e.target.value)}
                mb={4}
                value={selectedDate}
              >
                <option value="2024-01-01">2024-01-01</option>
                <option value="2024-01-02">2024-01-02</option>
                <option value="2024-01-03">2024-01-03</option>
                <option value="2024-01-04">2024-01-04</option>
              </Select>
              <Select
                placeholder="Select Site"
                onChange={(e) => setSelectedSite(e.target.value)}
                mb={4}
                value={selectedSite}
              >
                <option value="Site 1">Site 1</option>
                <option value="Site 2">Site 2</option>
                <option value="Site 3">Site 3</option>
              </Select>
              <Select
                placeholder="Select Foreman"
                onChange={(e) => setSelectedForeman(e.target.value)}
                value={selectedForeman}
              >
                <option value="Foreman A">Foreman A</option>
                <option value="Foreman B">Foreman B</option>
                <option value="Foreman C">Foreman C</option>
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {columns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box>
        <Flex gap={2} pl={20} mb={5}>
          <Button colorScheme="orange" size={"sm"}>
            Delete
          </Button>
          <Button colorScheme="primary" size={"sm"}>
            Bulk Clear Balance
          </Button>
        </Flex>
        <GenericGrid
          maxHeight={"400px"}
          columns={columns}
          data={filteredData}
          title="Manpower List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default DailyManpowerList;
