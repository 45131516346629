import React, { useState } from "react";
import { InputGroup, Input, InputRightElement, Button } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const SearchInput = ({ value, onChange, onClear, placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <InputGroup
      size="md"
      style={{
        borderRadius: "30px",
      }}
    >
      <Input
        placeholder={placeholder}
        pr="4.5rem"
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{
          padding: "15px 23px",
          fontSize: "20px",
          backgroundColor: isFocused ? "white" : "transparent",
          border: isFocused ? "2px solid black" : "1px solid #ccc",
          borderRadius: "30px",
          height: "50px",
        }}
      />
      {value && (
        <InputRightElement width="4.5rem" height="50px">
          <Button h="1.75rem" size="sm" onClick={onClear}>
            <CloseIcon />
          </Button>
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default SearchInput;
