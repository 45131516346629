import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Switch,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  useDisclosure,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  Text,
  Flex,
  Input,
} from "@chakra-ui/react";
import { MdSwitchAccessShortcutAdd } from "react-icons/md";
import GenericHeader from "../../commons/GenericHeader";
import UserRowBox from "../../commons/UserRowBox";
import GenericGrid from "../Procurement/GenericGrid";
import SelectBox from "../../commons/SelectBox";
import SearchInput from "../../commons/SearchInput";
const roleNames = [
  "Admin",
  "HR",
  "CEO",
  "Office Clerk",
  "Worker",
  "Foreman",
  "Supervisor",
];
const users = [
  { id: 1, name: "Hamza", role: "Admin", updated: "2023-08-01" },
  { id: 2, name: "Ahmed", role: "User", updated: "2023-07-15" },
  { id: 3, name: "Ali", role: "Admin", updated: "2023-09-02" },
  { id: 4, name: "Ayesha", role: "Moderator", updated: "2023-08-22" },
  { id: 5, name: "Fatima", role: "User", updated: "2023-07-30" },
  { id: 6, name: "Zain", role: "User", updated: "2023-08-12" },
  { id: 7, name: "Bilal", role: "User", updated: "2023-09-10" },
  { id: 8, name: "Yasir", role: "Admin", updated: "2023-07-20" },
  { id: 9, name: "Sarah", role: "Moderator", updated: "2023-08-25" },
  { id: 10, name: "Omar", role: "User", updated: "2023-07-18" },
];

const menuItems = [
  {
    label: "Dashboards",
  },
  {
    label: "Operations",
    subMenu: [
      { label: "Manpower" },
      { label: "Tools" },
      { label: "Daily Employee Duties" },
      { label: "Work Required" },
      { label: "Forman Reportings" },
      { label: "Subcontractor" },
      { label: "Transport" },
    ],
  },
  {
    label: "Projects",
    subMenu: [
      { label: "Project List" },
      { label: "Project Costs" },
      { label: "Bills" },
    ],
  },
  {
    label: "HR",
    subMenu: [
      { label: "HR Modules" },
      { label: "Employees" },
      { label: "Attendence" },
    ],
  },
  {
    label: "Finance",
    subMenu: [
      { label: "Accounts" },
      { label: "Projects Finances" },
      { label: "Financial Statements" },
      { label: "Payroll" },
      { label: "Finance Dashboard" },
    ],
  },
  {
    label: "Configuration",
    subMenu: [
      { label: "Vehicles" },
      { label: "Documents" },
      { label: "Materials" },
      { label: "Professions" },
    ],
  },
  {
    label: "User Roles Access",
  },
];

// Extract all labels from menuItems and subMenu
const moduleLabels = menuItems.flatMap((item) =>
  item.subMenu
    ? [item.label, ...item.subMenu.map((sub) => sub.label)]
    : [item.label]
);

const UserAccessManagement = () => {
  return (
    <Box>
      <GenericHeader
        title="Access Control"
        icon={<MdSwitchAccessShortcutAdd />}
      />
      <Tabs>
        <TabList>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Users
          </Tab>
          <Tab
            borderRadius={20}
            _selected={{
              color: "white",
              bg: "primary.500",
            }}
          >
            Roles
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <UsersSection />
          </TabPanel>
          <TabPanel>
            <RolesSection />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

// Users Section with GenericGrid
const UsersSection = () => {
  const [searchText, setSearchText] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      id: "user",
      header: "User",
      accessor: (row) => (
        <UserRowBox
          photo="https://via.placeholder.com/50"
          name={row.name}
          designation={row.role}
        />
      ),
    },
    {
      id: "role",
      header: "Role",
      accessor: (row) => row.role,
    },
    {
      id: "lastUpdated",
      header: "Last Updated",
      accessor: (row) => row.updated,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => col.id)
  );
  const [filters, setFilters] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });

  const handleVisibleColumnsChange = (newVisibleColumns) =>
    setVisibleColumns(newVisibleColumns);
  const handleRowClick = (rowData) => console.log("Row clicked:", rowData);

  return (
    <Box>
      <SearchInput
        placeholder="Search Users"
        value={searchText}
        onChange={handleSearchInputChange}
        onClear={handleClearSearch}
      />
      <GenericGrid
        columns={columns}
        data={filteredUsers}
        title="Users Table"
        visibleColumnsState={visibleColumns}
        filterState={filters}
        onVisibleColumnsChange={handleVisibleColumnsChange}
        onSort={setSortConfig}
        sortConfig={sortConfig}
        onRowClick={handleRowClick}
      />
    </Box>
  );
};

// Roles Section with Drawer and Role Cards
const RolesSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRole, setSelectedRole] = useState(null);
  const [permissions, setPermissions] = useState({
    addProject: false,
    updateProject: false,
    viewDetails: false,
    updateDocuments: false,
  });
  const [activePermissions, setActivePermissions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedModule, setSelectedModule] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const filteredRoles = roleNames.filter((role) =>
    role.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSwitchChange = (type) => {
    const updatedPermissions = { ...permissions, [type]: !permissions[type] };
    setPermissions(updatedPermissions);

    if (updatedPermissions[type]) {
      setActivePermissions((prev) => [...prev, type]);
    } else {
      setActivePermissions((prev) => prev.filter((perm) => perm !== type));
    }
  };

  const handleSaveRole = () => {
    // Logic to save the new role
    onClose();
  };

  const openDrawerWithRole = (role) => {
    setSelectedRole(role);
    onOpen();
  };

  return (
    <Box>
      <Flex justify="space-between" mb={4}>
        <SearchInput
          placeholder="Search Roles"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button colorScheme="orange" onClick={() => openDrawerWithRole(null)}>
          New Role
        </Button>
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {selectedRole ? `Edit Role: ${selectedRole}` : "Create New Role"}
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <Box>
                <Input
                  placeholder="Role Name"
                  size="md"
                  value={selectedRole || ""}
                  isReadOnly={!!selectedRole}
                />
              </Box>

              {/* Module Name */}
              <Box>
                <SelectBox
                  label="Module"
                  options={moduleLabels.map((label) => ({
                    value: label,
                    label: label,
                  }))}
                  value={selectedModule}
                  onChange={setSelectedModule}
                />
              </Box>

              {/* Permissions Table */}
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Permission</Th>
                    <Th>Enable</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {[
                    { label: "Add New Project", key: "addProject" },
                    { label: "Update Project", key: "updateProject" },
                    { label: "View Project Details", key: "viewDetails" },
                    { label: "Update Documents", key: "updateDocuments" },
                  ].map((perm) => (
                    <Tr key={perm.key}>
                      <Td>{perm.label}</Td>
                      <Td>
                        <Switch
                          isChecked={permissions[perm.key]}
                          onChange={() => handleSwitchChange(perm.key)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              {/* Active Permissions Tags */}
              {activePermissions.length > 0 && (
                <Box>
                  <Text fontSize="sm" color="gray.500" mb={1}>
                    Active Permissions:
                  </Text>
                  {activePermissions.map((perm) => (
                    <Tag key={perm} colorScheme="blue" mr={1}>
                      <TagLabel>{perm}</TagLabel>
                    </Tag>
                  ))}
                </Box>
              )}

              <SelectBox
                label="Assign Users"
                options={users.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))}
                isMulti
              />

              <Button colorScheme="orange" mt={4} onClick={handleSaveRole}>
                Save Role
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Role Cards in Flex Layout */}
      <Flex wrap="wrap" justify="space-between" mt={8}>
        {filteredRoles.map((role, index) => (
          <RoleCard
            key={index}
            roleName={role}
            onCardClick={() => openDrawerWithRole(role)}
          />
        ))}
      </Flex>
    </Box>
  );
};

// Role Card Component
const RoleCard = ({ roleName, onCardClick }) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      boxShadow="md"
      maxW="300px"
      m={4}
      flexBasis="30%"
      backgroundColor="white"
      borderColor="gray.300"
      transition="0.3s"
      _hover={{
        boxShadow: "lg",
        transform: "scale(1.02)",
        borderColor: "blue.500",
      }}
      cursor="pointer"
      onClick={onCardClick}
    >
      <Text fontWeight="bold" fontSize="lg" mb={2} textDecoration="underline">
        {roleName}
      </Text>

      <Box mb={2}>
        <Text fontSize="sm" color="gray.500" mb={1}>
          Permissions:
        </Text>
        <Tag colorScheme="green" mr={1}>
          <TagLabel>Add Project</TagLabel>
        </Tag>
        <Tag colorScheme="yellow" mr={1}>
          <TagLabel>Update Docs</TagLabel>
        </Tag>
        <Tag colorScheme="blue" mr={1}>
          <TagLabel>View Details</TagLabel>
        </Tag>
      </Box>

      <Box>
        <Text fontSize="sm" color="gray.500" mb={1}>
          Assignees:
        </Text>
        <Flex gap={2}>
          <UserRowBox
            photo="https://via.placeholder.com/20"
            name="Hamza"
            designation=""
          />
          <UserRowBox
            photo="https://via.placeholder.com/20"
            name="Ayesha"
            designation=""
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default UserAccessManagement;
