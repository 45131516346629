import React from "react";
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Image,
} from "@chakra-ui/react";
import Logo from "../../images/Logo-Header.png";

const PrintableInvoice = React.forwardRef(({ billData }, ref) => {
  // Use optional chaining and default empty arrays to avoid errors
  const items = billData.items || [];
  const creditPayments = billData.creditPayments || [];

  // Calculate total credit amount safely
  const totalCreditAmount = creditPayments.reduce(
    (sum, payment) => sum + payment.amount,
    0
  );

  return (
    <Box
      ref={ref}
      width="100%"
      maxWidth="800px"
      margin="auto"
      backgroundColor="white"
      p={8}
      boxShadow="lg"
      borderRadius="md"
      fontFamily="Arial, sans-serif"
    >
      {/* Company Header */}
      <Flex justify="space-between" align="center" mb={8}>
        <Box>
          <Image src={Logo} alt="Company Logo" boxSize="80px" />
          <Text fontSize="2xl" fontWeight="bold" mt={2} color="teal.500">
            Your Company Name
          </Text>
          <Text fontSize="sm">123 Street Address, City, State, ZIP</Text>
          <Text fontSize="sm">Phone: (123) 456-7890</Text>
        </Box>
        <Box textAlign="right">
          <Text fontSize="3xl" fontWeight="bold" color="gray.700">
            INVOICE
          </Text>
          <Text fontSize="sm">Date: {billData.date}</Text>
          <Text fontSize="sm">Invoice #: {billData.invoiceNo}</Text>
        </Box>
      </Flex>

      <Divider mb={8} />

      {/* Bill To / Ship To Section */}
      <Flex justify="space-between" mb={8}>
        <Box>
          <Text fontSize="lg" fontWeight="bold" color="gray.600">
            Bill To:
          </Text>
          <Text fontSize="sm">{billData.buyer}</Text>
          <Text fontSize="sm">Buyer Company Name</Text>
          <Text fontSize="sm">Buyer Address</Text>
        </Box>
        <Box textAlign="right">
          <Text fontSize="lg" fontWeight="bold" color="gray.600">
            Ship To:
          </Text>
          <Text fontSize="sm">{billData.projectSite}</Text>
          <Text fontSize="sm">Project Address</Text>
        </Box>
      </Flex>

      {/* Itemized Table */}
      <Table variant="simple" mb={8}>
        <Thead bg="gray.100">
          <Tr>
            <Th>S.No</Th>
            <Th>Item</Th>
            <Th>Quantity</Th>
            <Th>Unit Rate</Th>
            <Th>VAT</Th>
            <Th>Total Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>{item.item}</Td>
              <Td>{item.quantity}</Td>
              <Td>{item.unitRate.toFixed(2)}</Td>
              <Td>{item.vat.toFixed(2)}</Td>
              <Td>{item.totalAmount.toFixed(2)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Summary Section */}
      <Box textAlign="right" mb={8}>
        <Text fontSize="sm">
          <strong>Subtotal:</strong>{" "}
          {items.reduce((sum, item) => sum + item.totalAmount, 0).toFixed(2)}
        </Text>
        <Text fontSize="sm">
          <strong>VAT:</strong>{" "}
          {items.reduce((sum, item) => sum + item.vat, 0).toFixed(2)}
        </Text>
        <Text fontSize="sm">
          <strong>Transport Cost:</strong> {billData.transportCost.toFixed(2)}
        </Text>
        <Text fontSize="sm">
          <strong>Discount:</strong> {billData.discount.toFixed(2)}
        </Text>
        <Divider my={2} />
        <Text fontSize="2xl" fontWeight="bold" color="teal.500">
          Grand Total: {billData.amount.toFixed(2)}
        </Text>
      </Box>

      {/* Credit Section */}
      {creditPayments.length > 0 && (
        <>
          <Divider mb={8} />
          <Box mb={8}>
            <Text fontSize="lg" fontWeight="bold" color="gray.600" mb={4}>
              Credit Payment Schedule
            </Text>
            <Table variant="simple">
              <Thead bg="gray.100">
                <Tr>
                  <Th>Payment Date</Th>
                  <Th>Amount</Th>
                </Tr>
              </Thead>
              <Tbody>
                {creditPayments.map((payment, index) => (
                  <Tr key={index}>
                    <Td>{payment.date}</Td>
                    <Td>{payment.amount.toFixed(2)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Text textAlign="right" fontSize="lg" fontWeight="bold" mt={4}>
              Total Credit Amount: {totalCreditAmount.toFixed(2)}
            </Text>
          </Box>
        </>
      )}

      {/* Footer */}
      <Box textAlign="center" mt={10} p={5} bg="gray.50" borderRadius="md">
        <Text fontSize="sm" color="gray.600">
          Thank you for your business! If you have any questions about this
          invoice, please contact us at (123) 456-7890.
        </Text>
        <Text fontSize="sm" color="gray.600">
          Payment is due within 30 days. Late payments may be subject to
          additional fees.
        </Text>
        <Text fontSize="sm" color="gray.600">
          <strong>Your Company Name</strong> | www.yourcompanywebsite.com |
          info@yourcompanyemail.com
        </Text>
      </Box>
    </Box>
  );
});

export default PrintableInvoice;
