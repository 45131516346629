import React from "react";
import Zoom from "react-medium-image-zoom";
import { Box, IconButton, Image, Flex } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import "react-medium-image-zoom/dist/styles.css"; // This only needs to be imported once in your app

const ImageGallery = ({ images, width, handleDelete }) => {
  return (
    <Box
      width={`${width}px`}
      height="100%"
      overflowX="auto"
      whiteSpace="nowrap"
    >
      <Flex>
        {images.map((img, index) => (
          <Box key={index} position="relative" mr={2}>
            <Zoom>
              <Image
                src={img}
                height="40px"
                width="auto"
                maxWidth="100px"
                maxHeight="100px"
                cursor="pointer"
                objectFit="contain"
                onClick={(e) => e.stopPropagation()} // Prevent triggering the parent onClick
              />
            </Zoom>
            {handleDelete && (
              <IconButton
                icon={<CloseIcon />}
                size="xs"
                position="absolute"
                top="0"
                right="0"
                onClick={() => handleDelete(index)}
                variant="outline"
                colorScheme="red"
              />
            )}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default ImageGallery;
