import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Image,
  VStack,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import constructionImage from "../images/under-construction.png"; // Replace with your image path

const UnderConstruction = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/hr/wheel");
  };

  return (
    <Container centerContent>
      <VStack mt={2}>
        <Heading as="h1" size="xx-large" textAlign="center">
          Page Under Construction
        </Heading>
        <Text fontSize="lg" color="gray.500" textAlign="center">
          We're working hard to bring you this page. Please check back soon!
        </Text>
        <Box boxSize="sm">
          <Image src={constructionImage} alt="Under Construction" />
        </Box>
        <Button colorScheme="primary" size="lg" onClick={handleGoBack}>
          Go Back Home
        </Button>
      </VStack>
    </Container>
  );
};

export default UnderConstruction;
