import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";

const AddAccountDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newAccount, setNewAccount] = useState({
    name: "",
    type: "",
    balance: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAccount((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddAccount = () => {
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="primary" mb={4}>
        Add Account
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create a new account</DrawerHeader>

          <DrawerBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={newAccount.name}
                onChange={handleInputChange}
                placeholder="Account Name"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Type</FormLabel>
              <Input
                name="type"
                value={newAccount.type}
                onChange={handleInputChange}
                placeholder="Account Type"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Balance</FormLabel>
              <Input
                name="balance"
                value={newAccount.balance}
                onChange={handleInputChange}
                placeholder="Account Balance"
                type="number"
              />
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleAddAccount}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddAccountDrawer;
