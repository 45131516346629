import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Textarea,
  Select,
  Flex,
} from "@chakra-ui/react";
import GenericHeader from "../../commons/GenericHeader";
import {
  addFine,
  changeDriver,
  addMaintenance,
  selectVehicleBySno,
} from "../../store/vehiclesSlice";

const VehicleDetails = () => {
  const { sno } = useParams();
  const vehicle = useSelector((state) =>
    selectVehicleBySno(state, parseInt(sno))
  );
  const dispatch = useDispatch();

  const [drawerType, setDrawerType] = useState(null);
  const [formData, setFormData] = useState({});

  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setFormData({});
  };

  const handleCloseDrawer = () => {
    setDrawerType(null);
  };

  const handleSave = () => {
    switch (drawerType) {
      case "fine":
        dispatch(addFine({ sno: vehicle.sno, fine: formData }));
        break;
      case "driver":
        dispatch(
          changeDriver({ sno: vehicle.sno, newDriver: formData.newDriver })
        );
        break;
      case "maintenance":
        dispatch(
          addMaintenance({ sno: vehicle.sno, maintenance: formData.work })
        );
        break;
      default:
        break;
    }
    handleCloseDrawer();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <GenericHeader
          title={`Vehicle Details: ${vehicle.plateNo}`}
          back={"/vehicles"}
        />
        <Flex>
          <Button
            onClick={() => handleOpenDrawer("fine")}
            colorScheme="teal"
            mr={2}
          >
            Add Fine
          </Button>
          <Button
            onClick={() => handleOpenDrawer("driver")}
            colorScheme="blue"
            mr={2}
          >
            Change Driver
          </Button>
          <Button
            onClick={() => handleOpenDrawer("maintenance")}
            colorScheme="purple"
          >
            Add Maintenance
          </Button>
        </Flex>
      </Box>
      <Box p={4} borderWidth="1px" borderRadius="md">
        <Box as="h2" mb={4}>
          Details
        </Box>
        <Table variant="simple" background={"white"} borderRadius="md">
          <Tbody>
            <Tr>
              <Td>SNo</Td>
              <Td>{vehicle.sno}</Td>
            </Tr>
            <Tr>
              <Td>Type</Td>
              <Td>{vehicle.type}</Td>
            </Tr>
            <Tr>
              <Td>Plate No</Td>
              <Td>{vehicle.plateNo}</Td>
            </Tr>
            <Tr>
              <Td>Mulkya Expiry</Td>
              <Td>{vehicle.mulkyaExpiry}</Td>
            </Tr>
            <Tr>
              <Td>Insurance Expiry</Td>
              <Td>{vehicle.insuranceExpiry}</Td>
            </Tr>
            <Tr>
              <Td>Maintenance Cost</Td>
              <Td>{vehicle.maintenanceCost}</Td>
            </Tr>
            <Tr>
              <Td>Purchase Price</Td>
              <Td>{vehicle.purchasePrice}</Td>
            </Tr>
            <Tr>
              <Td>Mulkya Renewal Cost</Td>
              <Td>{vehicle.mulkyaRenewalCost}</Td>
            </Tr>
            <Tr>
              <Td>Driver Name</Td>
              <Td>{vehicle.driverName}</Td>
            </Tr>
            <Tr>
              <Td>Date Handover</Td>
              <Td>{vehicle.dateHandover}</Td>
            </Tr>
            <Tr>
              <Td>Date Return</Td>
              <Td>{vehicle.dateReturn}</Td>
            </Tr>
            <Tr>
              <Td>No of Time Fines</Td>
              <Td>{vehicle.finesCount}</Td>
            </Tr>
            <Tr>
              <Td>TODO Maintenance</Td>
              <Td>{vehicle.todoMaintenance}</Td>
            </Tr>
            <Tr>
              <Td>Total Cost</Td>
              <Td>{vehicle.totalCost}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box p={4} borderWidth="1px" borderRadius="md" mt={4}>
        <Box as="h2" mb={4}>
          Fines
        </Box>
        <Table variant="simple" borderRadius="md" background={"white"}>
          <Thead>
            <Tr>
              <Th>Fine Amount</Th>
              <Th>Date</Th>
              <Th>Reason</Th>
            </Tr>
          </Thead>
          <Tbody>
            {vehicle.fines.map((fine, index) => (
              <Tr key={index}>
                <Td>{fine.amount}</Td>
                <Td>{fine.date}</Td>
                <Td>{fine.reason}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Box p={4} borderWidth="1px" borderRadius="md" mt={4}>
        <Box as="h2" mb={4}>
          Driver History
        </Box>
        <Table variant="simple" borderRadius="md" background={"white"}>
          <Thead>
            <Tr>
              <Th>Driver Name</Th>
              <Th>Date Handover</Th>
              <Th>Date Return</Th>
            </Tr>
          </Thead>
          <Tbody>
            {vehicle.driverHistory.map((history, index) => (
              <Tr key={index}>
                <Td>{history.driverName}</Td>
                <Td>{history.dateHandover}</Td>
                <Td>{history.dateReturn}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Drawer
        isOpen={Boolean(drawerType)}
        placement="right"
        onClose={handleCloseDrawer}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {drawerType === "fine" && "Add Fine"}
            {drawerType === "driver" && "Change Driver"}
            {drawerType === "maintenance" && "Add Maintenance"}
          </DrawerHeader>
          <DrawerBody>
            {drawerType === "fine" && (
              <>
                <Input
                  placeholder="Date"
                  name="date"
                  value={
                    formData.date || new Date().toISOString().split("T")[0]
                  }
                  onChange={handleChange}
                  mb={4}
                />
                <Input
                  placeholder="Amount"
                  name="amount"
                  value={formData.amount || ""}
                  onChange={handleChange}
                  mb={4}
                />
                <Textarea
                  placeholder="Reason"
                  name="reason"
                  value={formData.reason || ""}
                  onChange={handleChange}
                  mb={4}
                />
              </>
            )}
            {drawerType === "driver" && (
              <>
                <Input
                  placeholder="Current Driver"
                  name="currentDriver"
                  value={vehicle.driverName}
                  isReadOnly
                  mb={4}
                />
                <Select
                  placeholder="New Driver"
                  name="newDriver"
                  value={formData.newDriver || ""}
                  onChange={handleChange}
                  mb={4}
                >
                  <option value="Driver 1">Driver 1</option>
                  <option value="Driver 2">Driver 2</option>
                </Select>
                <Input
                  placeholder="Date Handover"
                  name="dateHandover"
                  value={
                    formData.dateHandover ||
                    new Date().toISOString().split("T")[0]
                  }
                  onChange={handleChange}
                  mb={4}
                />
              </>
            )}
            {drawerType === "maintenance" && (
              <>
                <Input
                  placeholder="Date"
                  name="date"
                  value={
                    formData.date || new Date().toISOString().split("T")[0]
                  }
                  onChange={handleChange}
                  mb={4}
                />
                <Textarea
                  placeholder="Work"
                  name="work"
                  value={formData.work || ""}
                  onChange={handleChange}
                  mb={4}
                />
              </>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={handleCloseDrawer}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default VehicleDetails;
