import React, { useState, useMemo, useEffect } from "react";
import { Box, Checkbox, Flex, Button, useDisclosure } from "@chakra-ui/react";
import {
  ArrowUpDownIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  SearchIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import ReactPaginate from "react-paginate";
import "../../styles/GenericGrid.css";

const GenericGrid = ({
  columns = [],
  data = [],
  title,
  maxHeight,
  visibleColumnsState,
  filterState,
  onVisibleColumnsChange,
  onSort,
  sortConfig,
  onRowClick,
  onSelectedRowsChange,
}) => {
  console.log("data", data);
  console.log("columns", columns);
  const [selectedRows, setSelectedRows] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(visibleColumnsState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setVisibleColumns(visibleColumnsState);
  }, [visibleColumnsState]);

  useEffect(() => {
    setCurrentPage(0);
  }, [data]);

  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange(selectedRows);
    }
  }, [selectedRows, onSelectedRowsChange]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(data.map((item) => item.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleColumnVisibility = (column) => {
    const updatedColumns = visibleColumns.includes(column)
      ? visibleColumns.filter((col) => col !== column)
      : [...visibleColumns, column];
    setVisibleColumns(updatedColumns);
    onVisibleColumnsChange(updatedColumns);
  };

  const handleSort = (column) => {
    if (onSort) {
      onSort(column);
    }
  };

  const getSortIcon = (column) => {
    if (!sortConfig || sortConfig.key !== column) {
      return <ArrowUpDownIcon />;
    }
    if (sortConfig.direction === "ascending") {
      return <ArrowUpIcon />;
    }
    return <ArrowDownIcon />;
  };

  const getFilterIcon = (column) => {
    console.log("GetFilterICons");
    console.log("column", column);
    console.log("filterState", filterState);
    if (filterState[column]) {
      return <SearchIcon ml={2} color="blue.500" />;
    }
    return null;
  };

  const paginatedData = useMemo(() => {
    const startIndex = currentPage * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  }, [data, currentPage, itemsPerPage]);

  return (
    <Box>
      <Box p={4}>
        <Box maxW="100%" overflowX="auto">
          <Box borderRadius="md" minWidth="max-content">
            <Box position="relative">
              <Box position="sticky" top={0} zIndex={6} bg="gray.100">
                <Flex align="center">
                  <Checkbox
                    isChecked={selectedRows.length === data.length}
                    onChange={handleSelectAll}
                    mr="4"
                  />
                  <Flex flex="1" alignItems={"center"} padding={"0 10px"}>
                    {columns
                      .filter((col) => visibleColumns.includes(col.id))
                      .map((col) => (
                        <Box
                          key={col.id}
                          color="gray"
                          flex="1"
                          minWidth={col.minWidth}
                          maxWidth={col.maxWidth}
                          margin={"0 10px"}
                          onClick={() => handleSort(col.id)}
                          cursor="pointer"
                          fontWeight={"bold"}
                          display="flex"
                          alignItems="center"
                          whiteSpace="nowrap"
                        >
                          {col.header}
                          {getSortIcon(col.id)}
                          {getFilterIcon(col.id)}
                        </Box>
                      ))}
                  </Flex>
                </Flex>
              </Box>
              <Box overflowY="auto" maxHeight={maxHeight}>
                {paginatedData.length === 0 ? (
                  <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    height="200px"
                  >
                    <WarningIcon boxSize="40px" color="gray.500" />
                    <Box mt={2} fontSize="lg" color="gray.500">
                      No data to display
                    </Box>
                  </Flex>
                ) : (
                  paginatedData.map((row) => (
                    <Flex
                      key={row.id}
                      align="center"
                      width="100%"
                      alignItems={"center"}
                      cursor={onRowClick ? "pointer" : "default"}
                    >
                      <Checkbox
                        isChecked={selectedRows.includes(row.id)}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleRowSelect(row.id);
                        }}
                        mr="4"
                      />
                      <Flex
                        flex="1"
                        p="10px"
                        bg="#fff"
                        color="black"
                        borderRadius="10px"
                        margin={"10px 0"}
                        align="left"
                        alignItems={"center"}
                        onClick={() => onRowClick && onRowClick(row)}
                      >
                        {columns
                          .filter((col) => visibleColumns.includes(col.id))
                          .map((col) => (
                            <Box
                              key={col.id}
                              flex="1"
                              minWidth={col.minWidth}
                              maxWidth={col.maxWidth}
                              margin={"0 10px"}
                              fontWeight={"bold"}
                              textAlign="left"
                              onClick={(e) => col.render && e.stopPropagation()}
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {col.render ? col.render(row) : col.accessor(row)}
                            </Box>
                          ))}
                      </Flex>
                    </Flex>
                  ))
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={4}>
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(data.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(data) => setCurrentPage(data.selected)}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </Box>
    </Box>
  );
};

export default GenericGrid;
