import React, { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
} from "@chakra-ui/react";

import { FaDollarSign } from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import DashboardBox from "../../../commons/DashboardBox";
import GenericGrid from "../../Procurement/GenericGrid";
import GenericHeader from "../../../commons/GenericHeader";

const ProjectFinancialManagement = () => {
  // Dummy data
  const projectData = [
    {
      id: 1,
      name: "Project A",
      budget: 100000,
      spent: 80000,
      remaining: 20000,
    },
    {
      id: 2,
      name: "Project B",
      budget: 200000,
      spent: 150000,
      remaining: 50000,
    },
  ];

  const columns = [
    { id: "id", header: "ID", accessor: (row) => row.id },
    { id: "name", header: "Name", accessor: (row) => row.name },
    {
      id: "budget",
      header: "Budget",
      accessor: (row) => `AED ${row.budget.toLocaleString()}`,
    },
    {
      id: "spent",
      header: "Spent",
      accessor: (row) => `AED ${row.spent.toLocaleString()}`,
    },
    {
      id: "remaining",
      header: "Remaining",
      accessor: (row) => `AED ${row.remaining.toLocaleString()}`,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => col.id)
  );

  const budgetVsSpentChart = {
    labels: projectData.map((p) => p.name),
    datasets: [
      {
        label: "Budget",
        data: projectData.map((p) => p.budget),
        backgroundColor: "#68D391",
      },
      {
        label: "Spent",
        data: projectData.map((p) => p.spent),
        backgroundColor: "#FC8181",
      },
    ],
  };

  return (
    <Box p={5}>
      <GenericHeader title="Project-Based Financial Management" />

      <Tabs>
        <TabList>
          <Tab>Project Budgeting</Tab>
          <Tab>Cost Management</Tab>
          <Tab>Project Profitability</Tab>
        </TabList>

        <TabPanels>
          {/* Project Budgeting */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaDollarSign}
                name="Total Budget"
                value="300,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Total Spent"
                value="230,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Total Remaining"
                value="70,000"
                backgroundColor="#4299E1"
                type="money"
              />
            </SimpleGrid>
            <Box mt={8}>
              <Bar data={budgetVsSpentChart} />
            </Box>
          </TabPanel>

          {/* Cost Management */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaDollarSign}
                name="Labor Cost"
                value="150,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Material Cost"
                value="50,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Overhead Cost"
                value="30,000"
                backgroundColor="#4299E1"
                type="money"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={projectData}
                title="Cost Management"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>

          {/* Project Profitability */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaDollarSign}
                name="Project A Profit"
                value="20,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaDollarSign}
                name="Project B Profit"
                value="50,000"
                backgroundColor="#FC8181"
                type="money"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={projectData}
                title="Project Profitability"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ProjectFinancialManagement;
