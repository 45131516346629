import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  VStack,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  Checkbox,
} from "@chakra-ui/react";
import {
  FaProjectDiagram,
  FaFileAlt,
  FaUsers,
  FaDollarSign,
  FaTruck,
  FaTools,
  FaBuilding,
} from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import DashboardBox from "../../commons/DashboardBox";
import GenericHeader from "../../commons/GenericHeader";
import { RiDashboard3Line } from "react-icons/ri";

const Dashboard = () => {
  const lightColors = [
    "#e0e0e0",
    "#d6eaf8",
    "#cce7ff",
    "#e0e0e0",
    "#d4ffcc",
    "#ffffcc",
    "#ffd6e8",
    "#e5e5c0",
    "#e0fff0",
  ];

  const [todos, setTodos] = useState([
    {
      id: 1,
      text: "Complete project proposal",
      date: "2024-06-30",
      completed: false,
    },
    {
      id: 2,
      text: "Prepare for client meeting",
      date: "2024-07-02",
      completed: true,
    },
    {
      id: 3,
      text: "Review financial reports",
      date: "2024-07-01",
      completed: false,
    },
    {
      id: 4,
      text: "Update project milestones",
      date: "2024-06-29",
      completed: false,
    },
  ]);

  const toggleComplete = (id) => {
    setTodos((prevTodos) =>
      prevTodos.map((todo) =>
        todo.id === id ? { ...todo, completed: !todo.completed } : todo
      )
    );
  };

  const stats = [
    { icon: FaProjectDiagram, label: "Total Active Projects", value: "34" },
    { icon: FaProjectDiagram, label: "Total Completed Projects", value: "120" },
    { icon: FaFileAlt, label: "Quotations", value: "56" },
    { icon: FaUsers, label: "Total Employees", value: "250" },
    { icon: FaDollarSign, label: "Gross Project Profits", value: "$1,200,000" },
    { icon: FaDollarSign, label: "Expense", value: "$800,000" },
    { icon: FaTruck, label: "Total Vehicles", value: "50" },
    { icon: FaTools, label: "Total Materials", value: "150" },
    { icon: FaBuilding, label: "Subcontractor Projects", value: "20" },
  ];

  const projectForecastingData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Projects",
        data: [3, 2, 2, 5, 6, 5, 7, 6, 8, 7, 10, 9],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const billPurchasesData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Bill Purchases",
        data: [
          2000, 2500, 1500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500,
          7000,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const subcontractorProjectsData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Subcontractor Projects",
        data: [1, 3, 2, 4, 3, 5, 4, 6, 5, 7, 6, 8],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const duties = [
    { sno: 1, forman: "Akram", site: "Site 12", date: "2024-06-29" },
    { sno: 2, forman: "Asghar", site: "Site 5", date: "2024-06-28" },
    // Add more active duties as needed
  ];

  return (
    <Box>
      <GenericHeader title="Dashboard" icon={<RiDashboard3Line />} />
      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
        {stats.map((stat, index) => (
          <DashboardBox
            key={index}
            icon={stat.icon}
            name={stat.label}
            value={stat.value}
            backgroundColor={lightColors[index % lightColors.length]}
          />
        ))}
      </SimpleGrid>
      <SimpleGrid columns={[1, 2]} spacing={4} mt={4}>
        <Box p={4} background={"white"} borderWidth="1px" borderRadius="md">
          <Heading size="md" mb={4}>
            Project Forecasting
          </Heading>
          <Bar data={projectForecastingData} />
        </Box>
        <Box p={4} background={"white"} borderWidth="1px" borderRadius="md">
          <Heading size="md" mb={4}>
            Active Shift Duties
          </Heading>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>S.No</Th>
                <Th>Forman Name</Th>
                <Th>Site</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {duties.map((duty) => (
                <Tr key={duty.sno}>
                  <Td>{duty.sno}</Td>
                  <Td>{duty.forman}</Td>
                  <Td>{duty.site}</Td>
                  <Td>{duty.date}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </SimpleGrid>
      <Box
        p={4}
        background={"white"}
        borderWidth="1px"
        borderRadius="md"
        mt={4}
      >
        <Heading size="md" mb={4}>
          Monthly Bill Purchases
        </Heading>
        <Bar data={billPurchasesData} />
      </Box>
      <Box
        p={4}
        background={"white"}
        borderWidth="1px"
        borderRadius="md"
        mt={4}
      >
        <Heading size="md" mb={4}>
          Monthly Subcontractor Projects
        </Heading>
        <Bar data={subcontractorProjectsData} />
      </Box>
      <Box
        p={4}
        background={"white"}
        borderWidth="1px"
        borderRadius="md"
        mt={4}
      >
        <Heading size="md" mb={4}>
          To-Do List
        </Heading>
        <Box mt={4}>
          {todos.map((todo) => (
            <Box
              key={todo.id}
              textDecoration={todo.completed ? "line-through" : "none"}
              cursor="pointer"
              onClick={() => toggleComplete(todo.id)}
              _hover={{ textDecoration: todo.completed ? "none" : "underline" }}
              display="flex"
              alignItems="center"
            >
              <Checkbox
                isChecked={todo.completed}
                onChange={() => toggleComplete(todo.id)}
                mr={2}
              />
              <span>
                {todo.text} - {todo.date}
              </span>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
