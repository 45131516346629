import React, { useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button, Box } from "@chakra-ui/react";
import CustomSelectEditor from "../../Transport/CustomSelectEditor";

const LedgerList = () => {
  const gridRef = useRef(null); // Reference to the AgGridReact component

  const [rowData, setRowData] = useState([
    {
      date: "2024-01-01",
      account: "Cash",
      offsetAccount: "Equity",
      debit: 1000,
      credit: 0,
      description: "Initial deposit",
    },
    {
      date: "2024-01-02",
      account: "Accounts Payable",
      offsetAccount: "Cash",
      debit: 0,
      credit: 500,
      description: "Payment to supplier",
    },
    // Add more rows as needed
  ]);

  const [rowId, setRowId] = useState(rowData.length + 1);

  const columnDefs = [
    {
      headerName: "SNo",
      valueGetter: (params) => params.node.rowIndex + 1,
      width: 80,
    },
    {
      headerName: "DATE",
      field: "date",
      editable: true,
      cellEditor: "agDateStringCellEditor",
      width: 150,
    },
    {
      headerName: "ACCOUNT",
      field: "account",
      editable: true,
      cellEditor: CustomSelectEditor,
      cellEditorPopup: true,
      cellEditorParams: {
        selectOptions: [
          { label: "Cash", value: "Cash" },
          { label: "Accounts Payable", value: "Accounts Payable" },
          { label: "Accounts Receivable", value: "Accounts Receivable" },
          { label: "Revenue", value: "Revenue" },
          { label: "Expense", value: "Expense" },
          { label: "Equity", value: "Equity" },
        ],
      },
      width: 200,
    },
    {
      headerName: "OFFSET ACCOUNT",
      field: "offsetAccount",
      editable: true,
      cellEditor: CustomSelectEditor,
      cellEditorPopup: true,
      cellEditorParams: {
        selectOptions: [
          { label: "Cash", value: "Cash" },
          { label: "Accounts Payable", value: "Accounts Payable" },
          { label: "Accounts Receivable", value: "Accounts Receivable" },
          { label: "Revenue", value: "Revenue" },
          { label: "Expense", value: "Expense" },
          { label: "Equity", value: "Equity" },
        ],
      },
      width: 200,
    },
    {
      headerName: "DEBIT",
      field: "debit",
      editable: true,
      valueParser: "Number(newValue)",
      width: 150,
    },
    {
      headerName: "CREDIT",
      field: "credit",
      editable: true,
      valueParser: "Number(newValue)",
      width: 150,
    },
    {
      headerName: "DESCRIPTION",
      field: "description",
      editable: true,
      flex: 1,
    },
  ];

  const addRow = () => {
    const newRow = {
      date: "",
      account: "Cash",
      offsetAccount: "Equity",
      debit: 0,
      credit: 0,
      description: "",
    };
    setRowData([...rowData, newRow]);
    setRowId(rowId + 1);
  };

  return (
    <div>
      <Box mb={4}>
        <Button onClick={addRow} colorScheme="primary" mr={4}>
          Add Row
        </Button>
      </Box>
      <div
        className="ag-theme-alpine"
        style={{ height: "70vh", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          domLayout="autoHeight"
          defaultColDef={{ filter: true, resizable: true }}
          animateRows={true}
          popupParent={document.body}
        />
      </div>
    </div>
  );
};

export default LedgerList;
