import React, { useState } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  Flex,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GenericHeader from "../../commons/GenericHeader";
import { LuListTodo } from "react-icons/lu";

const Todo = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    dueDate: "",
    priority: "Low",
  });

  const [columns, setColumns] = useState({
    todo: {
      name: "Add New Todo",
      items: [],
    },
    inProgress: {
      name: "In Progress",
      items: [],
    },
    completed: {
      name: "Completed",
      items: [],
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addTask = () => {
    const newTask = {
      id: `${new Date().getTime()}`,
      title: formData.title,
      description: formData.description,
      dueDate: formData.dueDate,
      priority: formData.priority,
    };

    const updatedColumns = {
      ...columns,
      todo: {
        ...columns.todo,
        items: [...columns.todo.items, newTask],
      },
    };

    setColumns(updatedColumns);
    onClose(); // Close the drawer after adding a task
    setFormData({ title: "", description: "", dueDate: "", priority: "Low" }); // Reset the form
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      sourceItems.splice(destination.index, 0, removed);
      const newColumn = {
        ...sourceColumn,
        items: sourceItems,
      };
      setColumns({ ...columns, [source.droppableId]: newColumn });
    } else {
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    }
  };

  return (
    <Box>
      <Flex justifyContent="space-between" mb={6}>
        <GenericHeader title={"Todos"} icon={<LuListTodo />} />
        <Button leftIcon={<AddIcon />} colorScheme="primary" onClick={onOpen}>
          Add New Task
        </Button>
      </Flex>

      {/* Trello-like Columns */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex justify="space-between">
          {Object.entries(columns).map(([columnId, column], index) => (
            <Droppable key={columnId} droppableId={columnId}>
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  p={4}
                  bg="gray.100"
                  borderRadius="md"
                  w="32%"
                  border={"1px solid gray"}
                >
                  <Text fontSize="xl" fontWeight="bold" mb={4}>
                    {column.name}
                  </Text>
                  <VStack spacing={4}>
                    {column.items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            p={4}
                            bg="white"
                            borderRadius="md"
                            boxShadow="md"
                            w="100%"
                          >
                            <Text fontSize="lg" fontWeight="bold">
                              {item.title}
                            </Text>
                            <Text>{item.description}</Text>
                            <Text color="gray.500" fontSize="sm">
                              Due: {item.dueDate}
                            </Text>
                            <Text color="blue.500" fontSize="sm">
                              Priority: {item.priority}
                            </Text>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </VStack>
                </Box>
              )}
            </Droppable>
          ))}
        </Flex>
      </DragDropContext>

      {/* Drawer for Adding New Todo */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Create a new task</DrawerHeader>

          <DrawerBody>
            <FormControl isRequired mb={4}>
              <FormLabel>Title</FormLabel>
              <Input
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Enter task title"
              />
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter task description"
              />
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>Due Date</FormLabel>
              <Input
                type="date"
                name="dueDate"
                value={formData.dueDate}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl isRequired mb={4}>
              <FormLabel>Priority</FormLabel>
              <Select
                name="priority"
                value={formData.priority}
                onChange={handleInputChange}
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Select>
            </FormControl>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={addTask}>
              Add Task
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Todo;
