import React from "react";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { IoAddCircleOutline, IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const GenericHeader = ({
  title,
  searchPlaceholder,
  onSearch,
  icon,
  to,
  back,
  addButtonName,
}) => {
  const navigate = useNavigate();

  return (
    <Box width={"100%"}>
      <Flex gap={5} align="center" mb="4" justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={5}>
          {back && (
            <IoArrowBackCircleOutline
              onClick={() => navigate(back)}
              color="#db8700"
              size={50}
              cursor={"pointer"}
            />
          )}
          {title && (
            <Heading as="h2" size="lg">
              <Flex gap={5} align={"center"}>
                <Text>{title}</Text>
                {icon && icon}
              </Flex>
            </Heading>
          )}
        </Flex>
        {to && (
          <button className="btn-primary" onClick={() => navigate(to)}>
            <IoAddCircleOutline />
            Add {addButtonName ? addButtonName : title}
          </button>
        )}
      </Flex>
      {searchPlaceholder && onSearch && (
        <Flex justifyContent={"space-between"} marginBottom={10}>
          <Flex alignItems={"center"} gap={10} flex={1}>
            {searchPlaceholder && onSearch && (
              <input
                type="text"
                placeholder={searchPlaceholder}
                onChange={onSearch}
                className="search-input"
              />
            )}
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default GenericHeader;
