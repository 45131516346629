import React, { useRef, useEffect } from "react";
import { OrgChart } from "d3-org-chart";
import * as d3 from "d3";

const EmployeeHierarchy = () => {
  const chartRef = useRef();

  const data = [
    {
      id: 1,
      name: "Ahmed Khan",
      title: "CEO",
      parentId: null,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Greenfort Construction",
    },
    {
      id: 2,
      name: "Fatima Sheikh",
      title: "Foreman",
      parentId: 1,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site A",
    },
    {
      id: 3,
      name: "Hassan Ali",
      title: "Office Boy",
      parentId: 2,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site A",
    },
    {
      id: 4,
      name: "Ayesha Siddiqui",
      title: "Worker",
      parentId: 3,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site A",
    },
    {
      id: 5,
      name: "Bilal Ahmed",
      title: "Worker",
      parentId: 3,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site A",
    },
    {
      id: 6,
      name: "Mariam Usman",
      title: "Worker",
      parentId: 2,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site A",
    },
    {
      id: 7,
      name: "Zainab Khan",
      title: "Worker",
      parentId: 2,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site A",
    },
    {
      id: 8,
      name: "Yusuf Farooq",
      title: "Office Manager",
      parentId: 1,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site B",
    },
    {
      id: 9,
      name: "Ibrahim Malik",
      title: "Office Boy",
      parentId: 8,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site B",
    },
    {
      id: 10,
      name: "Amina Qureshi",
      title: "Secretary",
      parentId: 8,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site B",
    },
    {
      id: 11,
      name: "Abdullah Hassan",
      title: "General Manager",
      parentId: 1,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site C",
    },
    {
      id: 12,
      name: "Salman Tariq",
      title: "Assistant Manager",
      parentId: 11,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site C",
    },
    {
      id: 13,
      name: "Sara Ahmed",
      title: "Assistant Manager",
      parentId: 11,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site C",
    },
    {
      id: 14,
      name: "Ali Raza",
      title: "HR Head",
      parentId: 1,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site D",
    },
    {
      id: 15,
      name: "Ayesha Khan",
      title: "HR Executive",
      parentId: 14,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site D",
    },
    {
      id: 16,
      name: "Khadija Mahmood",
      title: "HR Executive",
      parentId: 14,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site D",
    },
    {
      id: 17,
      name: "Omar Bashir",
      title: "Project Manager",
      parentId: 1,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site E",
    },
    {
      id: 18,
      name: "Zaid Hussain",
      title: "Supervisor",
      parentId: 17,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site E",
    },
    {
      id: 19,
      name: "Amna Akhtar",
      title: "Worker",
      parentId: 18,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site E",
    },
    {
      id: 20,
      name: "Naeem Shah",
      title: "Worker",
      parentId: 18,
      photoUrl: "https://via.placeholder.com/50",
      additionalInfo: "Site E",
    },
  ];
  useEffect(() => {
    const chart = new OrgChart()
      .container(chartRef.current)
      .data(data)
      .nodeWidth(() => 250)
      .nodeHeight(() => 150)
      .childrenMargin(() => 50)
      .compact(false)
      .onNodeClick((d) => {
        d3.select(this).on("click", function (event) {
          d.toggleExpandCollapse();
          chart.update(chart.data());
        });
      })
      .nodeContent((d) => {
        return `
          <div style="display: flex; align-items: center; padding: 10px; background-color: none; margin-left: 1px; height: ${
            d.height
          }px; border-radius: 2px; flex-direction: column;">
            <div style="display: flex; align-items: center; padding: 10px; background-color: #FDFDFD; border: 1px solid lightgray; border-radius: 10px; width: 100%; height: ${
              d.height - 20
            }px;">
              <img src="${
                d.data.photoUrl
              }" alt="Photo" style="border-radius: 50%; margin-right: 20px;" />
              <div>
                <div style="font-size: 16px;"><b>${d.data.name}</b></div>
                <div style="font-size: 14px; color: gray;">${d.data.title}</div>
                <div style="font-size: 14px; color: #4CAF50; margin-top: 5px;"><b>${
                  d.data.additionalInfo
                }</b></div>
              </div>
            </div>
          </div>
        `;
      })
      .render();

    const resizeObserver = new ResizeObserver(() => {
      chart.update(chart.data());
    });
    resizeObserver.observe(chartRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [data]);

  return (
    <div style={{ width: "100%", height: "auto" }}>
      <div ref={chartRef} style={{ width: "100%" }}></div>
    </div>
  );
};

export default EmployeeHierarchy;
