// LoadingIndicator.js

import React from "react";
import { useSelector } from "react-redux";
import { Spinner, Box } from "@chakra-ui/react";

const LoadingIndicator = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  return (
    <>
      {isLoading && (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Spinner size="xl" color="primary.500" />
        </Box>
      )}
    </>
  );
};

export default LoadingIndicator;
