// ProjectList.js
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Icon, Text, Flex, Button } from "@chakra-ui/react";
import { IoAddCircleOutline } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdOutlineHourglassEmpty } from "react-icons/md";
import ProjectItem from "./ProjectItem";
import "../../styles/ProjectList.css";

const ProjectList = () => {
  const projects = useSelector((state) => state.projectList.projects);
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const scrollContainerRef = React.useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const handleClick = (index) => {
    if (scrollContainerRef.current && activeIndex !== index) {
      setActiveIndex(index);
      const itemWidth = 100;
      const activeWidth = 400;
      const center = scrollContainerRef.current.offsetWidth / 2;
      const newScrollPosition =
        index * itemWidth +
        itemWidth / 2 -
        center +
        (index === activeIndex ? itemWidth : activeWidth) / 2;
      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (scrollContainerRef.current && event.deltaY !== 0) {
        scrollContainerRef.current.scrollBy({
          left: event.deltaY,
          behavior: "smooth",
        });
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleWheel);

      return () => {
        scrollContainer.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  if (projects.length === 0) {
    return (
      <div className="no-projects-container">
        <Flex align={"center"} direction={"column"} justify={"center"} gap={20}>
          <Flex gap={10} direction={"column"} align={"center"}>
            <Icon as={MdOutlineHourglassEmpty} color="gray.500" boxSize={40} />
            <Text fontSize={"x-large"} mt={4}>
              No projects. Add a new one.
            </Text>
          </Flex>

          <Flex align="center" justifyContent="center">
            <button
              className="btn-primary"
              onClick={() => navigate("/add-site")}
            >
              <IoAddCircleOutline /> Add Site
            </button>
          </Flex>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <div className="project-search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Search Site"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn-primary" onClick={() => navigate("/add-site")}>
          <IoAddCircleOutline /> Add Site
        </button>
      </div>

      <div className="project-list-container">
        <button className="scroll-button left" onClick={scrollLeft}>
          <FaChevronLeft />
        </button>
        <div className="scroll-container" ref={scrollContainerRef}>
          {projects.map((project, index) => (
            <ProjectItem
              key={index}
              project={project}
              isActive={index === activeIndex}
              onClick={() => handleClick(index)}
            />
          ))}
        </div>
        <button className="scroll-button right" onClick={scrollRight}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default ProjectList;
