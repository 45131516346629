import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Select,
  Flex,
  Heading,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoAddCircleOutline, IoArrowBackCircleOutline } from "react-icons/io5";
import { HiOutlinePlusCircle } from "react-icons/hi2";
import { addDocument } from "../../store/documentSlice";

const AddDocumentForm = () => {
  const documents = useSelector((state) => state.documents.documents);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState({
    documentName: "",
    documentDescription: "",
    documentVersion: "",
    uploadedBy: "",
    uploadDate: "",
    documentFile: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (e) => {
    setFormData({ ...formData, documentFile: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const documentNameExists = documents.some(
      (doc) => doc.documentName === formData.documentName
    );
    if (documentNameExists) {
      toast({
        title: "Error",
        description:
          "Document name already exists. Please enter a different name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!formData.documentFile) {
      toast({
        title: "Error",
        description: "Please upload a document file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const newDocument = {
      ...formData,
      documentFile: formData.documentFile.name, // Store filename or handle file upload separately
    };

    dispatch(addDocument(newDocument));
    toast({
      title: "Success",
      description: "Document Added.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/documents");
  };

  return (
    <>
      <Flex gap={5} align="center" mb="4">
        <IoArrowBackCircleOutline
          onClick={() => navigate("/documents")}
          color="#db8700"
          size={50}
          cursor={"pointer"}
        />
        <Heading as="h2" size="lg">
          <Flex gap={5} align={"center"}>
            <Text>Add Document</Text>
            <HiOutlinePlusCircle color="green.600" fontSize={40} />
          </Flex>
        </Heading>
        <Box width="40px" />
      </Flex>

      <Box
        as="form"
        onSubmit={handleSubmit}
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Document Name</FormLabel>
            <Input
              name="documentName"
              value={formData.documentName}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Document Description</FormLabel>
            <Input
              name="documentDescription"
              value={formData.documentDescription}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Document Version</FormLabel>
            <Input
              name="documentVersion"
              value={formData.documentVersion}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Uploaded By</FormLabel>
            <Input
              name="uploadedBy"
              value={formData.uploadedBy}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Upload Date</FormLabel>
            <Input
              type="date"
              name="uploadDate"
              value={formData.uploadDate}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Upload Document</FormLabel>
            <Input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileUpload}
            />
          </FormControl>

          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={
              !formData.documentName ||
              !formData.documentVersion ||
              !formData.uploadedBy ||
              !formData.uploadDate ||
              !formData.documentFile
            }
          >
            <IoAddCircleOutline /> Add Document
          </Button>
        </VStack>
      </Box>
    </>
  );
};

export default AddDocumentForm;
