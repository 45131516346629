import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dutiesPlanning: [
    {
      sno: 1,
      date: "2023-06-01",
      foreman: "John Doe",
      workDescription: "Site preparation",
      site: "Site A",
      stage: "Initial",
      reportStatus: "Pending",
      activities: [],
      workImages: [],
      manpower: [],
      labourHours: 160,
    },
    {
      sno: 2,
      date: "2023-06-02",
      foreman: "Jane Smith",
      workDescription: "Foundation laying",
      site: "Site B",
      stage: "Intermediate",
      reportStatus: "Done",
      workImages: [],
      activities: [],
      manpower: [],
      labourHours: 50,
    },
    {
      sno: 3,
      date: "2023-06-03",
      foreman: "Mike Johnson",
      workDescription: "Structural work",
      site: "Site C",
      stage: "Advanced",
      reportStatus: "Pending",
      workImages: [],
      activities: [],
      manpower: [],
      labourHours: 120,
    },
  ],
};

const dutiesPlanningSlice = createSlice({
  name: "dutiesPlanning",
  initialState,
  reducers: {
    addDuty: (state, action) => {
      state.dutiesPlanning.push(action.payload);
    },
    addActivity: (state, action) => {
      const { id, activity } = action.payload;
      const duty = state.dutiesPlanning.find((duty) => duty.id === id);
      if (duty) {
        duty.activities.push({ activity, done: false });
      }
    },
    addManpower: (state, action) => {
      const { id, manpower } = action.payload;
      const duty = state.dutiesPlanning.find((duty) => duty.id === id);
      if (duty) {
        duty.manpower.push(manpower);
      }
    },
    updateDuty: (state, action) => {
      const { id, updates } = action.payload;
      const dutyIndex = state.dutiesPlanning.findIndex(
        (duty) => duty.id === id
      );
      if (dutyIndex !== -1) {
        state.dutiesPlanning[dutyIndex] = {
          ...state.dutiesPlanning[dutyIndex],
          ...updates,
        };
      }
    },
  },
});

export const { addDuty, addActivity, addManpower, updateDuty } =
  dutiesPlanningSlice.actions;

export const selectDutiesPlanning = (state) =>
  state.dutiesPlanning.dutiesPlanning;

export const selectDutyById = (state, id) =>
  state.dutiesPlanning.dutiesPlanning.find((duty) => duty.id === id);

export default dutiesPlanningSlice.reducer;
