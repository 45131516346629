import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  HStack,
  NumberInput,
  NumberInputField,
  Select,
  Flex,
  Textarea,
  Text,
  VStack,
  Tooltip,
  useToast,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import GenericHeader from "../../commons/GenericHeader";
import PrimaryButton from "../../commons/PrimaryButton";
import { PiEquals } from "react-icons/pi";
import ManpowerReceipt from "./ManpowerReceipt";

const PreviousWorkHistory = ({ onRowClick }) => {
  const dummyData = [
    {
      sno: 1,
      date: "2023-08-01",
      workDescription: "Installed wiring for new building",
      siteName: "Site 1",
      stageName: "Stage 1",
      foremanName: "Foreman A",
      amountPaid: "1500 AED",
    },
    {
      sno: 2,
      date: "2023-08-03",
      workDescription: "Plumbing work in office",
      siteName: "Site 2",
      stageName: "Stage 3",
      foremanName: "Foreman B",
      amountPaid: "1200 AED",
    },
    {
      sno: 3,
      date: "2023-08-05",
      workDescription: "Carpentry work for cabinets",
      siteName: "Site 1",
      stageName: "Stage 2",
      foremanName: "Foreman C",
      amountPaid: "1800 AED",
    },
    {
      sno: 4,
      date: "2023-08-07",
      workDescription: "Electrical repair work",
      siteName: "Site 2",
      stageName: "Stage 4",
      foremanName: "Foreman A",
      amountPaid: "900 AED",
    },
    {
      sno: 5,
      date: "2023-08-09",
      workDescription: "General maintenance",
      siteName: "Site 1",
      stageName: "Stage 1",
      foremanName: "Foreman B",
      amountPaid: "1100 AED",
    },
  ];

  return (
    <Box mt={8}>
      <Text fontSize="xl" fontWeight="bold" color="gray.400" mb={2}>
        Previous Work History
      </Text>
      <Divider mb={4} />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Sno</Th>
            <Th>Date</Th>
            <Th>Work Description</Th>
            <Th>Site Name</Th>
            <Th>Stage Name</Th>
            <Th>Foreman Name</Th>
            <Th>Amount Paid</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dummyData.map((row, index) => (
            <Tr key={index} onClick={() => onRowClick(row)} cursor="pointer">
              <Td>{row.sno}</Td>
              <Td>{row.date}</Td>
              <Td>{row.workDescription}</Td>
              <Td>{row.siteName}</Td>
              <Td>{row.stageName}</Td>
              <Td>{row.foremanName}</Td>
              <Td>{row.amountPaid}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

const AddManpowerForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedWork, setSelectedWork] = useState(null);
  const {
    isOpen: isReceiptDrawerOpen,
    onOpen: onReceiptDrawerOpen,
    onClose: onReceiptDrawerClose,
  } = useDisclosure();
  const dummySites = [
    {
      id: 1,
      name: "Site 1",
      stages: [
        { id: 1, name: "Stage 1" },
        { id: 2, name: "Stage 2" },
      ],
    },
    {
      id: 2,
      name: "Site 2",
      stages: [
        { id: 3, name: "Stage 3" },
        { id: 4, name: "Stage 4" },
      ],
    },
  ];
  const dummyForemen = [
    { id: 1, name: "Foreman A" },
    { id: 2, name: "Foreman B" },
    { id: 3, name: "Foreman C" },
  ];

  const [formData, setFormData] = useState({
    name: "",
    skill: "",
    siteName: "",
    stageName: "",
    rate: 0,
    paid: 0,
    balance: 0,
    workDoneEvaluation: 0,
    foreman: "",
    pictures: [],
    workDescription: "",
    remarks: "",
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      balance: prevData.rate - prevData.paid,
    }));
  }, [formData.rate, formData.paid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNumberChange = (name, value) => {
    setFormData({ ...formData, [name]: parseFloat(value) || 0 });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const urls = files.map((file) => URL.createObjectURL(file));
    setFormData({ ...formData, pictures: [...formData.pictures, ...urls] });
  };

  const handleImageRemove = (url) => {
    setFormData({
      ...formData,
      pictures: formData.pictures.filter((picture) => picture !== url),
    });
  };

  const handleRowClick = (row) => {
    setSelectedWork(row);
    onOpen();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast({
      title: "Success",
      description: "Manpower Added.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/daily-manpower");
  };

  return (
    <>
      <Flex>
        <GenericHeader
          back={"/daily-manpower"}
          icon={<AddIcon />}
          title={"Add Manpower"}
        />
        <Button colorScheme="teal" onClick={onReceiptDrawerOpen} ml={4} p={5}>
          Preview and Print Invoice
        </Button>
      </Flex>
      <Box
        as="form"
        onSubmit={handleSubmit}
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        {/* Worker Details Section */}
        <Text fontSize="xl" fontWeight="bold" color="gray.400" mb={2}>
          Worker Details
        </Text>
        <Divider mb={4} />
        <VStack spacing={4}>
          <HStack spacing={4} width="100%">
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Skill</FormLabel>
              <Select
                name="skill"
                value={formData.skill}
                onChange={handleInputChange}
              >
                <option value="Electrician">Electrician</option>
                <option value="Plumber">Plumber</option>
                <option value="Carpenter">Carpenter</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Rate</FormLabel>
              <NumberInput
                min={0}
                name="rate"
                value={formData.rate}
                onChange={(value) => handleNumberChange("rate", value)}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
          </HStack>
        </VStack>

        {/* Allotment Section */}
        <Text fontSize="xl" fontWeight="bold" color="gray.400" mt={8} mb={2}>
          Allotment
        </Text>
        <Divider mb={4} />
        <VStack spacing={4}>
          <HStack spacing={4} width="100%">
            <FormControl isRequired>
              <FormLabel>Site Name</FormLabel>
              <Select
                name="siteName"
                value={formData.siteName}
                onChange={handleInputChange}
              >
                {dummySites.map((site) => (
                  <option key={site.id} value={site.name}>
                    {site.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired isDisabled={!formData.siteName}>
              <FormLabel>Stage Name</FormLabel>
              <Select
                name="stageName"
                value={formData.stageName}
                onChange={handleInputChange}
                isDisabled={!formData.siteName}
              >
                {formData.siteName &&
                  dummySites
                    .find((site) => site.name === formData.siteName)
                    ?.stages.map((stage) => (
                      <option key={stage.id} value={stage.name}>
                        {stage.name}
                      </option>
                    ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Foreman</FormLabel>
              <Select
                name="foreman"
                value={formData.foreman}
                onChange={handleInputChange}
              >
                {dummyForemen.map((foreman) => (
                  <option key={foreman.id} value={foreman.name}>
                    {foreman.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          <FormControl>
            <FormLabel>Work Description</FormLabel>
            <Textarea
              name="workDescription"
              value={formData.workDescription}
              onChange={handleInputChange}
              rows={5}
            />
          </FormControl>
        </VStack>

        {/* Evaluation Section */}
        <Text fontSize="xl" fontWeight="bold" color="gray.400" mt={8} mb={2}>
          Evaluation
        </Text>
        <Divider mb={4} />
        <Flex direction={{ base: "column", lg: "row" }} gap={4}>
          <VStack spacing={4} flex="1">
            <FormControl isRequired>
              <FormLabel>Work Done Evaluation</FormLabel>
              <Tooltip
                label="Below 70 - Poor, 70-80 - Average, 80-90 - Good, 90-100 - Excellent"
                placement="right"
                hasArrow
                isOpen={tooltipOpen}
              >
                <NumberInput
                  min={0}
                  max={100}
                  name="workDoneEvaluation"
                  value={formData.workDoneEvaluation}
                  onChange={(value) =>
                    handleNumberChange("workDoneEvaluation", value)
                  }
                  onFocus={() => setTooltipOpen(true)}
                  onBlur={() => setTooltipOpen(false)}
                >
                  <NumberInputField />
                </NumberInput>
              </Tooltip>
            </FormControl>
            <FormControl>
              <FormLabel>Remarks</FormLabel>
              <Textarea
                name="remarks"
                value={formData.remarks}
                onChange={handleInputChange}
                rows={3}
              />
            </FormControl>
          </VStack>
          <VStack spacing={4} flex="1">
            <FormControl>
              <FormLabel>Pictures</FormLabel>
              <Input
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageUpload}
              />
              <Flex mt={2} wrap="wrap">
                {formData.pictures.map((url, index) => (
                  <Box key={index} position="relative" mr={2} mb={2}>
                    <img
                      src={url}
                      alt={`Uploaded ${index}`}
                      style={{ width: 100, height: 100 }}
                    />
                    <Button
                      size="xs"
                      position="absolute"
                      top={0}
                      right={0}
                      colorScheme="red"
                      onClick={() => handleImageRemove(url)}
                    >
                      X
                    </Button>
                  </Box>
                ))}
              </Flex>
            </FormControl>
          </VStack>
        </Flex>

        {/* Payment Section */}
        <Text fontSize="xl" fontWeight="bold" color="gray.400" mt={8} mb={2}>
          Payment
        </Text>
        <Divider mb={4} />
        <Flex
          justifyContent={"center"}
          background={"#8700DB"}
          padding={"5"}
          margin={"5"}
          borderRadius={20}
          alignItems={"center"}
          color={"white"}
          gap={10}
        >
          <FormControl isRequired width={"fit-content"}>
            <FormLabel fontSize={"large"}>Paid</FormLabel>
            <NumberInput
              fontSize={"xxx-large"}
              min={0}
              max={formData.rate}
              name="paid"
              value={formData.paid}
              onChange={(value) => handleNumberChange("paid", value)}
            >
              <NumberInputField fontSize={"xxx-large"} height={"60px"} />
            </NumberInput>
          </FormControl>

          <PiEquals fontSize={"32px"} />
          <FormControl width={"fit-content"}>
            <FormLabel fontSize={"large"}>Balance</FormLabel>
            <Text fontSize={"xxx-large"} fontWeight="bold">
              {formData.balance} AED
            </Text>
          </FormControl>
        </Flex>
        <PrimaryButton
          type="submit"
          colorScheme="Primary"
          size="lg"
          mt={4}
          title={"Add Manpower"}
        />
      </Box>

      {/* Previous Work History Section */}
      <PreviousWorkHistory onRowClick={handleRowClick} />

      {/* Drawer for displaying selected work details */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Work Details</DrawerHeader>

          <DrawerBody>
            {selectedWork && (
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel>Sno</FormLabel>
                  <Text>{selectedWork.sno}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel>Date</FormLabel>
                  <Text>{selectedWork.date}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel>Work Description</FormLabel>
                  <Text>{selectedWork.workDescription}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel>Site Name</FormLabel>
                  <Text>{selectedWork.siteName}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel>Stage Name</FormLabel>
                  <Text>{selectedWork.stageName}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel>Foreman Name</FormLabel>
                  <Text>{selectedWork.foremanName}</Text>
                </FormControl>
                <FormControl>
                  <FormLabel>Amount Paid</FormLabel>
                  <Text>{selectedWork.amountPaid}</Text>
                </FormControl>
              </VStack>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Drawer for Preview and Print Invoice */}
      <Drawer
        isOpen={isReceiptDrawerOpen}
        placement="right"
        onClose={onReceiptDrawerClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Preview and Print Invoice</DrawerHeader>

          <DrawerBody>
            <ManpowerReceipt formData={formData} />
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onReceiptDrawerClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddManpowerForm;
