import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Input,
  List,
  ListItem,
  Avatar,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import GenericSelectBox from "../../../commons/GenericSelectBox";

const users = [
  {
    id: 1,
    name: "John Doe",
    designation: "Software Engineer",
    status: "Active",
    avatar: "https://bit.ly/dan-abramov",
  },
  {
    id: 2,
    name: "Jane Smith",
    designation: "Product Manager",
    status: "On Leave",
    avatar: "https://bit.ly/kent-c-dodds",
  },
  {
    id: 3,
    name: "Michael Brown",
    designation: "Designer",
    status: "Active",
    avatar: "https://bit.ly/prosper-baba",
  },
  // Add more options here
];

const TestingUI = () => {
  const renderOption = (option) => (
    <Flex alignItems="center">
      <Avatar src={option.avatar} size="sm" mr={3} />
      <Box>
        <Text fontWeight="bold">{option.name}</Text>
        <Text fontSize="sm" color="gray.500">
          {option.designation}
        </Text>
        <Text
          fontSize="sm"
          color={option.status === "Active" ? "green.500" : "red.500"}
        >
          {option.status}
        </Text>
      </Box>
    </Flex>
  );

  const keyExtractor = (option) => option.id;

  const labelExtractor = (option) => option.name;

  return (
    <Box p={5}>
      <GenericSelectBox
        options={users}
        renderOption={renderOption}
        keyExtractor={keyExtractor}
        labelExtractor={labelExtractor}
        placeholder="Select users..."
      />
    </Box>
  );
};
export default TestingUI;
