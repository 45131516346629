import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  images: [],
};

const billImagesSlice = createSlice({
  name: "billImages",
  initialState,
  reducers: {
    addImage: (state, action) => {
      state.images.push(...action.payload);
    },
    removeImage: (state, action) => {
      state.images = state.images.filter(
        (_, index) => index !== action.payload
      );
    },
  },
});

export const { addImage, removeImage } = billImagesSlice.actions;

export const selectBillImages = (state) => state.billImages.images;

export default billImagesSlice.reducer;
