import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import DashboardBox from "../../../commons/DashboardBox";

const LedgerManagement = () => {
  // Dummy data
  const transactions = [
    {
      date: "2024-08-01",
      accountFrom: "Account 1",
      accountTo: "Account 2",
      description: "Payment for services",
      amount: 500,
    },
    {
      date: "2024-08-02",
      accountFrom: "Account 2",
      accountTo: "Account 3",
      description: "Invoice payment",
      amount: 1500,
    },
    {
      date: "2024-08-03",
      accountFrom: "Account 1",
      accountTo: "Account 3",
      description: "Transfer",
      amount: 200,
    },
    {
      date: "2024-08-04",
      accountFrom: "Account 3",
      accountTo: "Account 2",
      description: "Refund",
      amount: 300,
    },
  ];

  const calculateTotals = (transactions) => {
    let totalTransactions = 0;
    let debitTotal = 0;
    let creditTotal = 0;

    transactions.forEach((transaction) => {
      totalTransactions++;
      debitTotal += transaction.amount;
      creditTotal += transaction.amount;
    });

    return {
      totalTransactions,
      debitTotal,
      creditTotal,
    };
  };

  const { totalTransactions, debitTotal, creditTotal } =
    calculateTotals(transactions);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTransaction, setNewTransaction] = useState({
    date: "",
    accountFrom: "",
    accountTo: "",
    description: "",
    amount: "",
  });

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTransaction((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAmountChange = (value) => {
    setNewTransaction((prevState) => ({
      ...prevState,
      amount: value,
    }));
  };

  const handleSubmit = () => {
    // Here you would typically handle form submission
    console.log("New Transaction:", newTransaction);
    setIsModalOpen(false);
  };

  return (
    <Box p={4}>
      <Flex mb={4} justifyContent="space-between" alignItems="center">
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          onClick={handleModalOpen}
        >
          Add Transaction
        </Button>
      </Flex>
      <Flex mb={4} justifyContent="space-around" gap={5} alignItems="center">
        <DashboardBox
          name="Total Transactions"
          value={totalTransactions}
          backgroundColor="blue.300"
        />
        <DashboardBox
          name="Debit Total"
          value={debitTotal}
          backgroundColor="green.300"
        />
        <DashboardBox
          name="Credit Total"
          value={creditTotal}
          backgroundColor="yellow.300"
        />
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Account From</Th>
            <Th>Account To</Th>
            <Th>Description</Th>
            <Th>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactions.map((transaction, index) => (
            <Tr key={index}>
              <Td>{transaction.date}</Td>
              <Td>{transaction.accountFrom}</Td>
              <Td>{transaction.accountTo}</Td>
              <Td>{transaction.description}</Td>
              <Td>{transaction.amount} AED</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Transaction</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Input
                placeholder="Date"
                mb={4}
                name="date"
                value={newTransaction.date}
                onChange={handleInputChange}
              />
              <Input
                placeholder="Account From"
                mb={4}
                name="accountFrom"
                value={newTransaction.accountFrom}
                onChange={handleInputChange}
              />
              <Input
                placeholder="Account To"
                mb={4}
                name="accountTo"
                value={newTransaction.accountTo}
                onChange={handleInputChange}
              />
              <Input
                placeholder="Description"
                mb={4}
                name="description"
                value={newTransaction.description}
                onChange={handleInputChange}
              />
              <NumberInput
                mb={4}
                name="amount"
                value={newTransaction.amount}
                onChange={handleAmountChange}
                min={0}
              >
                <NumberInputField placeholder="Amount" />
              </NumberInput>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleSubmit} mr={3}>
              Submit
            </Button>
            <Button variant="ghost" onClick={handleModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LedgerManagement;
