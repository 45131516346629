import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectItemsWithInvoices } from "../../store/billsSlice";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "./GenericGrid";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox"; // Import the DashboardBox component
import { CiMoneyBill } from "react-icons/ci";

const ItemsSearch = () => {
  const itemsWithInvoices = useSelector(selectItemsWithInvoices);
  const [rowData, setRowData] = useState(itemsWithInvoices);
  const [originalData] = useState(itemsWithInvoices);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(itemsWithInvoices);
  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedItemName, setSelectedItemName] = useState("");
  const [selectedInvoiceNo, setSelectedInvoiceNo] = useState("");
  const [selectedSupplierName, setSelectedSupplierName] = useState("");

  const filters = useMemo(
    () => ({
      itemName: selectedItemName,
      invoiceNo: selectedInvoiceNo,
      supplierName: selectedSupplierName,
    }),
    [selectedItemName, selectedInvoiceNo, selectedSupplierName]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedItemName = selectedItemName;
    let newSelectedInvoiceNo = selectedInvoiceNo;
    let newSelectedSupplierName = selectedSupplierName;

    switch (filterType) {
      case "itemName":
        newSelectedItemName = "";
        break;
      case "invoiceNo":
        newSelectedInvoiceNo = "";
        break;
      case "supplierName":
        newSelectedSupplierName = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedItemName || row.name === newSelectedItemName) &&
        (!newSelectedInvoiceNo || row.invoiceNo === newSelectedInvoiceNo) &&
        (!newSelectedSupplierName ||
          row.supplierName === newSelectedSupplierName)
      );
    });

    setSelectedItemName(newSelectedItemName);
    setSelectedInvoiceNo(newSelectedInvoiceNo);
    setSelectedSupplierName(newSelectedSupplierName);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedItemName || row.name === selectedItemName) &&
        (!selectedInvoiceNo || row.invoiceNo === selectedInvoiceNo) &&
        (!selectedSupplierName || row.supplierName === selectedSupplierName)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedItemName("");
    setSelectedInvoiceNo("");
    setSelectedSupplierName("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  // Calculating total amount, lowest price, and the corresponding invoice number
  const calculateTotalAmount = useMemo(() => {
    return filteredData.reduce(
      (sum, item) => sum + item.unitRate * item.quantity,
      0
    );
  }, [filteredData]);

  const lowestPriceItem = useMemo(() => {
    if (filteredData.length === 0) return null;
    return filteredData.reduce((minItem, item) =>
      item.unitRate < minItem.unitRate ? item : minItem
    );
  }, [filteredData]);

  const itemColumns = [
    {
      id: "sno",
      header: "S.No",
      accessor: (item, index) => index + 1,
      show: true,
      minWidth: 50,
    },
    {
      id: "name",
      header: "Item Name",
      accessor: (item) => item.name,
      show: true,
      minWidth: 150,
    },
    {
      id: "invoiceNo",
      header: "Invoice No",
      accessor: (item) => item.invoiceNo,
      show: true,
      minWidth: 150,
    },
    {
      id: "supplierName",
      header: "Supplier Name",
      accessor: (item) => item.supplierName,
      show: true,
      minWidth: 150, // Ensure this column has a minimum width
    },
    {
      id: "unitRate",
      header: "Unit Rate",
      accessor: (item) => item.unitRate,
      show: true,
      minWidth: 150,
    },
    {
      id: "quantity",
      header: "Quantity",
      accessor: (item) => item.quantity,
      show: true,
      minWidth: 150,
    },
    {
      id: "total",
      header: "Total Price",
      accessor: (item) => item.unitRate * item.quantity,
      show: true,
      minWidth: 150,
    },
    {
      id: "images",
      header: "Images",
      accessor: (item) => item.images,
      render: (item) => (
        <Box minWidth={150} onClick={(e) => e.stopPropagation()}>
          {/* Use your ImageGallery component to display images here */}
          {/* <ImageGallery images={item.images} width={150} /> */}
        </Box>
      ),
      show: true,
      minWidth: 150,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.sno,
      render: (item) => (
        <IconButton
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            deleteRow(item.sno);
          }}
        />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    itemColumns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const deleteRow = (sno) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (confirmed) {
      setRowData(rowData.filter((row, index) => index + 1 !== sno));
      setFilteredData(filteredData.filter((row, index) => index + 1 !== sno));
      toast({
        title: "Row deleted.",
        description: `Row with S.No ${sno} has been deleted.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={CiMoneyBill}
            name="Total Amount"
            value={calculateTotalAmount}
            backgroundColor="blue.300"
            type="money"
          />
          {lowestPriceItem && (
            <>
              <DashboardBox
                icon={CiMoneyBill}
                name="Lowest Price"
                value={lowestPriceItem.unitRate}
                backgroundColor="green.300"
                type="money"
              />
              <DashboardBox
                icon={CiMoneyBill}
                name="Invoice No"
                value={lowestPriceItem.invoiceNo}
                backgroundColor="orange.300"
                type="text"
              />
            </>
          )}
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Items"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.itemName && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Item: ${filters.itemName}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("itemName")} />
            </Tag>
          )}
          {filters.invoiceNo && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Invoice No: ${filters.invoiceNo}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("invoiceNo")} />
            </Tag>
          )}
          {filters.supplierName && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Supplier: ${filters.supplierName}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("supplierName")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Item Name"
                onChange={(e) => setSelectedItemName(e.target.value)}
                mb={4}
                value={selectedItemName}
              >
                {originalData.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Invoice No"
                onChange={(e) => setSelectedInvoiceNo(e.target.value)}
                mb={4}
                value={selectedInvoiceNo}
              >
                {originalData.map((item, index) => (
                  <option key={index} value={item.invoiceNo}>
                    {item.invoiceNo}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Supplier Name"
                onChange={(e) => setSelectedSupplierName(e.target.value)}
                value={selectedSupplierName}
              >
                {originalData.map((item, index) => (
                  <option key={index} value={item.supplierName}>
                    {item.supplierName}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {itemColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={itemColumns}
          data={filteredData}
          title="Items List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
        />
      </Box>
    </Box>
  );
};

export default ItemsSearch;
