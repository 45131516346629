import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import GenericHeader from "../../commons/GenericHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  addRentalTool,
  updateRentalTool,
  selectRentalToolById,
} from "../../store/toolsSlice";
import { useParams, useLocation } from "react-router-dom";
import { LiaToolsSolid } from "react-icons/lia";
import { IoAddCircleOutline } from "react-icons/io5";
import { PiEquals } from "react-icons/pi";

const AddRentalTool = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const tool = useSelector((state) => selectRentalToolById(state, id));
  const isEditMode = location.pathname.includes("edit");

  const [toolData, setToolData] = useState({
    hireDate: "",
    returnDate: "",
    totalDaysUsed: 0,
    siteName: "",
    rentalShopName: "",
    equipmentType: "",
    amountPerDay: 0,
    paid: 0,
    balance: 0,
  });

  useEffect(() => {
    if (isEditMode && tool) {
      setToolData({
        ...tool,
        totalDaysUsed: calculateTotalDays(tool.hireDate, tool.returnDate),
        balance: calculateBalance(
          tool.amountPerDay,
          calculateTotalDays(tool.hireDate, tool.returnDate),
          tool.paid
        ),
      });
    }
  }, [isEditMode, tool]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setToolData((prevData) => {
      const newData = { ...prevData, [name]: value };
      if (name === "returnDate") {
        newData.totalDaysUsed = calculateTotalDays(
          newData.hireDate,
          newData.returnDate
        );
      }
      if (name === "returnDate" || name === "paid") {
        newData.balance = calculateBalance(
          newData.amountPerDay,
          newData.totalDaysUsed,
          newData.paid
        );
      }
      return newData;
    });
  };

  const handleNumberChange = (name, value) => {
    setToolData((prevData) => {
      const newData = { ...prevData, [name]: value };
      newData.balance = calculateBalance(
        newData.amountPerDay,
        newData.totalDaysUsed,
        newData.paid
      );
      return newData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditMode) {
      dispatch(updateRentalTool(toolData));
    } else {
      dispatch(addRentalTool(toolData));
    }
  };

  const calculateTotalDays = (hireDate, returnDate) => {
    if (!hireDate || !returnDate) return 0;
    const hire = new Date(hireDate);
    const ret = new Date(returnDate);
    const diffTime = Math.abs(ret - hire);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const calculateBalance = (amountPerDay, totalDaysUsed, paid) => {
    return amountPerDay * totalDaysUsed - paid;
  };

  return (
    <Box>
      <GenericHeader
        title={isEditMode ? "Update Rental Tool" : "Add Rental Tool"}
        icon={<LiaToolsSolid />}
        back={"/tools"}
      />
      <Box
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <form onSubmit={handleSubmit}>
          <FormControl mb={4}>
            <FormLabel>Equipment Type</FormLabel>
            {isEditMode ? (
              <Text>{toolData.equipmentType}</Text>
            ) : (
              <Input
                name="equipmentType"
                value={toolData.equipmentType}
                onChange={handleChange}
              />
            )}
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Rental Shop Name</FormLabel>
            {isEditMode ? (
              <Text>{toolData.rentalShopName}</Text>
            ) : (
              <Input
                name="rentalShopName"
                value={toolData.rentalShopName}
                onChange={handleChange}
              />
            )}
          </FormControl>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Flex direction="column" mr={4} flex={1}>
              <FormControl mb={2}>
                <FormLabel>Hire Date</FormLabel>
                {isEditMode ? (
                  <Text>{toolData.hireDate}</Text>
                ) : (
                  <Input
                    type="date"
                    name="hireDate"
                    value={toolData.hireDate}
                    onChange={handleChange}
                  />
                )}
              </FormControl>
              <FormControl isRequired mb={2}>
                <FormLabel>Return Date</FormLabel>
                <Input
                  type="date"
                  name="returnDate"
                  value={toolData.returnDate}
                  onChange={handleChange}
                  min={toolData.hireDate}
                  disabled={!toolData.hireDate}
                />
              </FormControl>
            </Flex>
            <Flex direction="column" alignItems="center" flex={1}>
              <FormLabel>Total Days</FormLabel>
              <Text fontSize="2xl">{toolData.totalDaysUsed}</Text>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <FormControl mb={2} mr={4}>
              <FormLabel>Site Name</FormLabel>
              {isEditMode ? (
                <Text>{toolData.siteName}</Text>
              ) : (
                <Input
                  name="siteName"
                  value={toolData.siteName}
                  onChange={handleChange}
                />
              )}
            </FormControl>
            <FormControl mb={2}>
              <FormLabel>Stage Name</FormLabel>
              {isEditMode ? (
                <Text>{toolData.stageName}</Text>
              ) : (
                <Input
                  name="stageName"
                  value={toolData.stageName}
                  onChange={handleChange}
                />
              )}
            </FormControl>
          </Flex>
          <Flex
            justifyContent={"space-between"}
            background={"#8700DB"}
            padding={"5"}
            margin={"5"}
            borderRadius={20}
            alignItems={"center"}
            color={"white"}
          >
            <HStack>
              <FormControl width={"fit-content"}>
                <FormLabel fontSize={"large"}>Amount per Day</FormLabel>
                {isEditMode ? (
                  <Text>{toolData.amountPerDay}</Text>
                ) : (
                  <NumberInput
                    min={0}
                    name="amountPerDay"
                    value={toolData.amountPerDay}
                    onChange={(value) =>
                      handleNumberChange("amountPerDay", value)
                    }
                  >
                    <NumberInputField />
                  </NumberInput>
                )}
              </FormControl>
              <FormControl isRequired width={"fit-content"}>
                <FormLabel fontSize={"large"}>Paid</FormLabel>
                <NumberInput
                  fontSize={"x-large"}
                  min={0}
                  name="paid"
                  value={toolData.paid}
                  onChange={(value) => handleNumberChange("paid", value)}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
            </HStack>
            <PiEquals fontSize={"32px"} />
            <FormControl width={"fit-content"}>
              <FormLabel fontSize={"large"}>Balance</FormLabel>
              <Text fontSize={"xxx-large"} fontWeight="bold">
                {toolData.balance} AED
              </Text>
            </FormControl>
          </Flex>
          <Flex justifyContent={"center"}>
            <Button
              type="submit"
              colorScheme="primary"
              leftIcon={<IoAddCircleOutline />}
            >
              {isEditMode ? "Update Rental Tool" : "Add Rental Tool"}
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default AddRentalTool;
