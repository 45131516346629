import React, { useEffect, useRef, useState } from "react";
import { Box, Checkbox, Flex, Button, useDisclosure } from "@chakra-ui/react";
import "../styles/GenericList.css";

const GenericList = ({ columns, data, onRowClick, identifier }) => {
  const scrollContainerRef = useRef(null);
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [selectedRows, setSelectedRows] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const headerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      if (scrollContainerRef.current && event.deltaY !== 0) {
        scrollContainerRef.current.scrollBy({
          left: event.deltaY,
          behavior: "smooth",
        });
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleWheel);
      return () => {
        scrollContainer.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        headerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft;
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleColumnVisibilityChange = (accessor) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.accessor === accessor ? { ...col, isVisible: !col.isVisible } : col
      )
    );
  };

  const toggleRowSelection = (rowIndex) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowIndex)
        ? prevSelectedRows.filter((index) => index !== rowIndex)
        : [...prevSelectedRows, rowIndex]
    );
  };

  const toggleSelectAllRows = () => {
    setSelectedRows(
      selectedRows.length === data.length ? [] : data.map((_, index) => index)
    );
  };

  return (
    <Box>
      <Button onClick={onOpen}>Columns</Button>
      {isOpen && (
        <Box
          position="absolute"
          zIndex={2}
          background="white"
          boxShadow="md"
          p={4}
        >
          <ul>
            {columns.map((column) => (
              <li
                key={column.accessor}
                onClick={() => handleColumnVisibilityChange(column.accessor)}
              >
                <Checkbox isChecked={column.isVisible}>
                  {column.header}
                </Checkbox>
              </li>
            ))}
          </ul>
          <Button mt={4} onClick={onClose}>
            Close
          </Button>
        </Box>
      )}
      <Box overflowX="auto" ref={headerRef}>
        <Flex
          color="gray.600"
          p={2}
          fontWeight="bold"
          bg="gray.100"
          position="sticky"
          top={0}
          zIndex={3}
          alignItems="center"
        >
          <Checkbox
            isChecked={selectedRows.length === data.length}
            onChange={toggleSelectAllRows}
            mr={2}
          />
          {visibleColumns
            .filter((column) => column.isVisible !== false)
            .map((column, index) => (
              <Box
                className={`${column.sticky ? `sticky-${column.sticky}` : ""}`}
                flex={column.flex || "1"}
                textAlign="left"
                minW={column.minWidth || "150px"}
                maxW={column.maxWidth || "auto"}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                key={index}
                position="relative"
                zIndex={column.sticky ? 2 : 1}
              >
                {column.header}
              </Box>
            ))}
        </Flex>
      </Box>
      <Box
        className="custom-scrollbar"
        overflowX="auto"
        ref={scrollContainerRef}
      >
        <Box minW="1000px">
          {data.map((item, rowIndex) => (
            <Flex
              key={rowIndex}
              alignItems="center"
              mb={2}
              bg={selectedRows.includes(rowIndex) ? "gray.200" : "white"}
              _hover={{ bg: "gray.100" }}
              onClick={
                onRowClick ? () => onRowClick(item[identifier]) : undefined
              }
            >
              <Checkbox
                isChecked={selectedRows.includes(rowIndex)}
                onChange={() => toggleRowSelection(rowIndex)}
                mr={2}
                bg="white"
              />
              {visibleColumns
                .filter((column) => column.isVisible !== false)
                .map((column, colIndex) => (
                  <Box
                    className={`${
                      column.sticky ? `sticky-${column.sticky}` : ""
                    }`}
                    flex={column.flex || "1"}
                    textAlign="left"
                    minW={column.minWidth || "150px"}
                    maxW={column.maxWidth || "auto"}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    key={colIndex}
                    zIndex={column.sticky ? 2 : 1}
                  >
                    {item[column.accessor]}
                  </Box>
                ))}
            </Flex>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default GenericList;
