import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";

import { SiSmartthings } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMaterials } from "../../store/materialsSlice";
import GenericHeader from "../../commons/GenericHeader";
import DashboardBox from "../../commons/DashboardBox";
import SearchInput from "../../commons/SearchInput";
import GenericGrid from "../Procurement/GenericGrid";

const MaterialsList = () => {
  const materials = useSelector(selectMaterials);
  const navigate = useNavigate();
  const toast = useToast();

  const [rowData, setRowData] = useState(materials);
  const [originalData] = useState(materials);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(materials);
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedMaterialName, setSelectedMaterialName] = useState("");

  const filters = useMemo(
    () => ({
      materialName: selectedMaterialName,
    }),
    [selectedMaterialName]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedMaterialName = selectedMaterialName;

    if (filterType === "materialName") {
      newSelectedMaterialName = "";
    }

    const filteredData = originalData.filter((row) => {
      return !newSelectedMaterialName || row.name === newSelectedMaterialName;
    });

    setSelectedMaterialName(newSelectedMaterialName);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return !selectedMaterialName || row.name === selectedMaterialName;
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedMaterialName("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const columns = [
    {
      id: "id",
      header: "ID",
      accessor: (item) => item.id,
      show: true,
      minWidth: 50,
    },
    {
      id: "name",
      header: "Material Name",
      accessor: (item) => item.name,
      show: true,
      minWidth: 200,
    },
    {
      id: "type",
      header: "Type",
      accessor: (item) => item.type,
      show: true,
      minWidth: 150,
    },
    {
      id: "actions",
      header: "Actions",
      accessor: (item) => item.id,
      render: (item) => (
        <IconButton
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            // Handle delete material logic here
          }}
        />
      ),
      show: true,
      minWidth: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleRowClick = ({ id }) => {
    navigate(`/materials/details/${id}`);
  };

  const totalMaterials = materials.length;

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader
          title="Materials"
          addButtonName="Material"
          to="/add-material"
          icon={<SiSmartthings />}
        />
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={SiSmartthings}
            name="Total Materials"
            value={totalMaterials}
            backgroundColor="blue.300"
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Materials"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.materialName && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Material Name: ${filters.materialName}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("materialName")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Material Name"
                onChange={(e) => setSelectedMaterialName(e.target.value)}
                mb={4}
                value={selectedMaterialName}
              >
                {materials.map((material) => (
                  <option key={material.name} value={material.name}>
                    {material.name}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {columns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={columns}
          data={filteredData}
          title="Material List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={setSortConfig}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default MaterialsList;
