// src/components/ProfilePage.jsx

import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  HStack,
  Input,
  IconButton,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { getGreeting } from "../utils";
import { FaEdit } from "react-icons/fa";
import { MdAccountCircle, MdDetails, MdSettings, MdLock } from "react-icons/md";
import SelectBox from "../commons/SelectBox";

const dummyUser = {
  firstName: "Abu Bakar",
  lastName: "Bakar",
  profession: "Software Engineer",
  age: 30,
  visa: "H1B",
  address: "123 Main St, Springfield, IL",
  accommodationRoomNo: "101",
  city: "Springfield",
  country: "USA",
  phone: "+1 234 567 890",
  postalCode: "62701",
  emailAddress: "john.doe@example.com",
  photo: "https://via.placeholder.com/100",
  coverImage: "https://via.placeholder.com/800x200",
  fontSize: "medium",
  fontFamily: "arial",
};

const ProfilePage = () => {
  const [user, setUser] = useState(dummyUser);
  const [coverImage, setCoverImage] = useState(dummyUser.coverImage);
  const [profilePhoto, setProfilePhoto] = useState(dummyUser.photo);
  const {
    isOpen: isCoverOpen,
    onOpen: onCoverOpen,
    onClose: onCoverClose,
  } = useDisclosure();
  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose,
  } = useDisclosure();

  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setCoverImage(reader.result);
      onCoverClose();
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePhoto(reader.result);
      onProfileClose();
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const greeting = getGreeting();
  const currentTime = new Date().toLocaleTimeString();

  return (
    <Box>
      {/* First Section: Cover Image and Greeting */}
      <Box position="relative" mb={8} borderRadius="md" bg="gray.100">
        <Image
          src={coverImage}
          alt="Cover Image"
          w="100%"
          h="200px"
          objectFit="cover"
        />
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          bg="rgba(0, 0, 0, 0.3)"
          opacity={0}
          _hover={{ opacity: 1 }}
        >
          <IconButton
            icon={<FaEdit />}
            size="sm"
            colorScheme="primary"
            position="absolute"
            top={4}
            right={4}
            onClick={onCoverOpen}
          />
        </Box>
        <Flex position="absolute" bottom={4} left={4} alignItems="center">
          <VStack
            align="flex-start"
            bg="whiteAlpha.800"
            p={4}
            borderRadius="md"
          >
            <Heading as="h2" size="xl">
              {greeting}, {user.firstName}!
            </Heading>
            <Text fontSize="lg">{currentTime}</Text>
          </VStack>
        </Flex>
        <Box
          position="absolute"
          bottom="-50px"
          left="50%"
          transform="translateX(-50%)"
          borderRadius="full"
          overflow="hidden"
          border="4px solid white"
        >
          <Box position="relative">
            <Image
              src={profilePhoto}
              alt="User Photo"
              boxSize="100px"
              objectFit="cover"
            />
            <Box
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              bg="rgba(0, 0, 0, 0.3)"
              opacity={0}
              _hover={{ opacity: 1 }}
            >
              <IconButton
                icon={<FaEdit />}
                size="sm"
                colorScheme="primary"
                position="absolute"
                top={0}
                right={35}
                transform="translate(50%, 50%)"
                opacity={0.8}
                onClick={onProfileOpen}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Modal for uploading cover image */}
      <Modal isOpen={isCoverOpen} onClose={onCoverClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Cover Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Upload Cover Image</FormLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={handleCoverImageChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCoverClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for uploading profile photo */}
      <Modal isOpen={isProfileOpen} onClose={onProfileClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Profile Photo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Upload Profile Photo</FormLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={handleProfilePhotoChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onProfileClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Second Section: User Details */}
      <Box mb={8}>
        <HStack mb={4}>
          <MdDetails />
          <Heading as="h3" size="lg">
            User Details
          </Heading>
        </HStack>
        <Box bg="white" p={6} borderRadius="md" boxShadow="md">
          <SimpleGrid columns={2} spacing={6}>
            <Flex justify="space-between">
              <Text fontWeight="bold">First Name:</Text>
              <Text>{user.firstName}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Last Name:</Text>
              <Text>{user.lastName}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Profession:</Text>
              <Text>{user.profession}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Age:</Text>
              <Text>{user.age}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Visa:</Text>
              <Text>{user.visa}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Address:</Text>
              <Text>{user.address}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Accommodation Room No:</Text>
              <Text>{user.accommodationRoomNo}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">City:</Text>
              <Text>{user.city}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Country:</Text>
              <Text>{user.country}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Phone:</Text>
              <Text>{user.phone}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Postal Code:</Text>
              <Text>{user.postalCode}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Email Address:</Text>
              <Text>{user.emailAddress}</Text>
            </Flex>
          </SimpleGrid>
        </Box>
      </Box>

      {/* Third Section: User Preferences */}
      <Box mb={8}>
        <HStack mb={4}>
          <MdSettings />
          <Heading as="h3" size="lg">
            User Preferences
          </Heading>
        </HStack>
        <Box bg="white" p={6} borderRadius="md" boxShadow="md">
          <VStack spacing={4} align="stretch">
            <Flex justify="space-between">
              <Text fontWeight="bold">Font Size:</Text>
              <SelectBox
                options={[
                  { value: "small", label: "Small" },
                  { value: "medium", label: "Medium" },
                  { value: "large", label: "Large" },
                ]}
                value={user.fontSize}
                onChange={(e) => setUser({ ...user, fontSize: e.target.value })}
              />
            </Flex>
            <Flex justify="space-between">
              <Text fontWeight="bold">Font Family:</Text>
              <SelectBox
                options={[
                  { value: "arial", label: "Arial" },
                  { value: "times", label: "Times New Roman" },
                  { value: "courier", label: "Courier New" },
                ]}
                value={user.fontFamily}
                onChange={(e) =>
                  setUser({ ...user, fontFamily: e.target.value })
                }
              />
            </Flex>
          </VStack>
        </Box>
      </Box>

      {/* Fourth Section: Change Password */}
      <Box mb={8}>
        <HStack mb={4}>
          <MdLock />
          <Heading as="h3" size="lg">
            Change Password
          </Heading>
        </HStack>
        <Box bg="white" p={6} borderRadius="md" boxShadow="md">
          <VStack spacing={4} align="stretch">
            <Input type="password" placeholder="Current Password" />
            <Input type="password" placeholder="New Password" />
            <Input type="password" placeholder="Confirm New Password" />
            <Button colorScheme="primary">Change Password</Button>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePage;
