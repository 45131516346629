import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Avatar,
  Tag,
  Text,
} from "@chakra-ui/react";

const FinancialAnalytics = () => {
  const data = [
    {
      name: "Darlene Robertson",
      id: "FR-331291238",
      payday: "19 December 2023",
      amount: "$157,912.77",
      category: "Freelance Payday",
      status: "Payment Success",
      statusColor: "green",
    },
    {
      name: "Jenny Wilson",
      id: "ST-331282731",
      payday: "21 December 2023",
      amount: "$679,412.27",
      category: "Staff Payday",
      status: "Payment Success",
      statusColor: "green",
    },
    {
      name: "Albert Flores",
      id: "MM-331264712",
      payday: "23 December 2023",
      amount: "$729,112.77",
      category: "Member Payday",
      status: "Pending Payment",
      statusColor: "orange",
    },
    {
      name: "Wade Warren",
      id: "MM-312472943",
      payday: "24 December 2023",
      amount: "$837,412.77",
      category: "Member Payday",
      status: "On Hold Payment",
      statusColor: "gray",
    },
    {
      name: "Robert Fox",
      id: "ST-442176124",
      payday: "25 December 2023",
      amount: "$414,912.77",
      category: "Staff Payday",
      status: "Payment Success",
      statusColor: "green",
    },
    {
      name: "Jane Cooper",
      id: "PT-712479182",
      payday: "28 December 2023",
      amount: "$927,912.77",
      category: "Part-Time Payday",
      status: "On Hold Payment",
      statusColor: "gray",
    },
    {
      name: "Cameron Williamson",
      id: "MM-981240912",
      payday: "30 December 2023",
      amount: "$341,912.77",
      category: "Member Payday",
      status: "Pending Payment",
      statusColor: "orange",
    },
  ];

  return (
    <Box p={4}>
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={4} mb={6}>
        <Box p={4} bg="white" boxShadow="md" borderRadius="md">
          <Heading size="sm" mb={4}>
            Generate Financial Report
          </Heading>
          <Button colorScheme="gray" w="full">
            Generate Report
          </Button>
        </Box>
        <Box p={4} bg="white" boxShadow="md" borderRadius="md">
          <Stat>
            <StatLabel>Monthly Payroll</StatLabel>
            <StatNumber>$84,912,821</StatNumber>
            <Tag colorScheme="red">-18.24%</Tag>
          </Stat>
        </Box>
        <Box p={4} bg="white" boxShadow="md" borderRadius="md">
          <Stat>
            <StatLabel>Company Expenses</StatLabel>
            <StatNumber>$429,862.92</StatNumber>
            <Tag colorScheme="primary">+24.92%</Tag>
          </Stat>
        </Box>
      </SimpleGrid>

      <Box bg="white" boxShadow="md" borderRadius="md" p={4}>
        <Flex justifyContent="space-between" mb={4}>
          <Button colorScheme="gray">All Payment</Button>
          <Button colorScheme="primary">Add New Payment</Button>
        </Flex>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Product</Th>
              <Th>Payday</Th>
              <Th isNumeric>Payment Amount</Th>
              <Th>Payment Category</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item, index) => (
              <Tr key={index}>
                <Td>
                  <Flex alignItems="center">
                    <Avatar size="sm" name={item.name} mr={3} />
                    <Box>
                      <Text fontWeight="bold">{item.name}</Text>
                      <Text fontSize="sm">{item.id}</Text>
                    </Box>
                  </Flex>
                </Td>
                <Td>{item.payday}</Td>
                <Td isNumeric>{item.amount}</Td>
                <Td>{item.category}</Td>
                <Td>
                  <Badge colorScheme={item.statusColor}>{item.status}</Badge>
                </Td>
                <Td>
                  <Button size="sm">...</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default FinancialAnalytics;
