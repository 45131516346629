import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Text,
  Select,
  HStack,
  Textarea,
  Image,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addTransport,
  updateTransport,
  selectTransportById,
} from "../../store/transportSlice";
import GenericHeader from "../../commons/GenericHeader";
import { LiaToolsSolid } from "react-icons/lia";
import { IoAddCircleOutline } from "react-icons/io5";
import { PiEquals } from "react-icons/pi";
import { useDropzone } from "react-dropzone";
import { selectProjects } from "../../store/projectsSlice";
import TransportReceiptPreview from "./TransportReceiptPreview"; // Importing the new component
import PrimaryButton from "../../commons/PrimaryButton";

const AddTransportForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = !!id;
  const projects = useSelector(selectProjects);
  const transport = useSelector((state) => selectTransportById(state, id));

  const [transportData, setTransportData] = useState({
    date: "",
    siteName: "",
    transporter: "",
    workType: "",
    rate: 0,
    paid: 0,
    balance: 0,
    remarks: "",
    photo: "",
  });

  useEffect(() => {
    if (isEditMode && transport) {
      setTransportData(transport);
    }
  }, [isEditMode, transport]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransportData((prevData) => ({
      ...prevData,
      [name]: value,
      balance: calculateBalance({ ...prevData, [name]: value }),
    }));
  };

  const handleNumberChange = (name, value) => {
    setTransportData((prevData) => ({
      ...prevData,
      [name]: value,
      balance: calculateBalance({ ...prevData, [name]: value }),
    }));
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTransportData((prevData) => ({
          ...prevData,
          photo: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const calculateBalance = (data) => {
    return data.rate - data.paid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditMode) {
      dispatch(updateTransport({ id, updatedData: transportData }));
    } else {
      dispatch(addTransport(transportData));
    }
    navigate("/transport");
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Flex>
        <GenericHeader
          title={isEditMode ? "Update Transport" : "Add Transport"}
          icon={<LiaToolsSolid />}
          back={"/transport"}
        />
        <Button ml={4} colorScheme="teal" onClick={onOpen}>
          Preview & Print
        </Button>
      </Flex>
      <Box
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <Heading size="md" mb={4}>
          Details
        </Heading>
        <form onSubmit={handleSubmit}>
          <Flex mb={4}>
            <FormControl isRequired mr={4}>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                name="date"
                value={transportData.date}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Site Name and Area</FormLabel>
              <Select
                name="siteName"
                value={transportData.siteName}
                onChange={handleChange}
              >
                <option value="">Select Site</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.name}>
                    {project.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>

          <Flex mb={4}>
            <FormControl isRequired mr={4}>
              <FormLabel>Transporter/Party Name</FormLabel>
              <Input
                name="transporter"
                value={transportData.transporter}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Work Type</FormLabel>
              <Input
                name="workType"
                value={transportData.workType}
                onChange={handleChange}
              />
            </FormControl>
          </Flex>

          <Flex gap={5}>
            <FormControl mb={4}>
              <FormLabel>Remarks</FormLabel>
              <Textarea
                name="remarks"
                value={transportData.remarks}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Upload Photo</FormLabel>
              <Box
                {...getRootProps()}
                border="2px dashed gray"
                borderRadius="md"
                p={4}
                textAlign="center"
                cursor="pointer"
              >
                <input {...getInputProps()} />
                <Text>Drag 'n' drop an image here, or click to select one</Text>
              </Box>
              {transportData.photo && (
                <Box mt={2}>
                  <Image
                    src={transportData.photo}
                    alt="Uploaded photo"
                    maxH="200px"
                  />
                </Box>
              )}
            </FormControl>
          </Flex>

          <Heading size="md" mb={4}>
            Payments
          </Heading>
          <Flex
            justifyContent={"space-between"}
            background={"#8700DB"}
            padding={"5"}
            margin={"5"}
            borderRadius={20}
            alignItems={"center"}
            color={"white"}
          >
            <HStack>
              <FormControl isRequired width={"fit-content"}>
                <FormLabel fontSize={"large"}>Rate</FormLabel>
                <NumberInput
                  min={0}
                  name="rate"
                  value={transportData.rate}
                  onChange={(value) => handleNumberChange("rate", value)}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl isRequired width={"fit-content"}>
                <FormLabel fontSize={"large"}>Paid</FormLabel>
                <NumberInput
                  fontSize={"x-large"}
                  min={0}
                  name="paid"
                  value={transportData.paid}
                  onChange={(value) => handleNumberChange("paid", value)}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>
            </HStack>
            <PiEquals fontSize={"32px"} />
            <FormControl width={"fit-content"}>
              <FormLabel fontSize={"large"}>Balance</FormLabel>
              <Text fontSize={"xxx-large"} fontWeight="bold">
                {transportData.balance} AED
              </Text>
            </FormControl>
          </Flex>

          <Flex justifyContent={"center"} mb={4}>
            <PrimaryButton
              type="submit"
              colorScheme="primary"
              title={isEditMode ? "Update Transport" : "Add Transport"}
            ></PrimaryButton>
          </Flex>
        </form>
      </Box>

      {/* Drawer for preview and print */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Transport Receipt Preview</DrawerHeader>
          <DrawerBody>
            <TransportReceiptPreview transportData={transportData} />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={() => window.print()}>
              Print
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default AddTransportForm;
