import { createSlice } from "@reduxjs/toolkit";
import { isMobile } from "../utils";

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    collapsed: isMobile(),
  },
  reducers: {
    toggleSidebar: (state) => {
      state.collapsed = !state.collapsed;
    },
    setCollapsed: (state, action) => {
      state.collapsed = action.payload;
    },
  },
});

export const { toggleSidebar, setCollapsed } = sidebarSlice.actions;
export default sidebarSlice.reducer;
