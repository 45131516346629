import React, { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
} from "@chakra-ui/react";

import { FaBalanceScale, FaFileAlt, FaMoneyBillWave } from "react-icons/fa";
import { Line } from "react-chartjs-2";
import DashboardBox from "../../../commons/DashboardBox";
import GenericHeader from "../../../commons/GenericHeader";
import GenericGrid from "../../Procurement/GenericGrid";

const FinancialStatements = () => {
  // Dummy data
  const financialData = [
    { id: 1, account: "Cash", debit: 50000, credit: 20000 },
    { id: 2, account: "Accounts Receivable", debit: 80000, credit: 50000 },
    { id: 3, account: "Revenue", debit: 0, credit: 200000 },
  ];

  const columns = [
    { id: "id", header: "ID", accessor: (row) => row.id },
    { id: "account", header: "Account", accessor: (row) => row.account },
    {
      id: "debit",
      header: "Debit",
      accessor: (row) => `AED ${row.debit.toLocaleString()}`,
    },
    {
      id: "credit",
      header: "Credit",
      accessor: (row) => `AED ${row.credit.toLocaleString()}`,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => col.id)
  );

  const profitLossChart = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Revenue",
        data: [30000, 45000, 40000, 60000, 70000, 75000, 80000],
        borderColor: "#68D391",
        backgroundColor: "rgba(104, 211, 145, 0.2)",
      },
      {
        label: "Expenses",
        data: [20000, 25000, 20000, 30000, 35000, 40000, 45000],
        borderColor: "#FC8181",
        backgroundColor: "rgba(252, 129, 129, 0.2)",
      },
    ],
  };

  return (
    <Box p={5}>
      <GenericHeader title="Financial Statements & Reporting" />

      <Tabs>
        <TabList>
          <Tab>Balance Sheet</Tab>
          <Tab>Income Statement</Tab>
          <Tab>Cash Flow Statement</Tab>
          <Tab>Custom Reports</Tab>
        </TabList>

        <TabPanels>
          {/* Balance Sheet */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaBalanceScale}
                name="Total Assets"
                value="200,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaBalanceScale}
                name="Total Liabilities"
                value="80,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaBalanceScale}
                name="Total Equity"
                value="120,000"
                backgroundColor="#4299E1"
                type="money"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={financialData}
                title="Balance Sheet"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>

          {/* Income Statement */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaMoneyBillWave}
                name="Total Revenue"
                value="200,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaMoneyBillWave}
                name="Total Expenses"
                value="120,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaMoneyBillWave}
                name="Net Profit"
                value="80,000"
                backgroundColor="#4299E1"
                type="money"
              />
            </SimpleGrid>
            <Box mt={8}>
              <Line data={profitLossChart} />
            </Box>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={financialData}
                title="Income Statement"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>

          {/* Cash Flow Statement */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaFileAlt}
                name="Total Cash Inflow"
                value="150,000"
                backgroundColor="#68D391"
                type="money"
              />
              <DashboardBox
                icon={FaFileAlt}
                name="Total Cash Outflow"
                value="100,000"
                backgroundColor="#FC8181"
                type="money"
              />
              <DashboardBox
                icon={FaFileAlt}
                name="Net Cash Flow"
                value="50,000"
                backgroundColor="#4299E1"
                type="money"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={financialData}
                title="Cash Flow Statement"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>

          {/* Custom Reports */}
          <TabPanel>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5}>
              <DashboardBox
                icon={FaFileAlt}
                name="Custom Report 1"
                value="Detail 1"
                backgroundColor="#68D391"
                type="text"
              />
              <DashboardBox
                icon={FaFileAlt}
                name="Custom Report 2"
                value="Detail 2"
                backgroundColor="#FC8181"
                type="text"
              />
            </SimpleGrid>
            <Box mt={8}>
              <GenericGrid
                columns={columns}
                data={financialData}
                title="Custom Reports"
                visibleColumnsState={visibleColumns}
                onVisibleColumnsChange={setVisibleColumns}
                filterState={{}}
                onSort={() => {}}
                sortConfig={{}}
                maxHeight="400px"
                onRowClick={() => {}}
                onSelectedRowsChange={() => {}}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default FinancialStatements;
