import { createSlice } from "@reduxjs/toolkit";

// Initial dummy data for materials
const initialMaterials = [
  {
    id: 1,
    name: "Cement",
    type: "Construction",
    priceComparison: [
      {
        shopName: "Shop A",
        price: 50,
        lastPurchasedDate: "2023-06-01",
        invoiceNo: "INV123",
      },
      {
        shopName: "Shop B",
        price: 55,
        lastPurchasedDate: "2023-06-10",
        invoiceNo: "INV124",
      },
    ],
  },
  {
    id: 2,
    name: "Steel Rods",
    type: "Construction",
    priceComparison: [
      {
        shopName: "Shop C",
        price: 100,
        lastPurchasedDate: "2023-05-15",
        invoiceNo: "INV125",
      },
      {
        shopName: "Shop D",
        price: 95,
        lastPurchasedDate: "2023-06-05",
        invoiceNo: "INV126",
      },
    ],
  },
  {
    id: 3,
    name: "Paint",
    type: "Finishing",
    priceComparison: [
      {
        shopName: "Shop E",
        price: 20,
        lastPurchasedDate: "2023-05-20",
        invoiceNo: "INV127",
      },
      {
        shopName: "Shop F",
        price: 18,
        lastPurchasedDate: "2023-06-12",
        invoiceNo: "INV128",
      },
    ],
  },
];

const materialsSlice = createSlice({
  name: "materials",
  initialState: initialMaterials,
  reducers: {
    addMaterial: (state, action) => {
      state.push(action.payload);
    },
    updateMaterial: (state, action) => {
      const index = state.findIndex(
        (material) => material.id === action.payload.id
      );
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
  },
});

export const { addMaterial, updateMaterial } = materialsSlice.actions;
export const selectMaterials = (state) => state.materials;
export const selectMaterialById = (state, id) =>
  state.materials.find((material) => material.id === id);
export default materialsSlice.reducer;
