import { createSlice } from "@reduxjs/toolkit";

const initialVehicles = [
  {
    sno: 1,
    type: "Car",
    plateNo: "ABC123",
    mulkyaExpiry: "2023-12-31",
    insuranceExpiry: "2023-11-30",
    maintenanceCost: 500,
    purchasePrice: 20000,
    mulkyaRenewalCost: 300,
    driverName: "John Doe",
    dateHandover: "2023-01-01",
    dateReturn: "2023-12-01",
    finesCount: 2,
    todoMaintenance: "Oil Change",
    totalCost: 100,
    driverHistory: [
      {
        driverName: "John Doe",
        dateHandover: "2023-01-01",
        dateReturn: "2023-12-01",
      },
    ],
    fines: [{ amount: 100, date: "2023-06-01", reason: "Speeding" }],
  },
  {
    sno: 2,
    type: "Tipper",
    plateNo: "XYZ456",
    mulkyaExpiry: "2024-01-15",
    insuranceExpiry: "2024-01-10",
    maintenanceCost: 700,
    purchasePrice: 50000,
    mulkyaRenewalCost: 400,
    driverName: "Jane Smith",
    dateHandover: "2023-02-01",
    dateReturn: "2023-11-15",
    finesCount: 1,
    todoMaintenance: "Brake Check",
    totalCost: 150,
    driverHistory: [
      {
        driverName: "Jane Smith",
        dateHandover: "2023-02-01",
        dateReturn: "2023-11-15",
      },
    ],
    fines: [{ amount: 150, date: "2023-08-01", reason: "Parking" }],
  },
  {
    sno: 3,
    type: "HIACE",
    plateNo: "LMN789",
    mulkyaExpiry: "2023-10-20",
    insuranceExpiry: "2023-10-15",
    maintenanceCost: 300,
    purchasePrice: 30000,
    mulkyaRenewalCost: 350,
    driverName: "Mark Johnson",
    dateHandover: "2023-03-01",
    dateReturn: "2023-09-01",
    finesCount: 3,
    todoMaintenance: "Tire Replacement",
    totalCost: 200,
    driverHistory: [
      {
        driverName: "Mark Johnson",
        dateHandover: "2023-03-01",
        dateReturn: "2023-09-01",
      },
    ],
    fines: [{ amount: 200, date: "2023-07-01", reason: "Overloading" }],
  },
];

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: initialVehicles,
  reducers: {
    addFine: (state, action) => {
      const { sno, fine } = action.payload;
      const vehicle = state.find((v) => v.sno === sno);
      if (vehicle) {
        vehicle.fines.push(fine);
        vehicle.finesCount += 1;
      }
    },
    changeDriver: (state, action) => {
      const { sno, newDriver } = action.payload;
      const vehicle = state.find((v) => v.sno === sno);
      if (vehicle) {
        vehicle.driverHistory.push({
          driverName: vehicle.driverName,
          dateHandover: vehicle.dateHandover,
          dateReturn: new Date().toISOString().split("T")[0],
        });
        vehicle.driverName = newDriver;
        vehicle.dateHandover = new Date().toISOString().split("T")[0];
      }
    },
    addMaintenance: (state, action) => {
      const { sno, maintenance } = action.payload;
      const vehicle = state.find((v) => v.sno === sno);
      if (vehicle) {
        vehicle.todoMaintenance = maintenance;
      }
    },
  },
});

export const { addFine, changeDriver, addMaintenance } = vehiclesSlice.actions;
export const selectVehicles = (state) => state.vehicles;
export const selectVehicleBySno = (state, sno) =>
  state.vehicles.find((v) => v.sno === sno);

export default vehiclesSlice.reducer;
