import { createSlice } from "@reduxjs/toolkit";

const initialEmployees = [
  {
    sno: 1,
    empName: "Ahmad",
    designation: "Engineer",
    visa: "H1B",
    passportNo: "A12345678",
    nationality: "Pakistan",
    passportExpiry: "2025-12-15",
    visaExpiry: "2024-07-01",
    selfContactNo: "+92 300 1234567",
    familyContactNo: "+92 300 1234568",
    performance: "Good",
    photo: "https://via.placeholder.com/100",
  },
  {
    sno: 2,
    empName: "Zubair",
    designation: "Engineer",
    visa: "H1B",
    passportNo: "B23456789",
    nationality: "Saudi Arabia",
    passportExpiry: "2026-11-20",
    visaExpiry: "2023-05-12",
    selfContactNo: "+966 555 123456",
    familyContactNo: "+966 555 123457",
    performance: "Excellent",
    photo: "https://via.placeholder.com/100",
  },
  {
    sno: 3,
    empName: "Aslam",
    designation: "Technician",
    visa: "L1",
    passportNo: "C34567890",
    nationality: "Malaysia",
    passportExpiry: "2024-10-10",
    visaExpiry: "2023-09-15",
    selfContactNo: "+60 12 3456789",
    familyContactNo: "+60 12 3456790",
    performance: "Satisfactory",
    photo: "https://via.placeholder.com/100",
  },
  {
    sno: 4,
    empName: "Fatima",
    designation: "Manager",
    visa: "B1",
    passportNo: "D45678901",
    nationality: "Indonesia",
    passportExpiry: "2025-01-25",
    visaExpiry: "2024-02-28",
    selfContactNo: "+62 811 123456",
    familyContactNo: "+62 811 123457",
    performance: "Good",
    photo: "https://via.placeholder.com/100",
  },
  {
    sno: 5,
    empName: "Yusuf",
    designation: "Consultant",
    visa: "E2",
    passportNo: "E56789012",
    nationality: "Turkey",
    passportExpiry: "2023-08-30",
    visaExpiry: "2023-12-05",
    selfContactNo: "+90 532 1234567",
    familyContactNo: "+90 532 1234568",
    performance: "Excellent",
    photo: "https://via.placeholder.com/100",
  },
  // Add more dummy data as required
];

const employeeSlice = createSlice({
  name: "employees",
  initialState: initialEmployees,
  reducers: {
    addEmployee: (state, action) => {
      state.push(action.payload);
    },
    // Other reducers if necessary
  },
});

export const { addEmployee } = employeeSlice.actions;

export const selectEmployees = (state) => state.employees;

export const selectEmployeeById = (state, id) =>
  state.employees.find((employee) => employee.id === parseInt(id, 10));

export default employeeSlice.reducer;
