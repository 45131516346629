import React from "react";
import { Box, Center, Heading, VStack, Image, Flex } from "@chakra-ui/react";
import Carousel from "../../commons/Carousel"; // Import the Carousel component
import companyLogo from "../../images/Logo-Header.png";

const AuthLayout = ({ title, children }) => {
  return (
    <Flex minHeight="100vh" align="center" justify="center" position="relative">
      {/* Carousel in the background */}
      <Carousel />

      {/* Dark overlay over the carousel */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="rgba(0, 0, 0, 0.5)" // Adjust the opacity as needed
        zIndex="0"
      />

      {/* Main content box */}
      <Box
        p={8}
        maxWidth="400px"
        width="100%"
        bg="white"
        boxShadow="lg"
        borderRadius="md"
        zIndex="1"
      >
        <Center mb={8}>
          <Image src={companyLogo} alt="Company Logo" width={200} />
        </Center>
        <Center mb={4}>
          <Heading size="lg">{title}</Heading>
        </Center>
        <VStack spacing={4}>{children}</VStack>
      </Box>
    </Flex>
  );
};

export default AuthLayout;
