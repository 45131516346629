// ProjectItem.js
import React from "react";
import { Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../styles/ProjectItem.css";

const ProjectItem = ({ project, isActive, onClick }) => {
  const activeProject = useSelector((state) => state.projectList.activeProject);

  return (
    <div
      className={`project-item ${isActive ? "active" : ""} ${
        activeProject?.name === project?.name ? "blinking-shadow active" : ""
      }`}
      onClick={onClick}
    >
      <div className="project-content">
        {isActive && (
          <>
            <div className="project-header-details">
              <strong>In Progress</strong>
              <Text fontWeight={"bold"} color="#00A006">
                + {project.agreementAmount} AED
              </Text>
            </div>
            <Image
              src={project.image}
              alt={project.title}
              style={{ width: "300px" }}
            />
          </>
        )}
        <h3
          style={{
            fontWeight: "bolder",
            fontSize: isActive ? "20px" : "",
            marginTop: "5px",
          }}
        >
          {project?.name}
        </h3>
        {isActive && (
          <div className="project-item-footer">
            <Text mt={5}>Current Stage</Text>
            <Text fontSize={16} fontWeight={"bold"} mb={5} color={"#00A097"}>
              BLOCK PLASTER, MEP CONDUITS
            </Text>
            <Link to={`/projects/${project.name}`}>
              <button>See Details</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectItem;
