import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Switch,
  VStack,
} from "@chakra-ui/react";
import { useDynamicTheme } from "./DynamicThemeProvider";

const ThemeToggle = () => {
  const { updateTheme, themeSettings } = useDynamicTheme();

  const handleThemeToggle = () => {
    updateTheme("theme", themeSettings.theme === "light" ? "dark" : "light");
  };
  const handleFontSizeChange = (value) => {
    if (value >= 16) {
      // dispatch(setFontSize(Number(value)));
      updateTheme("fontSize", `${value}px`);
    }
  };

  const handleFontFamilyChange = (e) => {
    const newPrimaryColor = e.target.value;
    // dispatch(setPrimaryColor(newPrimaryColor));
    updateTheme("fontFamily", newPrimaryColor);
  };

  const handlePrimaryColorChange = (e) => {
    const newPrimaryColor = e.target.value;
    // dispatch(setPrimaryColor(newPrimaryColor));
    updateTheme("primaryColor", newPrimaryColor);
  };

  return (
    <Box
      p={4}
      bg={themeSettings.theme === "light" ? "gray.100" : "gray.200"}
      borderRadius="md"
      shadow="md"
    >
      <VStack spacing={4}>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="theme-toggle" mb="0">
            Toggle to {themeSettings.theme === "light" ? "dark" : "light"} theme
          </FormLabel>
          <Switch
            id="theme-toggle"
            isChecked={themeSettings.theme === "dark"}
            onChange={handleThemeToggle}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Font Family:</FormLabel>
          <Input
            type="text"
            value={themeSettings.fontFamily}
            onChange={handleFontFamilyChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Font Size:</FormLabel>
          <NumberInput
            min={16}
            value={themeSettings.fontSize}
            onChange={handleFontSizeChange}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
        <FormControl>
          <FormLabel>Primary Color:</FormLabel>
          <Input
            type="text"
            value={themeSettings.primaryColor}
            onChange={handlePrimaryColorChange}
          />
        </FormControl>
        {/* Add more controls for other theme properties as needed */}
      </VStack>
    </Box>
  );
};

export default ThemeToggle;
