import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Switch,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericHeader from "../../../../commons/GenericHeader";
import SearchInput from "../../../../commons/SearchInput";
import GenericGrid from "../../../Procurement/GenericGrid";
import DashboardBox from "../../../../commons/DashboardBox";
import DatePicker from "react-datepicker";
import MonthSelector from "../../../../commons/MonthSelector";
import YearSelector from "../../../../commons/YearSelector";
import { useNavigate } from "react-router-dom";
const PayrollList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([
    {
      id: 1,
      employee: "John Doe",
      hireDate: "2022-05-15",
      totalHours: 160,
      bonus: 200,
      deduction: 50,
      ratePerHour: 20,
    },
    {
      id: 2,
      employee: "Jane Smith",
      hireDate: "2021-03-22",
      totalHours: 180,
      bonus: 150,
      deduction: 100,
      ratePerHour: 22,
    },
    {
      id: 3,
      employee: "Alice Johnson",
      hireDate: "2023-07-19",
      totalHours: 140,
      bonus: 300,
      deduction: 50,
      ratePerHour: 18,
    },
    {
      id: 4,
      employee: "Bob Brown",
      hireDate: "2020-11-05",
      totalHours: 200,
      bonus: 250,
      deduction: 75,
      ratePerHour: 25,
    },
  ]);
  const [originalData] = useState(rowData);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(rowData);

  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const {
    isOpen: isProcessSalariesOpen,
    onOpen: onOpenProcessSalaries,
    onClose: onCloseProcessSalaries,
  } = useDisclosure();

  const {
    isOpen: isAddBonusOpen,
    onOpen: onOpenAddBonus,
    onClose: onCloseAddBonus,
  } = useDisclosure();

  const {
    isOpen: isAddDeductionOpen,
    onOpen: onOpenAddDeduction,
    onClose: onCloseAddDeduction,
  } = useDisclosure();

  const [selectedEmployee, setSelectedEmployee] = useState("");

  const filters = useMemo(
    () => ({
      employee: selectedEmployee,
    }),
    [selectedEmployee]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedEmployee = selectedEmployee;

    switch (filterType) {
      case "employee":
        newSelectedEmployee = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return !newSelectedEmployee || row.employee === newSelectedEmployee;
    });

    setSelectedEmployee(newSelectedEmployee);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return !selectedEmployee || row.employee === selectedEmployee;
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedEmployee("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const payrollColumns = [
    {
      id: "id",
      header: "ID",
      accessor: (item) => item.id,
      show: true,
      width: 20,
    },
    {
      id: "employee",
      header: "Employee Name",
      accessor: (item) => item.employee,
      show: true,
      width: 150,
    },
    {
      id: "hireDate",
      header: "Hire Date",
      accessor: (item) => item.hireDate,
      show: true,
      width: 150,
    },
    {
      id: "totalHours",
      header: "Total Hours",
      accessor: (item) => item.totalHours,
      show: true,
      width: 100,
    },
    {
      id: "ratePerHour",
      header: "Rate Per Hour",
      accessor: (item) => item.ratePerHour,
      show: true,
      width: 100,
    },
    {
      id: "bonus",
      header: "Bonus",
      accessor: (item) => item.bonus,
      show: true,
      width: 100,
    },
    {
      id: "deduction",
      header: "Deduction",
      accessor: (item) => item.deduction,
      show: true,
      width: 100,
    },
    {
      id: "netSalary",
      header: "Net Salary",
      accessor: (item) =>
        item.totalHours * item.ratePerHour + item.bonus - item.deduction,
      show: true,
      width: 100,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    payrollColumns.filter((col) => col.show).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const totalSalariesPaid = useMemo(
    () =>
      rowData.reduce(
        (total, row) =>
          total + row.totalHours * row.ratePerHour + row.bonus - row.deduction,
        0
      ),
    [rowData]
  );

  const salariesDue = useMemo(
    () => rowData.reduce((total, row) => total + row.deduction, 0),
    [rowData]
  );

  const handleProcessSalaries = () => {
    toast({
      title: "Salaries Processed",
      description: "All salaries have been processed successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseProcessSalaries();
  };
  const handleRowClick = (row) => {
    navigate(`/payroll/details/${row.employee.id}`);
  };
  return (
    <Box>
      <GenericHeader title="Payroll List" icon={<AddIcon />} />

      {/* Dashboard Boxes */}
      <Flex justifyContent="space-between" mb={4} mt={4} gap={2}>
        <DashboardBox
          icon={AddIcon} // Use a relevant icon
          name="Total Salaries Paid"
          value={totalSalariesPaid}
          type="money"
          backgroundColor="teal.400"
        />
        <DashboardBox
          icon={AddIcon} // Use a relevant icon
          name="Salaries Due"
          value={salariesDue}
          type="money"
          backgroundColor="orange.300"
        />
      </Flex>

      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Payroll"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>

      {/* Additional Action Buttons */}
      <Flex justifyContent={"space-between"}>
        <Flex gap={2}>
          <YearSelector />
          <MonthSelector />
        </Flex>
        <Flex justifyContent="flex-end" mb={4} gap={2}>
          <Button colorScheme="green" onClick={onOpenProcessSalaries}>
            Process Salaries
          </Button>
          <Button colorScheme="blue" onClick={onOpenAddBonus}>
            Add Bonus
          </Button>
          <Button colorScheme="red" onClick={onOpenAddDeduction}>
            Add Deduction
          </Button>
        </Flex>
      </Flex>

      <Flex mb={4}>
        <Flex>
          {filters.employee && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Employee: ${filters.employee}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("employee")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Employee"
                onChange={(e) => setSelectedEmployee(e.target.value)}
                mb={4}
                value={selectedEmployee}
              >
                {originalData.map((row) => (
                  <option key={row.employee} value={row.employee}>
                    {row.employee}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {payrollColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Drawer
        isOpen={isProcessSalariesOpen}
        placement="right"
        onClose={onCloseProcessSalaries}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Process Salaries</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Processing Date</FormLabel>
                <Input type="date" />
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onCloseProcessSalaries}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleProcessSalaries}>
              Process
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isAddBonusOpen}
        placement="right"
        onClose={onCloseAddBonus}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Add Bonus</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Bonus Amount</FormLabel>
                <Input type="number" />
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onCloseAddBonus}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer
        isOpen={isAddDeductionOpen}
        placement="right"
        onClose={onCloseAddDeduction}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Add Deduction</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Deduction Amount</FormLabel>
                <Input type="number" />
              </FormControl>
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onCloseAddDeduction}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Box>
        <GenericGrid
          columns={payrollColumns}
          data={filteredData}
          title="Payroll List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default PayrollList;
