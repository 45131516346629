import { Box, Text } from "@chakra-ui/react";
import React from "react";
import SelectBox from "../../commons/SelectBox";

const CustomSelectEditor = ({ value, onValueChange, selectOptions }) => {
  console.log(selectOptions);
  const options = [
    { label: "Option 1", value: "Option 1" },
    { label: "Option 2", value: "Option 2" },
    // Add more options as needed
  ];

  const handleSelectChange = (selectedOption) => {
    onValueChange(selectedOption.value);
  };

  return (
    <Box>
      <Text>Change the value</Text>
      <SelectBox
        options={selectOptions ? selectOptions : options}
        placeholder="Select Work Types"
        onChange={handleSelectChange}
        value={value ? { label: value, value } : ""}
      />
    </Box>
  );
};

export default CustomSelectEditor;
