import React from "react";
import {
  Box,
  Text,
  Image,
  Divider,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Th,
} from "@chakra-ui/react";
import logoImg from "../images/Logo-Header.png"; // Import the logo

const QuotationReceipt = ({ formData, items, totalAmount }) => {
  return (
    <Box
      p={6}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      background="white"
      maxWidth="800px"
      mx="auto"
      boxShadow="lg"
    >
      {/* Company Header */}
      <Box textAlign="center" mb={6}>
        <Image src={logoImg} alt="Company Logo" boxSize="150px" mx="auto" />
        <Text fontSize="2xl" fontWeight="bold" mt={2}>
          Company Name
        </Text>
        <Text fontSize="md" color="gray.500">
          1234 Company St, Business City, Country
        </Text>
        <Text fontSize="md" color="gray.500">
          Phone: +123 456 789 | Email: info@company.com
        </Text>
      </Box>

      <Divider my={4} />

      {/* Quotation Details */}
      <Box mb={6}>
        <Text fontSize="xl" fontWeight="bold" textAlign="center">
          Quotation
        </Text>
        <Divider my={4} />

        {/* Client & Project Information */}
        <Box mb={4}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Client Information
          </Text>
          <Text>
            <strong>Client Name:</strong> {formData.clientName}
          </Text>
          <Text>
            <strong>Project Name:</strong> {formData.projectName}
          </Text>
          <Text>
            <strong>Date:</strong> {formData.date}
          </Text>
          <Text>
            <strong>Status:</strong> {formData.status}
          </Text>
        </Box>
      </Box>

      <Divider my={4} />

      {/* Quoted Items */}
      <Box mb={6}>
        <Text fontSize="lg" fontWeight="bold" mb={4}>
          Quoted Items
        </Text>

        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Description</Th>
              <Th>Quantity</Th>
              <Th>Unit Price (AED)</Th>
              <Th>VAT (5%)</Th>
              <Th>Total (AED)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item, index) => (
              <Tr key={index}>
                <Td>{item.description}</Td>
                <Td>{item.quantity}</Td>
                <Td>{item.unitPrice.toFixed(2)}</Td>
                <Td>{(item.unitPrice * item.quantity * 0.05).toFixed(2)}</Td>
                <Td>{item.total.toFixed(2)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Divider my={4} />

      {/* Total Amount */}
      <Box textAlign="right">
        <Text fontSize="2xl" fontWeight="bold" color="gray.700">
          Total Amount: {totalAmount.toFixed(2)} AED
        </Text>
      </Box>

      <Divider my={4} />

      {/* Footer Note */}
      <Box textAlign="center" mt={6}>
        <Text fontSize="md" color="gray.500">
          Thank you for your business!
        </Text>
        <Text fontSize="md" color="gray.500">
          This is a system-generated quotation. No signature required.
        </Text>
      </Box>
    </Box>
  );
};

export default QuotationReceipt;
