import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Switch,
  Text,
  Image,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ReactToPrint from "react-to-print";
import PrintableInvoice from "./PrintableInvoice";
import { IoPrint } from "react-icons/io5";
import {
  IoAddCircleOutline,
  IoArrowBackCircleOutline,
  IoTrash,
} from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { addBill } from "../../store/billsSlice";
import { v4 as uuidv4 } from "uuid";
import CreatableSelect from "react-select/creatable";

const CreatableSelectComponent = ({ options, onCreateOption }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (newValue) => {
    setSelectedOptions(newValue);
  };

  const handleCreate = (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    onCreateOption(newOption);
    setSelectedOptions([...selectedOptions, newOption]);
  };

  const formatCreateLabel = (inputValue) => `Add "${inputValue}"`;

  return (
    <CreatableSelect
      isClearable
      isMulti
      value={selectedOptions}
      options={options}
      onChange={handleChange}
      onCreateOption={handleCreate}
      formatCreateLabel={formatCreateLabel}
    />
  );
};

const AddBill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const printRef = useRef();

  // Dummy data for selection options
  const suppliers = [
    { id: 1, name: "Supplier A" },
    { id: 2, name: "Supplier B" },
  ];
  const buyers = [
    { id: 1, name: "Buyer A" },
    { id: 2, name: "Buyer B" },
  ];
  const projects = [
    { id: 1, name: "Project A" },
    { id: 2, name: "Project B" },
  ];
  const items = [
    { id: 1, name: "Item A" },
    { id: 2, name: "Item B" },
  ];

  const [invoiceNo] = useState(uuidv4());
  const [date, setDate] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [stage, setStage] = useState("");
  const [image, setImage] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [unitRate, setUnitRate] = useState(0);
  const [uploadOnly, setUploadOnly] = useState(true);
  const [itemList, setItemList] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [tags, setTags] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [transportCost, setTransportCost] = useState(0);
  const [isCredit, setIsCredit] = useState(false);
  const [creditPayments, setCreditPayments] = useState([]);

  const handleAddItem = () => {
    if (!selectedItem || quantity <= 0 || unitRate <= 0) {
      toast({
        title: "Please enter valid item details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newItem = {
      item: selectedItem,
      quantity: parseFloat(quantity),
      unitRate: parseFloat(unitRate),
      vat: parseFloat(quantity) * parseFloat(unitRate) * 0.05,
      totalAmount: parseFloat(quantity) * parseFloat(unitRate),
    };

    setItemList([...itemList, newItem]);
    setSelectedItem("");
    setQuantity(0);
    setUnitRate(0);
  };

  const handleDeleteItem = (index) => {
    const newItemList = [...itemList];
    newItemList.splice(index, 1);
    setItemList(newItemList);
  };

  const totalAmount = itemList.reduce((sum, item) => sum + item.totalAmount, 0);
  const totalVAT = itemList.reduce((sum, item) => sum + item.vat, 0);
  const netTotal =
    totalAmount + totalVAT - parseFloat(discount) - parseFloat(transportCost);

  const handleAddBill = () => {
    if (!date || !selectedSupplier || !selectedBuyer || !selectedProject) {
      toast({
        title: "Please fill in all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const newBill = {
      invoiceNo,
      date,
      supplierName: selectedSupplier,
      buyer: selectedBuyer,
      projectSite: selectedProject,
      stageName: stage,
      status: isCredit ? "Credit" : "Paid",
      amount: netTotal,
      image,
      items: itemList,
      discount,
      transportCost,
      creditPayments: isCredit ? creditPayments : [],
      remarks,
      tags: tags.map((tag) => tag.value),
    };

    dispatch(addBill(newBill));
    navigate("/bills");
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleAddCreditPayment = () => {
    setCreditPayments([...creditPayments, { amount: 0, date: "" }]);
  };

  const handleCreditPaymentChange = (index, field, value) => {
    const newCreditPayments = [...creditPayments];
    newCreditPayments[index][field] = value;
    setCreditPayments(newCreditPayments);
  };

  const handleDeleteCreditPayment = (index) => {
    const newCreditPayments = [...creditPayments];
    newCreditPayments.splice(index, 1);
    setCreditPayments(newCreditPayments);
  };

  const handleCreateTag = (newTag) => {
    setTags([...tags, newTag]);
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex alignItems="center" gap={4}>
          <IoArrowBackCircleOutline
            onClick={() => navigate("/bills")}
            color="#db8700"
            size={50}
            cursor="pointer"
          />
          <Text fontSize="2xl" fontWeight="bold">
            Add Bill
          </Text>
          <Text fontSize="lg" fontWeight="bold" color="gray.600">
            Invoice No: {invoiceNo}
          </Text>
        </Flex>
        <Button colorScheme="teal" onClick={onOpen}>
          Preview and Print
        </Button>
      </Flex>

      <Box
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <Flex gap={4}>
          <Box flex={1}>
            <FormControl mb={4}>
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Supplier</FormLabel>
              <Select
                value={selectedSupplier}
                onChange={(e) => setSelectedSupplier(e.target.value)}
              >
                <option value="" disabled>
                  Select Supplier
                </option>
                {suppliers.map((supplier) => (
                  <option key={supplier.id} value={supplier.name}>
                    {supplier.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Buyer</FormLabel>
              <Select
                value={selectedBuyer}
                onChange={(e) => setSelectedBuyer(e.target.value)}
              >
                <option value="" disabled>
                  Select Buyer
                </option>
                {buyers.map((buyer) => (
                  <option key={buyer.id} value={buyer.name}>
                    {buyer.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Project</FormLabel>
              <Select
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
              >
                <option value="" disabled>
                  Select Project
                </option>
                {projects.map((project) => (
                  <option key={project.id} value={project.name}>
                    {project.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Stage</FormLabel>
              <Select value={stage} onChange={(e) => setStage(e.target.value)}>
                <option value="" disabled>
                  Select Stage
                </option>
                <option value="Stage 1">Stage 1</option>
                <option value="Stage 2">Stage 2</option>
                <option value="Stage 3">Stage 3</option>
              </Select>
            </FormControl>

            <Box
              mb={4}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              backgroundColor="gray.50"
            >
              <Text fontWeight="bold" mb={4}>
                Add Items
              </Text>
              <Flex gap={4}>
                <FormControl flex={1}>
                  <FormLabel>Item</FormLabel>
                  <Select
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Item
                    </option>
                    {items.map((item) => (
                      <option key={item.id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl flex={1}>
                  <FormLabel>Quantity</FormLabel>
                  <Input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </FormControl>

                <FormControl flex={1}>
                  <FormLabel>Unit Rate</FormLabel>
                  <Input
                    type="number"
                    value={unitRate}
                    onChange={(e) => setUnitRate(e.target.value)}
                  />
                </FormControl>
              </Flex>

              <Button mt={4} colorScheme="teal" onClick={handleAddItem}>
                Add Item
              </Button>
            </Box>

            <Table variant="simple" mb={4}>
              <Thead>
                <Tr>
                  <Th>S.No</Th>
                  <Th>Item</Th>
                  <Th>Quantity</Th>
                  <Th>Unit Rate</Th>
                  <Th>VAT</Th>
                  <Th>Total Amount</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {itemList.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{item.item}</Td>
                    <Td>{item.quantity}</Td>
                    <Td>{item.unitRate}</Td>
                    <Td>{item.vat.toFixed(2)}</Td>
                    <Td>{item.totalAmount.toFixed(2)}</Td>
                    <Td>
                      <IconButton
                        aria-label="Delete Item"
                        icon={<IoTrash />}
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleDeleteItem(index)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <Box
            flex={0.4}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            backgroundColor="gray.50"
          >
            <FormControl mb={4}>
              <FormLabel>Upload Image</FormLabel>
              <Box
                {...getRootProps()}
                border="1px dashed gray"
                p={4}
                textAlign="center"
                mb={4}
                cursor="pointer"
              >
                <input {...getInputProps()} />
                <Text>Drag 'n' drop or click to select files</Text>
              </Box>
              {image && (
                <Image
                  src={image}
                  alt="Uploaded"
                  boxSize="150px"
                  objectFit="cover"
                />
              )}
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Remarks</FormLabel>
              <Input
                type="text"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Enter remarks"
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Tags</FormLabel>
              <CreatableSelectComponent
                options={tags}
                onCreateOption={handleCreateTag}
              />
            </FormControl>

            <Box mb={4}>
              <Text fontWeight="bold">Total: {totalAmount.toFixed(2)}</Text>
              <Text fontWeight="bold">VAT: {totalVAT.toFixed(2)}</Text>
              <FormControl mt={4}>
                <FormLabel>Transport Cost</FormLabel>
                <Input
                  type="number"
                  value={transportCost}
                  onChange={(e) => setTransportCost(e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Discount</FormLabel>
                <Input
                  type="number"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </FormControl>
              <Text fontWeight="bold" mt={4}>
                NET TOTAL: {netTotal.toFixed(2)}
              </Text>
            </Box>

            <Box mt={4}>
              <Text fontWeight="bold">Credit</Text>
              <Switch
                isChecked={isCredit}
                onChange={() => setIsCredit(!isCredit)}
                mb={4}
              />
              {isCredit && (
                <Box>
                  {creditPayments.map((payment, index) => (
                    <Flex gap={4} key={index} mb={4}>
                      <FormControl>
                        <FormLabel>Amount</FormLabel>
                        <Input
                          type="number"
                          value={payment.amount}
                          onChange={(e) =>
                            handleCreditPaymentChange(
                              index,
                              "amount",
                              e.target.value
                            )
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Date</FormLabel>
                        <Input
                          type="date"
                          value={payment.date}
                          onChange={(e) =>
                            handleCreditPaymentChange(
                              index,
                              "date",
                              e.target.value
                            )
                          }
                        />
                      </FormControl>
                      <IconButton
                        aria-label="Delete Payment"
                        icon={<IoTrash />}
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleDeleteCreditPayment(index)}
                      />
                    </Flex>
                  ))}
                  <Button colorScheme="teal" onClick={handleAddCreditPayment}>
                    Add Payment
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Flex>

        <Flex justifyContent="center" mt={4}>
          <Button
            colorScheme="teal"
            leftIcon={<IoAddCircleOutline />}
            onClick={handleAddBill}
          >
            Save Bill
          </Button>
        </Flex>
      </Box>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Invoice Preview</DrawerHeader>

          <DrawerBody>
            <PrintableInvoice
              ref={printRef}
              billData={{
                invoiceNo,
                date,
                supplierName: selectedSupplier,
                buyer: selectedBuyer,
                projectSite: selectedProject,
                stageName: stage,
                items: itemList,
                discount: parseFloat(discount),
                transportCost: parseFloat(transportCost),
                amount: netTotal,
                remarks,
                tags: tags.map((tag) => tag.value),
              }}
            />
          </DrawerBody>

          <DrawerFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Close
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button colorScheme="teal" leftIcon={<IoPrint />}>
                  Print Invoice
                </Button>
              )}
              content={() => printRef.current}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default AddBill;
