import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  Button,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  FormControl,
  FormLabel,
  Switch,
  useToast,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericGrid from "../Procurement/GenericGrid";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import DashboardBox from "../../commons/DashboardBox";
import { TbSubtask } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import ImageGallery from "../../commons/ImageGallery";

const AttendanceRecord = () => {
  const employees = [
    {
      id: 1,
      photo: "https://via.placeholder.com/150",
      name: "Aslam",
      designation: "Engineer",
      timeIn: "",
      timeOut: "",
      forman: "Ahmed",
      site: "Site A",
    },
    {
      id: 2,
      photo: "https://via.placeholder.com/150",
      name: "Akram",
      designation: "Supervisor",
      timeIn: "",
      timeOut: "",
      forman: "Ali",
      site: "Site B",
    },
    {
      id: 3,
      photo: "https://via.placeholder.com/150",
      name: "Mohamed Ali",
      designation: "Technician",
      timeIn: "",
      timeOut: "",
      forman: "Osman",
      site: "Site C",
    },
    {
      id: 4,
      photo: "https://via.placeholder.com/150",
      name: "Wajid Khan",
      designation: "Manager",
      timeIn: "",
      timeOut: "",
      forman: "Ibrahim",
      site: "Site D",
    },
    {
      id: 5,
      photo: "https://via.placeholder.com/150",
      name: "Ahmed",
      designation: "Clerk",
      timeIn: "",
      timeOut: "",
      forman: "Hassan",
      site: "Site E",
    },
  ];

  const [rowData, setRowData] = useState(employees);
  const [originalData] = useState(employees);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(employees);
  const [timeModalType, setTimeModalType] = useState(null);
  const [time, setTime] = useState(new Date().toISOString().substring(0, 16));
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();

  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();

  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const {
    isOpen: isTimeModalOpen,
    onOpen: onOpenTimeModal,
    onClose: onCloseTimeModal,
  } = useDisclosure();

  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedSite, setSelectedSite] = useState("");

  const filters = useMemo(
    () => ({
      designation: selectedDesignation,
      site: selectedSite,
    }),
    [selectedDesignation, selectedSite]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedDesignation = selectedDesignation;
    let newSelectedSite = selectedSite;

    switch (filterType) {
      case "designation":
        newSelectedDesignation = "";
        break;
      case "site":
        newSelectedSite = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedDesignation ||
          row.designation === newSelectedDesignation) &&
        (!newSelectedSite || row.site === newSelectedSite)
      );
    });

    setSelectedDesignation(newSelectedDesignation);
    setSelectedSite(newSelectedSite);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedDesignation || row.designation === selectedDesignation) &&
        (!selectedSite || row.site === selectedSite)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedDesignation("");
    setSelectedSite("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const columns = [
    {
      id: "photo",
      header: "Photo",
      accessor: (item) => item.photo,
      render: (item) => (
        <Box minWidth={100} onClick={(e) => e.stopPropagation()}>
          <ImageGallery images={[item.photo]} width={100} />
        </Box>
      ),
      show: true,
      minWidth: 100,
    },
    {
      id: "name",
      header: "Name",
      accessor: (item) => item.name,
      show: true,
      minWidth: 150,
    },
    {
      id: "designation",
      header: "Designation",
      accessor: (item) => item.designation,
      show: true,
      minWidth: 150,
    },
    {
      id: "timeIn",
      header: "Time In",
      accessor: (item) => item.timeIn,
      show: true,
      minWidth: 150,
    },
    {
      id: "timeOut",
      header: "Time Out",
      accessor: (item) => item.timeOut,
      show: true,
      minWidth: 150,
    },
    {
      id: "forman",
      header: "Forman",
      accessor: (item) => item.forman,
      show: true,
      minWidth: 150,
    },
    {
      id: "site",
      header: "Site",
      accessor: (item) => item.site,
      show: true,
      minWidth: 150,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    columns.filter((col) => col.show !== false).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleRowClick = (id) => {
    navigate(`/attendance/details/${id}`);
  };

  const handleTimeInOut = (type) => {
    setTimeModalType(type);
    onOpenTimeModal();
  };

  const handleSaveTime = () => {
    const updatedData = rowData.map((row) => {
      if (selectedRows.includes(row.id)) {
        if (timeModalType === "Time In") {
          return { ...row, timeIn: time };
        } else if (timeModalType === "Time Out") {
          return { ...row, timeOut: time };
        }
      }
      return row;
    });

    setRowData(updatedData);
    setFilteredData(updatedData);

    toast({
      title: `${timeModalType} saved`,
      description: `${timeModalType} has been recorded for the selected employees.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseTimeModal();
  };

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const isTimeInDisabled = selectedRows.every(
    (id) => rowData.find((row) => row.id === id).timeIn
  );
  const isTimeOutDisabled = selectedRows.every(
    (id) =>
      !rowData.find((row) => row.id === id).timeIn ||
      rowData.find((row) => row.id === id).timeOut
  );

  // Dashboard boxes
  const totalEmployees = employees.length;
  const totalPresent = filteredData.filter(
    (employee) => employee.timeIn && !employee.timeOut
  ).length;
  const averageTimeIn = "08:30 AM"; // Dummy average time in
  const averageTimeOut = "05:30 PM"; // Dummy average time out

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <GenericHeader title="Daily Attendance" icon={<TbSubtask />} />
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={TbSubtask}
            name="Total Employees"
            value={totalEmployees}
            backgroundColor="blue.300"
          />
          <DashboardBox
            icon={TbSubtask}
            name="Total Present"
            value={totalPresent}
            backgroundColor="green.300"
          />
          <DashboardBox
            icon={TbSubtask}
            name="Average Time In"
            value={averageTimeIn}
            backgroundColor="yellow.300"
          />
          <DashboardBox
            icon={TbSubtask}
            name="Average Time Out"
            value={averageTimeOut}
            backgroundColor="red.300"
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button
          colorScheme="teal"
          leftIcon={<AddIcon />}
          onClick={() => handleTimeInOut("Time In")}
          isDisabled={isTimeInDisabled || selectedRows.length === 0}
        >
          Time In
        </Button>
        <Button
          colorScheme="orange"
          leftIcon={<AddIcon />}
          onClick={() => handleTimeInOut("Time Out")}
          isDisabled={isTimeOutDisabled || selectedRows.length === 0}
        >
          Time Out
        </Button>
        <Button onClick={onOpenFilterModal}>Filter</Button>
        <SearchInput
          placeholder="Search Employees"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.designation && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Designation: ${filters.designation}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("designation")} />
            </Tag>
          )}
          {filters.site && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Site: ${filters.site}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("site")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Designation"
                onChange={(e) => setSelectedDesignation(e.target.value)}
                mb={4}
                value={selectedDesignation}
              >
                {employees.map((employee) => (
                  <option
                    key={employee.designation}
                    value={employee.designation}
                  >
                    {employee.designation}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Site"
                onChange={(e) => setSelectedSite(e.target.value)}
                mb={4}
                value={selectedSite}
              >
                {employees.map((employee) => (
                  <option key={employee.site} value={employee.site}>
                    {employee.site}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {columns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isTimeModalOpen} onClose={onCloseTimeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Record ${timeModalType}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="datetime-local"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleSaveTime}>
              Save
            </Button>
            <Button variant="ghost" onClick={onCloseTimeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={columns}
          data={filteredData}
          title="Attendance List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={setSortConfig}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
          onSelectedRowsChange={handleSelectedRowsChange}
        />
      </Box>
    </Box>
  );
};

export default AttendanceRecord;
