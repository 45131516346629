import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  useColorModeValue,
  useToast,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { IoArrowBackCircleOutline, IoAddCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectSubcontractors,
  updateSubcontractor,
  addSubcontractor,
  deletePayment,
} from "../../store/subcontractorsSlice";
import SelectBox from "../../commons/SelectBox";
import SubcontractorAgreement from "./SubcontractorAgreement";
import PrimaryButton from "../../commons/PrimaryButton";

const SubcontractorForm = ({ isEditMode = false }) => {
  const { id } = useParams();
  const subcontractors = useSelector(selectSubcontractors);
  const existingSubcontractor = subcontractors.find(
    (sc) => sc.sno === parseInt(id)
  );
  const [date, setDate] = useState(
    isEditMode ? existingSubcontractor.date : ""
  );
  const [siteName, setSiteName] = useState(
    isEditMode ? existingSubcontractor.siteName : ""
  );
  const [typeOfWork, setTypeOfWork] = useState(
    isEditMode ? existingSubcontractor.typeOfWork : ""
  );
  const [agreementAmount, setAgreementAmount] = useState(
    isEditMode ? existingSubcontractor.agreementAmount : 0
  );
  const [totalPaid, setTotalPaid] = useState(
    isEditMode ? existingSubcontractor.totalPaid : 0
  );
  const [payments, setPayments] = useState(
    isEditMode ? existingSubcontractor.paymentsPlan : []
  );
  const [paymentWork, setPaymentWork] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isEditMode) {
      setDate(existingSubcontractor.date);
      setSiteName(existingSubcontractor.siteName);
      setTypeOfWork(existingSubcontractor.typeOfWork);
      setAgreementAmount(existingSubcontractor.agreementAmount);
      setTotalPaid(existingSubcontractor.totalPaid);
      setPayments(existingSubcontractor.paymentsPlan);
    }
  }, [isEditMode, existingSubcontractor]);

  const handleAddPayment = () => {
    const newPayment = {
      work: paymentWork,
      amount: paymentAmount,
      paid: false,
    };
    setPayments([...payments, newPayment]);
    setPaymentWork("");
    setPaymentAmount(0);
  };

  const handleDeletePayment = (index) => {
    const updatedPayments = payments.filter((_, i) => i !== index);
    setPayments(updatedPayments);
    if (isEditMode) {
      dispatch(deletePayment({ sno: parseInt(id), paymentIndex: index }));
    }
  };

  const handlePaidPayment = (index) => {
    const updatedPayments = payments.map((payment, i) =>
      i === index ? { ...payment, paid: true } : payment
    );
    const paidPayment = payments[index];
    setPayments(updatedPayments);
    setTotalPaid(totalPaid + paidPayment.amount);
    toast({
      title: "Payment marked as paid.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSave = () => {
    const updatedData = {
      date,
      siteName,
      typeOfWork,
      agreementAmount,
      totalPaid,
      balance: agreementAmount - totalPaid,
      paymentsPlan: payments,
    };

    if (isEditMode) {
      dispatch(updateSubcontractor({ sno: parseInt(id), updatedData }));
    } else {
      dispatch(addSubcontractor(updatedData));
    }

    navigate("/subcontractors");
  };

  const dummyOptions = [
    { value: "Steel-fix", label: "Steel Fix" },
    { value: "aluminium", label: "Aluminium work" },
    { value: "gates", label: "Gates Work" },
  ];

  const totalBalance = agreementAmount - totalPaid;

  const agreementData = {
    date,
    subcontractorName: "Subcontractor Name", // Replace with actual data when integrated
    siteName,
    typeOfWork,
    agreementAmount,
    totalPaid,
    totalBalance,
    workRequired: "Detailed work description goes here.", // Replace with actual work description
    payments,
  };

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Flex align="center" mb="4">
          <IoArrowBackCircleOutline
            onClick={() => navigate("/subcontractors")}
            color="#db8700"
            size={50}
            cursor="pointer"
          />
          <Heading as="h2" size="lg" ml="4">
            {isEditMode ? "Edit Subcontractor" : "Add Subcontractor"}
          </Heading>
        </Flex>
        <Button
          colorScheme="teal"
          onClick={onOpen}
          leftIcon={<IoAddCircleOutline />}
        >
          Preview & Print Agreement
        </Button>
      </Flex>

      <Box
        p={4}
        borderRadius="20"
        background={"white"}
        border={"3px solid gray"}
      >
        <Flex
          mb="4"
          flexWrap="wrap"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box flex="1" mr="4">
            <FormControl mb="4">
              <FormLabel>Date</FormLabel>
              <Input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </FormControl>
            <FormControl mb="4">
              <SelectBox
                label="Select Site"
                options={dummyOptions}
                placeholder="Select Site"
                value={siteName}
                onChange={(selected) => setSiteName(selected)}
              />
            </FormControl>
            <FormControl mb="4">
              <SelectBox
                label="Select Type of Work"
                options={dummyOptions}
                placeholder="Select Type of Work"
                value={typeOfWork}
                onChange={(selected) => setTypeOfWork(selected)}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Agreement Amount</FormLabel>
              <Input
                type="number"
                value={agreementAmount}
                onChange={(e) => setAgreementAmount(parseFloat(e.target.value))}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Total Paid</FormLabel>
              <Input
                type="number"
                value={totalPaid}
                onChange={(e) => setTotalPaid(parseFloat(e.target.value))}
              />
            </FormControl>
          </Box>

          <Box flex="1">
            <Box
              p="4"
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="md"
              mb="4"
              bg={useColorModeValue("gray.50", "gray.700")}
            >
              <Heading as="h4" size="md" mb="4">
                Payments Plan
              </Heading>
              <FormControl mb="4">
                <FormLabel>Work Description</FormLabel>
                <Input
                  type="text"
                  value={paymentWork}
                  onChange={(e) => setPaymentWork(e.target.value)}
                />
              </FormControl>
              <FormControl mb="4">
                <FormLabel>Amount</FormLabel>
                <Input
                  type="number"
                  value={paymentAmount}
                  onChange={(e) => setPaymentAmount(parseFloat(e.target.value))}
                />
              </FormControl>
              <Button
                colorScheme="gray"
                onClick={handleAddPayment}
                mb="4"
                width="full"
              >
                Add Payment
              </Button>
              <Box>
                <Table variant="simple" mt={4}>
                  <Thead>
                    <Tr>
                      <Th>Payment No</Th>
                      <Th>Work Description</Th>
                      <Th>Amount</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {payments.map((payment, index) => (
                      <Tr
                        key={index}
                        textDecoration={payment.paid ? "line-through" : "none"}
                        fontWeight={payment.paid ? "bold" : "normal"}
                      >
                        <Td>{index + 1}</Td>
                        <Td>{payment.work}</Td>
                        <Td>{payment.amount}</Td>
                        <Td>
                          <Button
                            colorScheme="teal"
                            size="sm"
                            mr="2"
                            onClick={() => handlePaidPayment(index)}
                          >
                            Paid
                          </Button>
                          <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => handleDeletePayment(index)}
                          >
                            Delete
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Box>
            <Box
              p="4"
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="md"
              bg={useColorModeValue("gray.50", "gray.700")}
            >
              <Heading as="h4" size="md" mb="4">
                Summary
              </Heading>
              <Flex justifyContent="space-between" mb="2">
                <Text>Total Paid</Text>
                <Text>{totalPaid}</Text>
              </Flex>
              <Flex justifyContent="space-between" mb="2">
                <Text>Balance</Text>
                <Text>{totalBalance}</Text>
              </Flex>
            </Box>
          </Box>
        </Flex>
        <Flex justifyContent={"center"}>
          <PrimaryButton
            title={isEditMode ? "Update Subcontractor" : "Add Subcontractor"}
            onClick={handleSave}
          ></PrimaryButton>
        </Flex>
      </Box>
      {/* Drawer for preview and print */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Agreement Preview</DrawerHeader>
          <DrawerBody>
            <SubcontractorAgreement agreementData={agreementData} />
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default SubcontractorForm;
