import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  NumberInput,
  NumberInputField,
  useToast,
} from "@chakra-ui/react";
import { IoAddCircleOutline, IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const AddVehicle = () => {
  const [vehicle, setVehicle] = useState({
    type: "",
    plateNo: "",
    mulkyaExpiry: "",
    insuranceExpiry: "",
    maintenanceCost: "",
    purchasePrice: "",
    mulkyaRenewalCost: "",
    serviceCost: "",
  });
  const [payments, setPayments] = useState([]);
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicle({
      ...vehicle,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically dispatch an action to save the vehicle
    console.log("Vehicle:", vehicle);
    console.log("Payments:", payments);
    toast({
      title: "Vehicle added.",
      description: "The vehicle has been added successfully.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    navigate("/vehicles");
  };

  return (
    <Box>
      <Flex gap={5} align="center" mb="4">
        <IoArrowBackCircleOutline
          onClick={() => navigate("/vehicles")}
          color="#db8700"
          size={50}
          cursor={"pointer"}
        />
        <Heading as="h2" size="lg">
          <Flex gap={5} align={"center"}>
            <Text>Add Vehicle</Text>
          </Flex>
        </Heading>
      </Flex>
      <Box
        bg="white"
        p={5}
        borderRadius="md"
        boxShadow="md"
        border="1px solid"
        borderColor="gray.200"
      >
        <form onSubmit={handleSubmit}>
          <Flex direction={{ base: "column", md: "row" }} gap={5}>
            <Box flex={1}>
              <FormControl mb={3} isRequired>
                <FormLabel>Type</FormLabel>
                <Select
                  name="type"
                  value={vehicle.type}
                  onChange={handleInputChange}
                >
                  <option value="">Select type</option>
                  <option value="Car">Car</option>
                  <option value="Tipper">Tipper</option>
                  <option value="HIACE">HIACE</option>
                </Select>
              </FormControl>
              <FormControl mb={3} isRequired>
                <FormLabel>Plate No</FormLabel>
                <Input
                  name="plateNo"
                  value={vehicle.plateNo}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mb={3} isRequired>
                <FormLabel>MULKYA EXPIRY</FormLabel>
                <Input
                  type="date"
                  name="mulkyaExpiry"
                  value={vehicle.mulkyaExpiry}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mb={3} isRequired>
                <FormLabel>INSURANCE EXPIRY</FormLabel>
                <Input
                  type="date"
                  name="insuranceExpiry"
                  value={vehicle.insuranceExpiry}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mb={3} isRequired>
                <FormLabel>MAINTENANCE COST</FormLabel>
                <NumberInput
                  min={0}
                  value={vehicle.maintenanceCost}
                  onChange={(valueString) =>
                    setVehicle({ ...vehicle, maintenanceCost: valueString })
                  }
                >
                  <NumberInputField name="maintenanceCost" />
                </NumberInput>
              </FormControl>
              <FormControl mb={3} isRequired>
                <FormLabel>PURCHASE PRICE</FormLabel>
                <NumberInput
                  min={0}
                  value={vehicle.purchasePrice}
                  onChange={(valueString) =>
                    setVehicle({ ...vehicle, purchasePrice: valueString })
                  }
                >
                  <NumberInputField name="purchasePrice" />
                </NumberInput>
              </FormControl>
            </Box>
            <Box flex={1}>
              <FormControl mb={3} isRequired>
                <FormLabel>ANNUAL OWNERSHIP MULKYA RENEWAL COST</FormLabel>
                <NumberInput
                  min={0}
                  value={vehicle.mulkyaRenewalCost}
                  onChange={(valueString) =>
                    setVehicle({ ...vehicle, mulkyaRenewalCost: valueString })
                  }
                >
                  <NumberInputField name="mulkyaRenewalCost" />
                </NumberInput>
              </FormControl>
              <FormControl mb={3} isRequired>
                <FormLabel>Service Cost</FormLabel>
                <NumberInput
                  min={0}
                  value={vehicle.serviceCost}
                  onChange={(valueString) =>
                    setVehicle({ ...vehicle, serviceCost: valueString })
                  }
                >
                  <NumberInputField name="serviceCost" />
                </NumberInput>
              </FormControl>
            </Box>
          </Flex>
          <Flex justifyContent={"center"}>
            <button className="btn-primary">
              <IoAddCircleOutline /> Save Vehicle
            </button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default AddVehicle;
