import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Box } from "@chakra-ui/react";

// Define dummy data for the table
const salaryTransactions = [
  {
    date: "2024-08-01",
    basicPay: 2000,
    bonus: 200,
    deduction: 50,
    overtime: 150,
    netSalary: 2300,
    accountNo: "123456789",
    bankName: "ABC Bank",
  },
  {
    date: "2024-08-02",
    basicPay: 2000,
    bonus: 100,
    deduction: 30,
    overtime: 100,
    netSalary: 2170,
    accountNo: "123456789",
    bankName: "ABC Bank",
  },
  {
    date: "2024-08-03",
    basicPay: 2000,
    bonus: 300,
    deduction: 0,
    overtime: 200,
    netSalary: 2500,
    accountNo: "123456789",
    bankName: "ABC Bank",
  },
  {
    date: "2024-08-04",
    basicPay: 2000,
    bonus: 150,
    deduction: 40,
    overtime: 120,
    netSalary: 2230,
    accountNo: "123456789",
    bankName: "ABC Bank",
  },
  {
    date: "2024-08-05",
    basicPay: 2000,
    bonus: 250,
    deduction: 20,
    overtime: 180,
    netSalary: 2410,
    accountNo: "123456789",
    bankName: "ABC Bank",
  },
];

const SalaryTransactionsTable = () => {
  return (
    <Box overflowX="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Basic Pay</Th>
            <Th>Bonus</Th>
            <Th>Deduction</Th>
            <Th>Overtime</Th>

            <Th>Account No</Th>
            <Th>Bank Name</Th>
            <Th>Net Salary</Th>
          </Tr>
        </Thead>
        <Tbody>
          {salaryTransactions.map((transaction, index) => (
            <Tr key={index}>
              <Td>{transaction.date}</Td>
              <Td>{transaction.basicPay} AED</Td>
              <Td>{transaction.bonus} AED</Td>
              <Td>{transaction.deduction} AED</Td>
              <Td>{transaction.overtime} AED</Td>
              <Td>{transaction.accountNo}</Td>
              <Td>{transaction.bankName}</Td>
              <Td fontWeight="bold">{transaction.netSalary} AED</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default SalaryTransactionsTable;
