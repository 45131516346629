import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "light",
  fontFamily: "Arial",
  fontSize: 16,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme(state) {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
    setFontFamily(state, action) {
      console.log(action.payload);
      state.fontFamily = action.payload;
    },
    setFontSize(state, action) {
      state.fontSize = action.payload;
    },
  },
});

export const { toggleTheme, setFontFamily, setFontSize } = themeSlice.actions;
export default themeSlice.reducer;
