import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { addMaterial } from "../../store/materialsSlice";
import GenericHeader from "../../commons/GenericHeader";
import { IoAddCircleOutline } from "react-icons/io5";

const AddMaterial = () => {
  const [formData, setFormData] = useState({ name: "", type: "" });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    const newMaterial = {
      id: Date.now(),
      ...formData,
      priceComparison: [],
    };
    dispatch(addMaterial(newMaterial));
  };

  return (
    <Box>
      <GenericHeader title="Add New Material" back={"/materials"} />
      <Box p={4} borderWidth="1px" borderRadius="md" background={"white"}>
        <Flex gap={4}>
          <FormControl mb={4} flex={1}>
            <FormLabel>Material Name</FormLabel>
            <Input
              placeholder="Material Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              mb={4}
            />
          </FormControl>
          <FormControl mb={4} flex={1}>
            <FormLabel>Material Type</FormLabel>
            <Input
              placeholder="Material Type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              mb={4}
            />
          </FormControl>
        </Flex>

        <Flex justifyContent={"center"}>
          <button className="btn-primary" onClick={handleSave}>
            <IoAddCircleOutline /> Add Material
          </button>
        </Flex>
      </Box>
    </Box>
  );
};

export default AddMaterial;
