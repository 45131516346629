import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

const SkeletonLoading = () => {
  return (
    <div className="skeleton-loading">
      <Skeleton count={5} />
    </div>
  );
};

export default SkeletonLoading;
