import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  VStack,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Button,
  Heading,
  Menu,
  HStack,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  background,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GenericHeader from "../../commons/GenericHeader";
import { AddIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import DashboardBox from "../../commons/DashboardBox";
import { FaCalendarAlt, FaClock, FaTasks, FaTrophy } from "react-icons/fa";
import "../../styles/CustomDatePicker.css"; // Import the custom CSS
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaMoneyBill } from "react-icons/fa6";
import SalaryTransactionsTable from "./SalaryTransactionsTable";

const EmployeeDetails = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const certModalDisclosure = useDisclosure();
  const [processLoading, setProcessLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedCert, setSelectedCert] = useState(null);
  const [basicAmount, setBasicAmount] = useState(1000);
  const [salaryAdvance, setSalaryAdvance] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [overtimeAmount, setOvertimeAmount] = useState(200);
  const [excellentWorkAllowance, setExcellentWorkAllowance] = useState(100);
  const absentDeduction = 100;

  const calculateNetSalary = () => {
    return (
      basicAmount +
      overtimeAmount +
      bonus +
      excellentWorkAllowance -
      salaryAdvance -
      absentDeduction
    );
  };

  const handleProcessSalary = () => {
    setProcessLoading(true);
    setTimeout(() => {
      setProcessLoading(false);
      // Display salary processed message
    }, 2000);
  };

  const handleDisableEmployee = () => {
    setIsDisabled(true);
  };

  const employee = {
    name: "Abu Bakar",
    rate: "1000 AED",
    designation: "Software Engineer",
    joiningDate: "2022-01-15",
    bank: "ABC Bank",
    accountNo: "123456789",
    workArea: "Dubai",
    laborCard: "LC123456",
    visa: "VISA123456",
    passportNo: "P123456789",
    nationality: "UAE",
    passportExpiry: "2025-12-31",
    visaExpiry: "2024-12-31",
    selfContact: "1234567890",
    familyContact: "0987654321",
    performance: "Excellent",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUIRuFTSeB2XwtcJ1gmYXSQLOrRjx43XEh_g&s",
  };

  const timesheet = Array.from({ length: 31 }, (_, index) => ({
    date: `2024-07-${index + 1}`,
    day: index + 1,
    inTime: "09:00",
    outTime: "18:00",
    breakTime: "1:00",
    workingHours: 8,
    overtime: 2,
    performance: "Good",
  }));

  const accommodationHistory = Array.from({ length: 5 }, (_, index) => ({
    date: `2024-07-${index + 1}`,
    roomNo: `A${index + 1}`,
    roomRate: `${1000 + index * 100} AED`,
  }));

  const leaveRequests = Array.from({ length: 3 }, (_, index) => ({
    dateFrom: `2024-07-${index + 1}`,
    dateTo: `2024-07-${index + 5}`,
    noOfDays: 5,
    reason: "Personal Leave",
    status: index === 0 ? "Approved" : "Requested",
  }));

  const certificates = [
    {
      type: "Appreciation",
      dateIssued: "2024-07-15",
      bgColor: "green.200",
      icon: FaTrophy,
    },
    {
      type: "Warning",
      dateIssued: "2024-06-15",
      bgColor: "red.200",
      icon: FaTasks,
    },
  ];

  const openDrawer = (type) => {
    setDrawerType(type);
    setIsDrawerOpen(true);
  };
  const openCertModal = (cert) => {
    setSelectedCert(cert);
    certModalDisclosure.onOpen();
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"], // remove formatting button
    ],
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "sub",
    "super",
    "header",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "direction",
    "align",
    "link",
    "image",
    "video",
  ];
  const performanceData = {
    labels: ["Performance"],
    datasets: [
      {
        label: "Performance",
        data: [80, 20],
        backgroundColor: ["#4CAF50", "#ddd"],
        cutout: "70%",
      },
    ],
  };

  return (
    <>
      {/* 1st Box */}
      <Flex justifyContent={"space-between"} alignItems="center">
        <Flex>
          <GenericHeader title="Employee Details" back={"/hr/employees"} />
          <Box>
            <Heading size="md" color="primary.500">
              For Month
            </Heading>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MMM, yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              className="react-datepicker-wrapper"
            />
          </Box>
        </Flex>
        <Button colorScheme="teal" onClick={openDrawer}>
          Edit Employee
        </Button>
      </Flex>

      <Box
        p={4}
        bg="white"
        borderRadius="md"
        mt={4}
        opacity={isDisabled ? 0.5 : 1}
      >
        {/* Employee Details */}
        <Flex justifyContent={"space-between"}>
          <Flex>
            <Image
              borderRadius="full"
              boxSize="100px"
              src={employee.imageUrl}
              alt={employee.name}
            />
            <Box ml={4}>
              <Text fontSize="2xl" fontWeight="bold">
                {employee.name}
              </Text>
              <Text>{employee.designation}</Text>
              <Text>
                Joined on:{" "}
                <Text as="span" fontWeight="bold" color="teal.500">
                  {employee.joiningDate}
                </Text>
              </Text>
              <Button mt={2} onClick={onOpen}>
                View Details
              </Button>
            </Box>
          </Flex>
          <Flex>
            <Box ml={4}>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Work Area:</Text>
                <Text fontWeight="bold">Site A</Text>
              </Flex>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Bank:</Text>
                <Text fontWeight="bold">{employee.bank}</Text>
              </Flex>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">A/C No:</Text>
                <Text fontWeight="bold">{employee.accountNo}</Text>
              </Flex>
            </Box>
          </Flex>
          <Flex>
            <Box ml={4}>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">PassPort No:</Text>
                <Text fontWeight="bold">{employee.passportNo}</Text>
              </Flex>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Labor Card No:</Text>
                <Text fontWeight="bold">{employee.laborCard}</Text>
              </Flex>
              <Flex justifyContent={"space-between"} flexDirection={"column"}>
                <Text fontSize="sm">Rate:</Text>
                <Text fontWeight="bold">{employee.rate}</Text>
              </Flex>
            </Box>
          </Flex>

          <Flex alignItems="center" mt={4}>
            <Flex alignItems="center" ml={4} gap={5}>
              <Box>
                <CircularProgressbar
                  value={80} // Percentage value
                  text={`Good`} // Text in the middle
                  styles={buildStyles({
                    textColor: "#4CAF50",
                    pathColor: "#4CAF50",
                    trailColor: "#ddd",
                  })}
                />
              </Box>
              <DashboardBox
                icon={FaTasks}
                name="Total Work Days"
                value="22"
                backgroundColor="#4a769d"
                type="number"
              />
              <DashboardBox
                icon={FaMoneyBill}
                name="Rate"
                value={employee.rate}
                backgroundColor="#738b19"
                type="text"
              />
            </Flex>
          </Flex>
        </Flex>

        {/* Employee Stats */}

        {/* Tabs for Accommodation, Leave Request, and Certificates */}
        <Tabs mt={4}>
          <TabList>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Accommodation
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Leave Request
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Certificates
            </Tab>
            <Tab
              borderRadius={20}
              _selected={{
                color: "white",
                bg: "primary.500",
              }}
            >
              Salary Transactions
            </Tab>
          </TabList>

          <TabPanels>
            {/* Accommodation Tab */}
            <TabPanel>
              <Button
                colorScheme="blue"
                onClick={() => openDrawer("accommodation")}
              >
                Add Accommodation
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Room No</Th>
                      <Th>Room Rate</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {accommodationHistory.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.date}</Td>
                        <Td>{entry.roomNo}</Td>
                        <Td>{entry.roomRate}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>

            {/* Leave Request Tab */}
            <TabPanel>
              <Button colorScheme="teal" onClick={() => openDrawer("leave")}>
                Add Leave Request
              </Button>
              <Box overflowY="auto" maxHeight="200px" mt={4}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Date From</Th>
                      <Th>Date To</Th>
                      <Th>No of Days</Th>
                      <Th>Reason</Th>
                      <Th>Status</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {leaveRequests.map((entry, index) => (
                      <Tr key={index}>
                        <Td>{entry.dateFrom}</Td>
                        <Td>{entry.dateTo}</Td>
                        <Td>{entry.noOfDays}</Td>
                        <Td>{entry.reason}</Td>
                        <Td>{entry.status}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>

            {/* Certificates Tab */}
            <TabPanel>
              <Button
                colorScheme="green"
                onClick={() => openDrawer("certificate")}
              >
                Add Certificate
              </Button>
              <Box mt={4}>
                <Flex gap={4}>
                  {certificates.map((cert, index) => (
                    <Box
                      key={index}
                      p={4}
                      bg={cert.bgColor}
                      borderRadius="md"
                      cursor="pointer"
                      onClick={() => openCertModal(cert)}
                      width="fit-content"
                    >
                      <Text fontSize="lg" fontWeight="bold">
                        {cert.type} Letter
                      </Text>
                      <Text>Date Issued: {cert.dateIssued}</Text>
                      <IconButton
                        aria-label="Certificate Icon"
                        icon={<cert.icon />}
                        isRound
                        size="lg"
                        variant="ghost"
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <SalaryTransactionsTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* 3rd Box */}
      <Box p={4} bg="white" borderRadius="md" mt={4}>
        <Heading as="h3" size="lg" mb={4}>
          Attendance Sheet
        </Heading>
        <Box overflowY="auto" maxHeight="500px">
          <Table variant="simple">
            <Thead position="sticky" top="0" zIndex="1" bg="white">
              <Tr>
                <Th>Date</Th>
                <Th>Day</Th>
                <Th>In</Th>
                <Th>Out</Th>
                <Th>Break Time</Th>
                <Th>Working Hours</Th>
                <Th>Overtime</Th>
                <Th>Performance</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {timesheet.map((entry, index) => (
                <Tr key={index}>
                  <Td>{entry.date}</Td>
                  <Td>{entry.day}</Td>
                  <Td>{entry.inTime}</Td>
                  <Td>{entry.outTime}</Td>
                  <Td>{entry.breakTime}</Td>
                  <Td>{entry.workingHours}</Td>
                  <Td>{entry.overtime}</Td>
                  <Td>{entry.performance}</Td>
                  <Td>
                    <Button colorScheme="green" size="sm">
                      Approve
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      {/* 4th Box */}
      <Box p={6} bg="white" borderRadius="lg" mt={6} boxShadow="lg">
        <Heading as="h3" size="lg" mb={6} color="teal.600" textAlign="center">
          Salary Calculation
        </Heading>
        <Flex justifyContent="space-between" alignItems="flex-start" gap={6}>
          <Flex
            flexDirection="column"
            flex="1"
            bg="gray.50"
            p={4}
            borderRadius="md"
          >
            <Table variant="unstyled">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Basic Amount:
                  </Td>
                  <Td>
                    <NumberInput
                      value={basicAmount}
                      onChange={(valueString) =>
                        setBasicAmount(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                    <Badge mt={2} colorScheme="purple">
                      Rate per Hour: {(basicAmount / 22).toFixed(2)} AED
                    </Badge>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Overtime Amount:
                  </Td>
                  <Td color="teal.500" fontWeight="bold">
                    {overtimeAmount} AED
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Salary Advance:
                  </Td>
                  <Td>
                    <NumberInput
                      value={salaryAdvance}
                      onChange={(valueString) =>
                        setSalaryAdvance(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>

          <Flex
            flexDirection="column"
            flex="1"
            bg="gray.50"
            p={4}
            borderRadius="md"
          >
            <Table variant="unstyled">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Absent Deduction:
                  </Td>
                  <Td color="red.500" fontWeight="bold">
                    {absentDeduction} AED
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Excellent Work Allowance:
                  </Td>
                  <Td>
                    <NumberInput
                      value={excellentWorkAllowance}
                      onChange={(valueString) =>
                        setExcellentWorkAllowance(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold" color="gray.700">
                    Bonus:
                  </Td>
                  <Td>
                    <NumberInput
                      value={bonus}
                      onChange={(valueString) =>
                        setBonus(parseFloat(valueString))
                      }
                      size="sm"
                      variant="flushed"
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>

          <Box
            flex="1"
            bg="teal.600"
            color="white"
            p={6}
            borderRadius="lg"
            textAlign="center"
            boxShadow="md"
          >
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Total Net Salary
            </Text>
            <Text fontSize="3xl" fontWeight="bold">
              {calculateNetSalary()} AED
            </Text>
            <Button
              mt={6}
              colorScheme="whiteAlpha"
              variant="outline"
              onClick={handleProcessSalary}
              isLoading={processLoading}
              loadingText="Processing"
              disabled={processLoading}
              width="100%"
              size="lg"
            >
              Process Salary
            </Button>
            {!processLoading && (
              <Text fontSize="xl" fontWeight="bold" mt={4}>
                Salary Processed
              </Text>
            )}
          </Box>
        </Flex>
      </Box>

      {/* Employee Details Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Employee Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Name: {employee.name}</Text>
            <Text>Designation: {employee.designation}</Text>
            <Text>Joining Date: {employee.joiningDate}</Text>
            <Text>Bank: {employee.bank}</Text>
            <Text>Account No: {employee.accountNo}</Text>
            <Text>Work Area: {employee.workArea}</Text>
            <Text>Labor Card: {employee.laborCard}</Text>
            <Text>Visa: {employee.visa}</Text>
            <Text>Passport No: {employee.passportNo}</Text>
            <Text>Nationality: {employee.nationality}</Text>
            <Text>Passport Expiry: {employee.passportExpiry}</Text>
            <Text>Visa Expiry: {employee.visaExpiry}</Text>
            <Text>Self Contact: {employee.selfContact}</Text>
            <Text>Family Contact: {employee.familyContact}</Text>
            <Text>Performance: {employee.performance}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Accommodation Drawer */}
      <Drawer
        isOpen={isDrawerOpen && drawerType === "accommodation"}
        placement="right"
        onClose={closeDrawer}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Accommodation</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <Text>Room:</Text>
              <Input placeholder="Enter room" />
              <Text>Room Rent:</Text>
              <NumberInput>
                <NumberInputField placeholder="Enter room rent" />
              </NumberInput>
              <Button colorScheme="teal">Update</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Certificate Drawer */}
      <Drawer
        isOpen={isDrawerOpen && drawerType === "certificate"}
        placement="right"
        onClose={closeDrawer}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Issue Certificate</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <Text>Type:</Text>
              <Select placeholder="Select certificate type">
                <option value="appreciation">Appreciation Letter</option>
                <option value="warning">Warning Letter</option>
                <option value="custom">Custom Letter</option>
              </Select>
              <Text>Title:</Text>
              <Input placeholder="Enter title" />
              <Text>Content:</Text>
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                style={{ height: "400px", width: "100%" }}
              />
              <Flex style={{ marginTop: "50px" }} gap={5}>
                <Button colorScheme="primary">Issue</Button>
                <Button colorScheme="gray">Save Template</Button>
              </Flex>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Leave Request Drawer */}
      <Drawer
        isOpen={isDrawerOpen && drawerType === "leave"}
        placement="right"
        onClose={closeDrawer}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Leave Request</DrawerHeader>
          <DrawerBody>
            <VStack align="start" spacing={4}>
              <Text>Date Range:</Text>
              <DatePicker
                selectsRange
                startDate={selectedDate}
                endDate={null}
                onChange={(update) => setSelectedDate(update)}
                isClearable={true}
                placeholderText="Select date range"
              />
              <Text>Reason:</Text>
              <Input as="textarea" placeholder="Enter reason" />
              <Button colorScheme="teal">Submit</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Certificate Modal */}

      <Modal
        isOpen={certModalDisclosure.isOpen}
        onClose={certModalDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedCert?.type} Letter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="bold" fontSize="lg">
              {selectedCert?.type} Letter
            </Text>
            <Text>Date Issued: {selectedCert?.dateIssued}</Text>
            <Text mt={4}>
              {/* Placeholder content for the certificate */}
              This is the content of the {selectedCert?.type} letter issued on{" "}
              {selectedCert?.dateIssued}.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmployeeDetails;
