import React from "react";
import { Button } from "@chakra-ui/react";
import { IoAddCircleOutline } from "react-icons/io5";

const PrimaryButton = ({ title, ...props }) => {
  return (
    <Button
      borderRadius="50px"
      padding="8px 10px"
      border="0"
      bg="var(--primary-color)"
      color="white"
      cursor="pointer"
      fontSize="28px"
      display="flex"
      alignItems="center"
      gap="7px"
      {...props}
    >
      <IoAddCircleOutline /> {title}
    </Button>
  );
};

export default PrimaryButton;
