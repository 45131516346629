import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { loadState, saveState } from "./utils/localStorage";
import { Provider } from "react-redux";
import store from "./store";
const root = ReactDOM.createRoot(document.getElementById("root"));

const persistedState = loadState();
if (persistedState) {
  store.dispatch({ type: "theme/setTheme", payload: persistedState.theme });
  store.dispatch({ type: "theme/setFont", payload: persistedState.font });
  store.dispatch({
    type: "theme/setFontSize",
    payload: persistedState.fontSize,
  });
}

store.subscribe(() => {
  saveState({
    theme: store.getState().theme.theme,
    font: store.getState().theme.fontFamily,
    fontSize: store.getState().theme.fontSize,
  });
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
