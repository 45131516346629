import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectSubcontractors } from "../../store/subcontractorsSlice";
import { useNavigate } from "react-router-dom";
import { TbSubtask } from "react-icons/tb";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericHeader from "../../commons/GenericHeader";
import DashboardBox from "../../commons/DashboardBox";
import GenericGrid from "../Procurement/GenericGrid";
import SearchInput from "../../commons/SearchInput";
import { GrMoney, GrUserWorker } from "react-icons/gr";
import { AiOutlineDollar } from "react-icons/ai";

const SubcontractorsList = () => {
  const subcontractors = useSelector(selectSubcontractors);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState(subcontractors);
  const [originalData] = useState(subcontractors);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(subcontractors);

  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedSubcontractorName, setSelectedSubcontractorName] =
    useState("");
  const [selectedSite, setSelectedSite] = useState("");

  const filters = useMemo(
    () => ({
      subcontractorName: selectedSubcontractorName,
      site: selectedSite,
    }),
    [selectedSubcontractorName, selectedSite]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedSubcontractorName = selectedSubcontractorName;
    let newSelectedSite = selectedSite;

    switch (filterType) {
      case "subcontractorName":
        newSelectedSubcontractorName = "";
        break;
      case "site":
        newSelectedSite = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedSubcontractorName ||
          row.subcontractorName === newSelectedSubcontractorName) &&
        (!newSelectedSite || row.siteName === newSelectedSite)
      );
    });

    setSelectedSubcontractorName(newSelectedSubcontractorName);
    setSelectedSite(newSelectedSite);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedSubcontractorName ||
          row.subcontractorName === selectedSubcontractorName) &&
        (!selectedSite || row.siteName === selectedSite)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedSubcontractorName("");
    setSelectedSite("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const calculateTotalBalance = useMemo(() => {
    return filteredData.reduce((sum, row) => sum + row.balance, 0);
  }, [filteredData]);

  const calculateTotalPaid = useMemo(() => {
    return filteredData.reduce((sum, row) => sum + row.totalPaid, 0);
  }, [filteredData]);

  const calculateAveragePayment = useMemo(() => {
    return (
      filteredData.reduce((sum, row) => sum + row.agreementAmount, 0) /
      filteredData.length
    ).toFixed(2);
  }, [filteredData]);

  const subcontractorColumns = [
    {
      id: "sno",
      header: "SNo",
      accessor: (item) => item.sno,
      show: true,
      minWidth: 50,
    },
    {
      id: "subcontractorName",
      header: "Subcontractor Name",
      accessor: (item) => item.subcontractorName,
      show: true,
      minWidth: 150,
    },
    {
      id: "siteName",
      header: "Site Name",
      accessor: (item) => item.siteName,
      show: true,
      minWidth: 150,
    },
    {
      id: "typeOfWork",
      header: "Type Of Work",
      accessor: (item) => item.typeOfWork,
      show: true,
      minWidth: 150,
    },
    {
      id: "agreementAmount",
      header: "Agreement Amount",
      accessor: (item) => item.agreementAmount,
      show: true,
      minWidth: 150,
    },
    {
      id: "paymentsPlan",
      header: "Payments Plan",
      accessor: (item) =>
        item.paymentsPlan
          .map((payment) => `${payment.work}: ${payment.amount}`)
          .join(", "),
      show: true,
      minWidth: 150,
    },
    {
      id: "totalPaid",
      header: "Total Paid",
      accessor: (item) => item.totalPaid,
      show: true,
      minWidth: 150,
    },
    {
      id: "balance",
      header: "Balance",
      accessor: (item) => item.balance,
      show: true,
      minWidth: 150,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    subcontractorColumns.filter((col) => col.show).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const handleRowClick = ({ sno }) => {
    navigate(`/subcontractors/details/${sno}`);
  };

  return (
    <Box>
      <GenericHeader
        title="Subcontractors"
        to={"/add-subcontractor"}
        icon={<TbSubtask />}
      />
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Flex
          alignItems="center"
          width="100%"
          justifyContent="space-around"
          gap={3}
        >
          <DashboardBox
            icon={GrMoney}
            name="Total Balance"
            value={calculateTotalBalance}
            backgroundColor="blue.300"
            type="money"
          />
          <DashboardBox
            icon={AiOutlineDollar}
            name="Total Paid"
            value={calculateTotalPaid}
            backgroundColor="yellow.300"
            type="money"
          />
          <DashboardBox
            icon={GrUserWorker}
            name="Average Payment"
            value={calculateAveragePayment}
            backgroundColor="red.300"
            type="money"
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Subcontractors"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.subcontractorName && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Subcontractor Name: ${filters.subcontractorName}`}</TagLabel>
              <TagCloseButton
                onClick={() => handleTagClose("subcontractorName")}
              />
            </Tag>
          )}
          {filters.site && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Site: ${filters.site}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("site")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Subcontractor Name"
                onChange={(e) => setSelectedSubcontractorName(e.target.value)}
                mb={4}
                value={selectedSubcontractorName}
              >
                {originalData.map((row) => (
                  <option
                    key={row.subcontractorName}
                    value={row.subcontractorName}
                  >
                    {row.subcontractorName}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Select Site"
                onChange={(e) => setSelectedSite(e.target.value)}
                value={selectedSite}
              >
                {originalData.map((row) => (
                  <option key={row.siteName} value={row.siteName}>
                    {row.siteName}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {subcontractorColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={subcontractorColumns}
          data={filteredData}
          title="Subcontractors List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default SubcontractorsList;
