import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  Switch,
  Input,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import debounce from "lodash/debounce";
import GenericHeader from "../../commons/GenericHeader";
import SearchInput from "../../commons/SearchInput";
import GenericGrid from "../Procurement/GenericGrid";
import { FaTasks } from "react-icons/fa";
import UserRowBox from "../../commons/UserRowBox";

const EmployeeDailyDutiesList = () => {
  const [rowData, setRowData] = useState([
    {
      id: 1,
      employeeName: "Aslam",
      skillSet: "Plumber",
      siteName: "Villa 12",
      foreman: {
        photo: "https://via.placeholder.com/50",
        name: "Aslam",
        site: "Site 2",
      },
      timein: "08:10 AM",
      date: "2024-07-01",
      photo: "https://via.placeholder.com/50", // Dummy photo URL
      designation: "Senior Plumber",
    },
    {
      id: 2,
      employeeName: "Akram",
      skillSet: "Electrician",
      siteName: "Villa 12",
      foreman: {
        photo: "https://via.placeholder.com/50",
        name: "Aslam",
        site: "Site 2",
      },
      timein: "08:10 AM",
      date: "2024-07-01",
      photo: "https://via.placeholder.com/50",
      designation: "Electrical Engineer",
    },
    {
      id: 3,
      employeeName: "Rahim",
      skillSet: "Carpenter",
      siteName: "Villa 12",
      timein: "08:10 AM",
      foreman: {
        photo: "https://via.placeholder.com/50",
        name: "Aslam",
        site: "Site 2",
      },
      date: "2024-07-02",
      photo: "https://via.placeholder.com/50",
      designation: "Lead Carpenter",
    },
    {
      id: 4,
      employeeName: "John",
      skillSet: "Painter",
      siteName: "Villa 12",
      foreman: {
        photo: "https://via.placeholder.com/50",
        name: "Aslam",
        site: "Site 2",
      },
      timein: "08:10 AM",
      date: "2024-07-02",
      photo: "https://via.placeholder.com/50",
      designation: "Senior Painter",
    },
  ]);
  const [originalData] = useState(rowData);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(rowData);

  const toast = useToast();
  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();
  const {
    isOpen: isColumnModalOpen,
    onOpen: onOpenColumnModal,
    onClose: onCloseColumnModal,
  } = useDisclosure();

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const filters = useMemo(
    () => ({
      employee: selectedEmployee,
      date: selectedDate,
    }),
    [selectedEmployee, selectedDate]
  );

  const debouncedApplySearchFilter = useCallback(
    debounce((text) => applySearchFilter(text), 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    debouncedApplySearchFilter(text);
  };

  const applySearchFilter = (text) => {
    const filteredData = originalData.filter((row) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(text.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setFilteredData(originalData);
  };

  const handleTagClose = (filterType) => {
    let newSelectedEmployee = selectedEmployee;
    let newSelectedDate = selectedDate;

    switch (filterType) {
      case "employee":
        newSelectedEmployee = "";
        break;
      case "date":
        newSelectedDate = "";
        break;
      default:
        break;
    }

    const filteredData = originalData.filter((row) => {
      return (
        (!newSelectedEmployee || row.employeeName === newSelectedEmployee) &&
        (!newSelectedDate || row.date === newSelectedDate)
      );
    });

    setSelectedEmployee(newSelectedEmployee);
    setSelectedDate(newSelectedDate);
    setRowData(filteredData);
    setFilteredData(filteredData);

    toast({
      title: "Filter Removed",
      description: `Filter for ${filterType} has been removed.`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  const applyFilter = () => {
    const filteredData = originalData.filter((row) => {
      return (
        (!selectedEmployee || row.employeeName === selectedEmployee) &&
        (!selectedDate || row.date === selectedDate)
      );
    });

    setRowData(filteredData);
    setFilteredData(filteredData);
    toast({
      title: "Filters Applied",
      description: "Data has been filtered",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const clearFilter = () => {
    setSelectedEmployee("");
    setSelectedDate("");
    setRowData(originalData);
    setFilteredData(originalData);
    toast({
      title: "Filters Cleared",
      description: "Filters have been cleared",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    onCloseFilterModal();
  };

  const employeeColumns = [
    {
      id: "employeeName",
      header: "Employee Name",
      accessor: (item) => item.employeeName,
      show: true,
      width: 250,
      render: (row) => (
        <UserRowBox
          photo={row.photo}
          name={row.employeeName}
          designation={row.designation}
        />
      ),
    },
    {
      id: "timein",
      header: "Time In",
      accessor: (item) => item.timein,
      show: true,
      width: 150,
    },
    {
      id: "foreman",
      header: "Foreman",
      show: true,
      width: 150,
      render: (row) => (
        <UserRowBox
          photo={row.foreman.photo}
          name={row.foreman.name}
          designation={row.foreman.site}
        />
      ),
    },
    {
      id: "siteName",
      header: "Site Name",
      accessor: (item) => item.siteName,
      show: true,
      width: 150,
    },
    {
      id: "date",
      header: "Date",
      accessor: (item) => item.date,
      show: true,
      width: 150,
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(
    employeeColumns.filter((col) => col.show).map((col) => col.id)
  );

  const handleVisibleColumnsChange = (columnId) => {
    const updatedColumns = visibleColumns.includes(columnId)
      ? visibleColumns.filter((col) => col !== columnId)
      : [...visibleColumns, columnId];
    setVisibleColumns(updatedColumns);
  };

  const handleSort = (column) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: column, direction: direction });

    const sortedData = [...rowData].sort((a, b) => {
      if (a[column] < b[column]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setFilteredData(sortedData);
  };

  const handleRowClick = (row) => {
    // Handle row click if needed, e.g., navigate to another page
    console.log("Row clicked:", row);
  };

  return (
    <Box>
      <GenericHeader
        title="Employee Daily Duties"
        addButtonName={"Add Duty"}
        icon={<FaTasks />}
      />
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        <Button leftIcon={<AddIcon />} onClick={onOpenFilterModal}>
          Filter
        </Button>
        <SearchInput
          placeholder="Search Employees"
          value={searchText}
          onChange={handleSearchInputChange}
          onClear={handleClearSearch}
        />
        <Button onClick={onOpenColumnModal}>Toggle Columns</Button>
      </Flex>
      <Flex mb={4}>
        <Flex>
          {filters.employee && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Employee: ${filters.employee}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("employee")} />
            </Tag>
          )}
          {filters.date && (
            <Tag
              size="md"
              borderRadius="10px"
              variant="solid"
              colorScheme="gray"
              mr={2}
            >
              <TagLabel fontWeight="bold">{`Date: ${filters.date}`}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose("date")} />
            </Tag>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isFilterModalOpen} onClose={onCloseFilterModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Apply Filters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Select
                placeholder="Select Employee"
                onChange={(e) => setSelectedEmployee(e.target.value)}
                mb={4}
                value={selectedEmployee}
              >
                {originalData.map((row) => (
                  <option key={row.id} value={row.employeeName}>
                    {row.employeeName}
                  </option>
                ))}
              </Select>
              <FormControl>
                <FormLabel>Filter by Date</FormLabel>
                <Input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={applyFilter} mr={3}>
              Apply Filter
            </Button>
            <Button variant="ghost" onClick={clearFilter}>
              Clear Filter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isColumnModalOpen} onClose={onCloseColumnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toggle Columns</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {employeeColumns.map((col) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={col.id}
                  mb={2}
                >
                  <FormLabel htmlFor={col.id} mb="0" flex="1">
                    {col.header}
                  </FormLabel>
                  <Switch
                    id={col.id}
                    isChecked={visibleColumns.includes(col.id)}
                    onChange={() => handleVisibleColumnsChange(col.id)}
                  />
                </FormControl>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onCloseColumnModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box>
        <GenericGrid
          columns={employeeColumns}
          data={filteredData}
          title="Employee Daily Duties List"
          visibleColumnsState={visibleColumns}
          filterState={filters}
          onVisibleColumnsChange={handleVisibleColumnsChange}
          onSort={handleSort}
          sortConfig={sortConfig}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default EmployeeDailyDutiesList;
