import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documents: [
    {
      sno: 1,
      name: "Document 1",
      description: "Description for Document 1",
      versions: [
        {
          version: "1.0",
          uploadedBy: "User A",
          uploadDate: "2024-01-01",
        },
      ],
      documentFile: null, // Add default value if needed
    },
    {
      sno: 2,
      name: "Document 2",
      description: "Description for Document 2",
      versions: [
        {
          version: "2.0",
          uploadedBy: "User B",
          uploadDate: "2024-02-01",
        },
      ],
      documentFile: null, // Add default value if needed
    },
    // Add more document objects here
  ],
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    addDocument: (state, action) => {
      state.documents.push(action.payload);
    },
    updateDocument: (state, action) => {
      const index = state.documents.findIndex(
        (doc) => doc.sno === action.payload.sno
      );
      if (index !== -1) {
        state.documents[index] = action.payload;
      }
    },
    deleteDocument: (state, action) => {
      state.documents = state.documents.filter(
        (doc) => doc.sno !== action.payload
      );
    },
    // New reducer for adding a version to a document
    addVersion: (state, action) => {
      const { sno, version } = action.payload;
      const document = state.documents.find((doc) => doc.sno === sno);
      if (document) {
        document.versions.push(version);
      }
    },
  },
});

export const { addDocument, updateDocument, deleteDocument, addVersion } =
  documentSlice.actions;

// Selector to get all documents
export const selectDocuments = (state) => state.document.documents;

// Selector to find a document by sno
export const findDocumentBySno = (sno) => (state) =>
  state.document.documents.find((doc) => doc.sno == sno);

export default documentSlice.reducer;
