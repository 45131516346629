import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "./commons/Header";
import "./styles/App.css";
import "./styles/theme.css";
import Sidebar from "./commons/Sidebar";
import { toggleSidebar } from "./store/sidebarSlice";
import MainContent from "./components/MainContent";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
import {
  ChakraProvider,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { DynamicThemeProvider } from "./commons/DynamicThemeProvider";
import LoadingIndicator from "./commons/LoadingIndicator";
import LoginPage from "./components/Auth/Login";
import "react-quill/dist/quill.snow.css";
import LogoImage from "./images/Logo-Header.png";
import Signup from "./components/Auth/Signup";
import AuthLayout from "./components/Auth/AuthLayout";

const App = () => {
  const theme = useSelector((state) => state.theme.theme);
  const fontFamily = useSelector((state) => state.theme.fontFamily);
  const fontSize = useSelector((state) => state.theme.fontSize);
  const collapsed = useSelector((state) => state.sidebar.collapsed);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      setTimeout(() => setLoading(false), 2000); // Simulate loading delay
    }
  }, [isAuthenticated]);

  return (
    <DynamicThemeProvider>
      <LoadingIndicator />
      <Router>
        {loading ? (
          <Flex
            align="center"
            justify="center"
            minHeight="100vh"
            flexDirection={"column"}
            gap={10}
          >
            <Image src={LogoImage} height={70} />
            <Spinner size="xl" />
            <Text fontSize={20} fontWeight={"bold"} color={"primary.500"}>
              Enterprise Resource Planning
            </Text>
            <LoadingIndicator />
          </Flex>
        ) : isAuthenticated ? (
          <div className={`App ${theme}`}>
            <Header toggleSidebar={toggleSidebar} />
            <div className="app-layout">
              <Sidebar collapsed={collapsed} />
              <MainContent collapsed={collapsed} />
            </div>
          </div>
        ) : (
          <LoginPage />
        )}
      </Router>
    </DynamicThemeProvider>
  );
};

export default App;
