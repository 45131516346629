import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
} from "@chakra-ui/react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { findDocumentBySno, selectDocuments } from "../../store/documentSlice";

const DocumentDetails = () => {
  const { sno } = useParams();
  const document = useSelector(findDocumentBySno(sno));
  const toast = useToast();

  if (!document) {
    toast({
      title: "Error",
      description: "Document not found.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

    return <p>Document not found.</p>;
  }

  const {
    documentName,
    documentDescription,
    documentVersion,
    uploadedBy,
    uploadDate,
    documentFile,
    versions,
  } = document;

  return (
    <Box p={4} borderRadius="20" background={"white"} border={"3px solid gray"}>
      <Heading as="h2" size="lg" mb={4}>
        {documentName}
      </Heading>
      <Text fontSize="lg" mb={2}>
        <strong>Description:</strong> {documentDescription}
      </Text>
      <Text fontSize="lg" mb={2}>
        <strong>Version:</strong> {documentVersion}
      </Text>
      <Text fontSize="lg" mb={4}>
        <strong>Uploaded By:</strong> {uploadedBy}
      </Text>
      <Text fontSize="lg" mb={4}>
        <strong>Upload Date:</strong> {uploadDate}
      </Text>

      <Box
        style={{
          width: "80%",
          height: "600px",
          border: "1px solid gray",
          overflow: "auto",
        }}
      >
        <Box> View the Document</Box>
      </Box>

      <Heading as="h3" size="md" mb={2}>
        Document Versions
      </Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Version</Th>
            <Th>Uploaded Date</Th>
            <Th>Uploaded By</Th>
          </Tr>
        </Thead>
        <Tbody>
          {versions.map((version, index) => (
            <Tr key={index}>
              <Td>{version.versionNumber}</Td>
              <Td>{version.uploadDate}</Td>
              <Td>{version.uploadedBy}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DocumentDetails;
