import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  {
    id: 1,
    date: new Date().toISOString().split("T")[0],
    siteNameAndArea: "Site A, Area 1",
    transporterPartyName: "Transporter 1",
    workType: "SAND/WATER/GARBAGE REMOVE",
    rateAmount: 1000,
    paid: 500,
    balance: 500,
    remarks: "", // New field for remarks
    photo: "", // New field for photo (URL or file path)
  },
  {
    id: 2,
    date: new Date().toISOString().split("T")[0],
    siteNameAndArea: "Site B, Area 2",
    transporterPartyName: "Transporter 2",
    workType: "STEEL DELIVERY",
    rateAmount: 1500,
    paid: 1500,
    balance: 0,
    remarks: "",
    photo: "",
  },
];

const transportSlice = createSlice({
  name: "transport",
  initialState,
  reducers: {
    addTransport: (state, action) => {
      state.push(action.payload);
    },
    updateTransport: (state, action) => {
      const { id, updatedData } = action.payload;
      const existing = state.find((transport) => transport.id === id);
      if (existing) {
        Object.assign(existing, updatedData);
      }
    },
    deleteTransport: (state, action) => {
      return state.filter((transport) => transport.id !== action.payload);
    },
  },
});

export const { addTransport, updateTransport, deleteTransport } =
  transportSlice.actions;
export const selectTransport = (state) => state.transport;

export const selectTransportById = (state, id) =>
  state.transport.find((transport) => transport.id === parseInt(id));

export default transportSlice.reducer;
